import { palette } from '../base/palette'

export const switchButton = {
    defaultProps: {
        disableRipple: true,
    },
    styleOverrides: {
        switchBase: {
            color: palette.background.obg,
            "&.Mui-checked": {
                "& + .MuiSwitch-track": {
                    backgroundColor: palette.action.active,
                    opacity: 0.5
                }

            }

        },
        colorSecondary: {
            "&$checked": {
                color: palette.action.active
            }
        },
        colorPrimary: {
            "&.Mui-checked": {
                color: palette.action.active
            },
        },
        track: {
            opacity: 0.2,
            backgroundColor: palette.background.obg
        },

    }
}