import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { withRouter } from '../wrappers/withRouter';
import { connect } from 'react-redux';
import { getObject } from '../../../utils/objectDefinitionUtils/basicUtils';
import { selectDetails } from '../../../redux/data/selectors';
import { JsonToURLParameters } from '../../../utils/formatters/urlFormatters';
import { getDetails } from '../../../redux/data/actions';
import { CircularProgress, Paper, Typography } from '@mui/material';
import { palette } from "../../../common/theme/base/palette"
import Star from '@mui/icons-material/StarBorder';
import Person from '@mui/icons-material/Person';
import Money from '@mui/icons-material/Euro';

function StatisticsDashboard(props) {
    useEffect(() => {
        //Fetch statistics
        const AdvisorStatisticsObject = getObject("AdvisorStatistics")
        var url = JsonToURLParameters(props.params, AdvisorStatisticsObject.request.get.url)
        props.getDetails(url)
        //Fetch Wallet
        const AccountBalanceObject = getObject("AccountBalance")
        url = JsonToURLParameters(props.params, AccountBalanceObject.request.get.url)
        props.getDetails(url)
    }, [props.params])
    return (
        <Box sx={{ marginLeft: 5, marginTop: 5, }}>
            {props.statistics ? <Paper sx={{ paddingX: 5, paddingY: 2 }} elevation={1}>
                <Box px={2} pb={3} display="flex">
                    <Box flex={1} display="flex" alignItems="center" justifyContent="flex-start" >
                        <Typography variant="h4" color="action.active"  >
                            Achievements
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" justifyItems="center" flexDirection="row">

                    <Box flex={1} display="flex" justifyItems="center" flexDirection="column">
                        <Typography variant="captionPaper" align="left">
                            Sales Ranking
                        </Typography>
                        <Typography style={{ display: 'inline-block' }} variant="body1" mb={1} align="left" >
                            {props.statistics.salesRank}
                        </Typography>
                    </Box>
                    <Box flex={1} display="flex" justifyItems="center" flexDirection="column">
                        <Typography variant="captionPaper" align="left">
                            Network Ranking
                        </Typography>
                        <Typography style={{ display: 'inline-block' }} variant="body1" mb={1} align="left" >
                            {props.statistics.networkRank}
                        </Typography>
                    </Box>
                    <Box flex={1} display="flex" justifyItems="center" flexDirection="column">
                        <Typography variant="captionPaper" align="left">
                            Sponsor alias
                        </Typography>
                        <Typography style={{ display: 'inline-block' }} variant="body1" mb={1} align="left" >
                            {props.statistics.sponsorAlias}
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" justifyItems="center" flexDirection="row" mt={3} mb={3}>
                    <Paper sx={{ paddingX: 5, paddingY: 2, backgroundColor: palette.info.main, marginX: 5, flex: 1 }} elevation={1}>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >
                            <Typography variant="h5" ml={1} align="center" color="background.paper">
                                Personal Stars
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" mt={1} mb={2} >
                            <Typography variant="h5" align="center" ml={1} color="background.paper">

                                <Star color="background.paper" fontSize="large"></Star>
                            </Typography>

                            <Typography variant="h5" align="center" ml={1} color="background.paper">
                                {props.statistics.personalStars}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >

                            <Typography variant="caption" align="center" color="background.paper">
                                pending:
                            </Typography>
                            <Typography variant="caption" align="center" ml={1} color="background.paper">
                                {props.statistics.personalPendingStars}
                            </Typography>
                        </Box>

                    </Paper>
                    <Paper sx={{ paddingX: 5, paddingY: 2, backgroundColor: palette.info.main, marginX: 5, width: "250px", flex: 1 }} elevation={1}>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >
                            <Typography variant="h5" ml={1} align="center" color="background.paper">
                                Matrix Validated Team Stars
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" mt={1} mb={2} >
                            <Typography variant="h5" align="center" ml={1} color="background.paper">

                                <Star color="background.paper" fontSize="large"></Star>
                            </Typography>

                            <Typography variant="h5" align="center" ml={1} color="background.paper">
                                {props.statistics.matrixValidatedTeamStars}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >

                            <Typography variant="caption" align="center" color="background.paper">
                                pending:
                            </Typography>
                            <Typography variant="caption" align="center" ml={1} color="background.paper">
                                {props.statistics.matrixPendingTeamStars}
                            </Typography>
                        </Box>

                    </Paper>
                    <Paper sx={{ paddingX: 5, paddingY: 2, backgroundColor: palette.success.main, marginX: 5, width: "250px", flex: 1 }} elevation={1}>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >
                            <Typography variant="h5" ml={1} align="center" color="background.paper">
                                Active Advisors
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" mt={1} mb={2} >
                            <Typography variant="h5" align="center" ml={1} color="background.paper">

                                <Person color="background.paper" fontSize="large"></Person>
                            </Typography>

                            <Typography variant="h5" align="center" ml={1} color="background.paper">
                                {props.statistics.enrollerActiveAdvisors}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >
                            <Typography variant="caption" align="center" color="background.paper">
                                First level:
                            </Typography>
                            <Typography variant="caption" align="center" ml={1} color="background.paper">
                                {props.statistics.enrollerActiveFirstLevelAdvisors}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >

                            <Typography variant="caption" align="center" color="background.paper">
                                pending:
                            </Typography>
                            <Typography variant="caption" align="center" ml={1} color="background.paper">
                                {props.statistics.enrollerPendingAdvisors}
                            </Typography>
                        </Box>

                    </Paper>
                    {props.wallet ? <Paper sx={{ paddingX: 5, paddingY: 2, backgroundColor: palette.background.yellow, marginX: 5, width: "250px", flex: 1 }} elevation={1}>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >
                            <Typography variant="h5" ml={1} align="center" color="background.paper">
                                Wallet
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" mt={1} mb={2} >
                            <Typography variant="h5" align="center" ml={1} color="background.paper">

                                <Money color="background.paper" fontSize="large"></Money>
                            </Typography>

                            <Typography variant="h5" align="center" ml={1} color="background.paper">
                                {props.wallet.balance}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >
                            <Typography variant="caption" align="center" color="background.paper">
                                currency:
                            </Typography>
                            <Typography variant="caption" align="center" ml={1} color="background.paper">
                                {props.wallet.currency}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >

                            <Typography variant="caption" align="center" color="background.paper">
                                pendingDeposit:
                            </Typography>
                            <Typography variant="caption" align="center" ml={1} color="background.paper">
                                {props.wallet.pendingDeposit}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >

                            <Typography variant="caption" align="center" color="background.paper">
                                pendingWithdraw:
                            </Typography>
                            <Typography variant="caption" align="center" ml={1} color="background.paper">
                                {props.wallet.pendingWithdraw}
                            </Typography>
                        </Box>
                    </Paper> : null}
                </Box>

            </Paper> : <CircularProgress></CircularProgress>}
        </Box>
    );

}

const mapDispatchToProps = dispatch => {
    return {
        getDetails: (url) => dispatch(getDetails(url, url)),
    }
}
const mapStateToProps = (state, props) => {
    const AdvisorStatisticsObject = getObject("AdvisorStatistics")
    const statisticsUrl = JsonToURLParameters(props.params, AdvisorStatisticsObject.request.get.url)

    const AccountBalanceObject = getObject("AccountBalance")
    const accountBalanceUrl = JsonToURLParameters(props.params, AccountBalanceObject.request.get.url)
    return ({
        statistics: selectDetails(statisticsUrl, state),
        wallet: selectDetails(accountBalanceUrl, state)
    })
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatisticsDashboard))
