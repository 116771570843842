
const definition = {
    "format": "InvoiceLine",
    "type": "object",
    "route": {
        "name": "/:InvoiceLineId"
    },
    "request": {
        "get": { url: "invoice/:InvoiceId", route: "lines/:InvoiceLineId" },
    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "column_hide": true
        },
        "name": {
            "type": "string",
            "nullable": true
        },
        "number": {
            "type": "string",
            "nullable": true
        },
        "description": {
            "type": "string",
            "nullable": true,
            "line": 2
        },
        "note": {
            "type": "string",
            "nullable": true,
            "line": 2,
            "column_hide": true
        },
        "unitPriceExcludingVat": {
            "type": "number",
            "format": "double",
            "block": 2,
            "line": 2,
            "column_hide": true
        },
        "unitPriceIncludingVat": {
            "type": "number",
            "format": "double",
            "block": 2,
            "line": 2,
            "column_hide": true
        },
        "vatPercentage": {
            "type": "number",
            "format": "double",
            "block": 2,
            "column_hide": true
        },
        "vatCode": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "block": 2
        },
        "quantity": {
            "type": "number",
            "format": "double",
            "block": 2
        },
        "unitDescription": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 2
        },
        "subtotalExcludingVat": {
            "type": "number",
            "format": "double",
            "block": 2,
            "line": 3,
            "column_hide": true
        },
        "subtotalIncludingVat": {
            "type": "number",
            "format": "double",
            "block": 2,
            "line": 3,
            "column_hide": true
        },
        "subtotalVat": {
            "type": "number",
            "format": "double",
            "block": 2,
            "line": 3,
            "column_hide": true
        },
        "invoiceGroupName": {
            "type": "string",
            "nullable": true
        },
        "contractReference": {
            "type": "string",
            "nullable": true,
            "line": 2
        },
        "fromDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "toDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "sourceId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true
        },
        "referenceInvoiceLineId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true
        }
    }
}
export default definition