

const definition = {
    "format": "Order",
    "type": "array",
    "request": {
        "get": { url: "orders" }
    },
    "route": {
        "name": "order",
        "isMain": true,
        "details": "OrderDetails"
    },
    "numberPath": "number",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "column_hide": true,
        },
        "customerAccountId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "column_hide": true
        },
        "number": {
            "type": "string",
            "nullable": true,
            "block": 1,
            "column_search": true,
            "column_filterParameter": "Number"
        },
        "customerAccount": {
            "type": "object",
            "format": "AccountIdentifier",
            "request": {
                "get": { url: "accounts/:customerAccountId/identifier" }
            },
            "field_hide": false,
            "object_hide": true,
            "tab_hide": true
        },
        "sellerAccountId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "column_hide": true,
        },
        "sellerAccount": {
            "type": "object",
            "format": "AccountIdentifier",
            "request": {
                "get": { url: "accounts/:sellerAccountId/identifier" }
            },
            "field_hide": false,
            "object_hide": true,
            "tab_hide": true
        },
        "creationTimeTo": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_filterParameter": "CreationTimeTo",
            "column_hide": true,

        },
        "creationTimeFrom": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_filterParameter": "CreationTimeFrom",
            "column_hide": true,

        },
        "modificationTimeFrom": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_filterParameter": "NodificationTimeFrom",
            "column_hide": true,

        },
        "modificationTimeTo": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_filterParameter": "ModificationTimeTo",
            "column_hide": true,

        },
        "reference": {
            "type": "string",
            "nullable": true,
            "field_hide": true,
            "column_hide": true,
        },
        "status": {
            "type": "enum",
            "format": "OrderStatus",
            "block": 1,
            "column_search": true,
            "column_filterParameter": "Status"
        },
        "totalExcludingVat": {
            "type": "number",
            "format": "double",
            "block": 2,
            "column_hide": true,
        },
        "totalIncludingVat": {
            "type": "number",
            "format": "double",
            "block": 2
        },
        "totalVat": {
            "type": "number",
            "format": "double",
            "block": 2,
            "column_hide": true,
        },
        "currency": {
            "type": "enum",
            "format": "Currency",
            "block": 2
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3,
            "column_hide": true,
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "block": 3,
            "column_hide": true,
        },
        "modifiesContractId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true,
        },
        "modificationSwitchDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3,
            "column_hide": true,
        },
        "invoiceId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true,
        },
        "contractPaymentMethod": {
            "type": "enum",
            "format": "PaymentType",
            "block": 1,
            "column_hide": true,
        }
    }
}
export default definition