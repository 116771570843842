export const selectData = (type, state) => state.data?.data?.some(item => item.type === type) ? state.data.data.find(item => item.type === type).items : null

export const selectTotalData = (type, state) => state.data.data.some(item => item.type === type) ? state.data.data.find(item => item.type === type).totalCount : 0

export const selectDetails = (type, state) => {
    const details = state.data.details.find(item => item.type === type)
    return details ? state.data.details.find(item => item.type === type).data : null
}

export const selectDetailsInner = (type, state, field = "", params = {}, identifier = "id") => {
    var object = state.data.details.some(item => item.type === type) ? { ...state.data.details.find(item => item.type === type).data } : null
    if (field == "" || object == null) {
        return object
    }
    field.split('/').forEach(function (element) {
        object = object && object[element] ? object[element] : (object && params[element.slice(1)] ? object.find(obj => (obj[identifier]).replace(/\s/g, '') == params[element.slice(1)]
        ) : null)
    })

    return object
}

export const selectError = (state) => state.data.error