import { dataActionTypes } from '../actionTypes';

export const getData = (name, url, maxResultCount, skipCount, sorting, filters, method) => {
    return ({
        type: dataActionTypes.GET_DATA,
        payload: {
            type: name,
            url,
            maxResultCount,
            skipCount,
            sorting,
            filters,
            method
        }
    })
}

export const getDetails = (type, url) => ({
    type: dataActionTypes.GET_DETAILS,
    payload: {
        type,
        url,
    }
})


export const getDetailsSuccess = (type, response) => ({
    type: dataActionTypes.GET_DETAILS_SUCCES,
    payload: {
        type,
        response,
    }
})

export const getDataSucces = (type, response, maxResultCount, skipCount) => ({
    type: dataActionTypes.GET_DATA_SUCCES,
    payload: {
        type,
        response,
        maxResultCount,
        skipCount
    }
});

export const getDataFailed = () => ({
    type: dataActionTypes.GET_DATA_FAILED,
});

export const getDetailsFailed = () => ({
    type: dataActionTypes.GET_DETAILS_FAILED,
});

export const resetData = (url) => ({
    type: dataActionTypes.RESET_DATA,
    payload: url
});

export const postData = (url, data, response) => ({
    type: dataActionTypes.POST_DATA,
    payload: { url, data, response }
});

export const postDataFailed = (error) => ({
    type: dataActionTypes.POST_DATA_FAILED,
    payload: error
});

export const postDataSucces = (url, data, response) => ({
    type: dataActionTypes.POST_DATA_SUCCES,
    payload: { url, data, response }
});


export const deleteData = (url, response) => ({
    type: dataActionTypes.DELETE_DATA,
    payload: { url, response }
});

export const deleteDataFailed = (error) => ({
    type: dataActionTypes.DELETE_DATA_FAILED,
    payload: error
});

export const deleteDataSucces = (url, data, response) => ({
    type: dataActionTypes.DELETE_DATA_SUCCES,
    payload: { url, data, response }
});

export const putData = (url, data, response) => ({
    type: dataActionTypes.PUT_DATA,
    payload: { url, data, response }
});

export const putDataFailed = (error) => ({
    type: dataActionTypes.PUT_DATA_FAILED,
    payload: error
});

export const putDataSucces = (url, data, response) => ({
    type: dataActionTypes.PUT_DATA_SUCCES,
    payload: { url, data, response }
});
