

const definition = {
    "format": "AccountIncentives",
    "type": "object",
    "route": {
        "name": "/:AccountIncentivesId"
    },
    "request": {
        "get": { url: "account-incentives/:AccountIncentivesId" },
    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "column_hide": true,
            "flex": 1
        },
        "accountId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "column_hide": true,
        },
        "number": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true,
        },
        "sellerAccountId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true,
        },
        "sellerAccount": {
            "type": "object",
            "format": "AccountIdentifier",
            "request": {
                "get": { url: "accounts/:sellerAccountId/identifier" }
            },
            "field_hide": false,
            "object_hide": true,
            "tab_hide": true,
            "column_flex": 0.5
        },
        "buyerAccountId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true,
            "column_flex": 0.5
        },
        "buyerAccount": {
            "type": "object",
            "format": "AccountIdentifier",
            "request": {
                "get": { url: "accounts/:buyerAccountId/identifier" }
            },
            "field_hide": false,
            "object_hide": true,
            "tab_hide": true,
            "column_flex": 0.5
        },
        "targetAccountId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "column_hide": true,
        },
        "targetAccount": {
            "type": "object",
            "format": "AccountIdentifier",
            "request": {
                "get": { url: "accounts/:targetAccountId/identifier" }
            },
            "field_hide": false,
            "object_hide": true,
            "tab_hide": true,
            "column_flex": 0.5
        },
        "awardedDate": {
            "type": "string",
            "format": "date-time",
            "block": 4,
            "column_hide": true,
        },
        "validationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 4,
            "column_hide": true,
        },
        "paidDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 4,
            "column_hide": true,
        },
        "rejectionDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 4,
            "column_hide": true,
        },
        "refundDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 4,
            "column_hide": true,
        },
        "contractLineId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true,
        },
        "orderLineId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true,
        },
        "nextPayday": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3,
            "column_flex": 1,
            "column_hide": true
        },
        "contractId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true,
        },
        "orderId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true,
        },
        "estimatedValue": {
            "type": "number",
            "format": "double",
            "block": 3,
            "column_hide": true,
        },
        "paidValue": {
            "type": "number",
            "format": "double",
            "block": 3,
            "hideIfEmpty": true,
            "column_flex": 0.5
        },
        "name": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "column_hide": true,
            "column_search": true,
            "column_filterParameter": "Name",
        },

        "description": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "column_hide": true,

        },
        "bonusName": {
            "type": "string",
            "nullable": true,
            "block": 3,
            "line": 2,
            "column_flex": 1
        },
        "bonusDescription": {
            "type": "string",
            "nullable": true,
            "block": 3,
            "line": 2,
            "column_hide": true,
        },
        "type": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 2,
            "column_flex": 1,
            "column_search": true,
            "column_filterParameter": "Type",
        },
        "status": {
            "type": "enum",
            "format": "BonusStatus",
            "block": 2,
            "line": 2,
            "column_flex": 0.5,
            "column_search": true,
            "column_filterParameter": "Status",
        },
        "currency": {
            "type": "enum",
            "format": "Currency",
            "block": 3,
            "column_hide": true,
        },
        "valueType": {
            "type": "enum",
            "format": "ValueType",
            "block": 3,
            "column_hide": true,
        },
        "isRecurring": {
            "type": "boolean",
            "block": 1,
            "column_flex": 0.5
        },
        "teamScope": {
            "type": "enum",
            "format": "TeamScope",
            "block": 2,
            "line": 3,
            "column_hide": true,
        },
        "targets": {
            "format": "AccountIncentiveTarget",
            "type": "array",
            "items": {
                "type": "object",
                "format": "AccountIncentiveTarget",
            },
            "identifier": "criteriaId",
            // "request": {
            //     "get": { url: "account-incentives/:AccountIncentivesId", route: "targets" },
            // },
            "route": { name: "targets" },
            "nullable": true,
            "object_hide": true,
            "tab_name": "targets",
            "column_hide": true,
        }
    }
}
export default definition