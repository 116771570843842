import { Box } from "@mui/system";
import { getObject } from "../../../utils/objectDefinitionUtils/basicUtils";
import { Button, IconButton, Typography } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "../../Components/wrappers/withRouter";
import { useCallback, useEffect, useState } from "react";
import { selectRoles } from "../../../redux/user/selectors";
import { getUpdatingItems } from "../../../redux/ui/selectors";
import { dataActionTypes } from "../../../redux/actionTypes";
import { selectError } from "../../../redux/data/selectors";
import CustomDataGrid from "../../Components/Grid/CustomDataGrid";
import Add from "@mui/icons-material/Add";
import DetailsModal from "./detailsModal";
import get from 'lodash/get';



function DetailsModalArray({ object, formik }) {
    const [showCreate, setShowCreate] = useState(false)
    const [showEdit, setShowEdit] = useState({ state: false, id: null })
    const [values, setValues] = useState([...get(formik.values, object.field)])
    const handleCreate = () => {
        setShowCreate(true)
    }
    const handleEdit = (row) => {
        setShowEdit({ state: true, id: row.id })
    }
    const handleClose = () => {
        setShowCreate(false)
        setShowEdit({ state: false, id: null })
    }

    const handleSubmit = async (values) => {
        if (showEdit.state) {
            var temp = [...get(formik.values, object.field)].map((item) => {
                if (item.id == showEdit.id) {
                    return { id: showEdit.id, ...values }
                }
                return item
            })

            formik.setFieldValue(object.field, temp)
            setShowEdit({ state: false, id: null })
            setValues(temp)
        } else {
            setShowCreate(false)
            var newData = [...get(formik.values, object.field), { id: get(formik.values, object.field).length == 0 ? 1 : Math.max(...get(formik.values, object.field).map(value => parseInt(value.id))) + 1, ...values }]
            formik.setFieldValue(object.field, newData)
            setValues(newData)
        }
    }

    const handleDelete = useCallback((row) => {
        setValues(old => [...old.filter(value => value.id != row.id)])
    }, [formik]);

    useEffect(() => {

        formik.setFieldValue(object.field, values)
    }, [values]);

    useEffect(() => {
        var temp = [...get(formik.values, object.field)]
        temp.map((item, index) => item.id = index + 1)
        formik.setFieldValue(object.field, temp)
    }, []);

    return (
        <Box>
            {
                object &&
                <Box>
                    <Button onClick={() => handleDelete({ id: 1 })}>
                        Delete
                    </Button>
                    <Box
                        mt={3}
                        mb={3}
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="center"
                        flexDirection="column"
                        id="modal-modal-description"
                        width="70vw"
                        position="relative"
                        flexWrap='wrap'
                    >
                        <Box justifyContent="right" display="flex" width="100%" >
                            <IconButton onClick={() => handleCreate()} ><Add color="active" /></IconButton>
                        </Box>
                        <CustomDataGrid
                            hideSearch={true}
                            autoHeight={true}
                            objectDefinition={object}
                            objects={get(formik.values, object.field)}
                            totalRows={get(formik.values, object.field).length}
                            detailsName={"Edit"}
                            onDetailsClick={handleEdit}
                            actions={[{ "name": "Delete", onClick: handleDelete }]}
                        />
                        <Typography variant="error">{get(formik.errors, object.field)}</Typography>
                    </Box>
                    <DetailsModal open={showCreate} onClose={handleClose} title={"Add " + object.format}
                        objectDefinition={getObject(object.format)} editType="Add" onSubmit={handleSubmit}></DetailsModal>
                    <DetailsModal open={showEdit.state} onClose={handleClose} title={"Edit " + object.format}
                        objectDefinition={getObject(object.format)} editType="Change" onSubmit={handleSubmit} data={get(formik.values, object.field).find((value, index) => index != showEdit.id)}></DetailsModal>
                </Box>
            }
        </Box >
    )
}

const mapStateToProps = (state) => {

    return ({
        loadingItems: getUpdatingItems(state, dataActionTypes.POST_DATA, dataActionTypes.EDIT_DATA),
        roles: selectRoles(state),
        error: selectError(state)
    })
};
export default withRouter(connect(mapStateToProps, null)(DetailsModalArray))
