
const definition = {
    "format": "Person",
    "type": "object",
    "request": {
        "get": { url: "accounts/:AccountId", route: "person" },
    },
    "route": {
        "name": "person"
    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true,
            "field_hide": true
        },
        "prefix": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "hideIfEmpty": true
        },
        "firstname": {
            "type": "string",
            "nullable": true,
            "columnFlex": 0.5,
            "column_search": true,
            "column_filterParameter": "Name"
        },
        "infix": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "hideIfEmpty": true
        },
        "lastname": {
            "type": "string",
            "nullable": true,
            "columnFlex": 0.5
        },
        "birthdate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
            "block": 2
        },
        "gender": {
            "type": "enum",
            "format": "Gender",
            "column_hide": true,
            "block": 2
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "column_hide": true,
            "block": 3
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
            "block": 3
        }
    }
}


export default definition