

const definition = {
    "format": "Payment",
    "type": "object",
    "request": {
        "get": {
            url: "invoice/:InvoiceId", route: "payments/:PaymentId"
        }
    },
    "route": {
        "name": "/:PaymentId"
    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "providerId": {
            "type": "string",
            "nullable": true,
            "field_hide": true
        },
        "transactionId": {
            "type": "string",
            "nullable": true,
            "field_hide": true
        },
        "amount": {
            "type": "number",
            "format": "double"
        },
        "link": {
            "type": "string",
            "format": "link",
            "nullable": true,
            "block": 2
        },
        "qrCode": {
            "type": "string",
            "format": "link",
            "nullable": true,
            "block": 2
        },
        "status": {
            "type": "enum",
            "format": "PaymentStatus"
        },
        "invoiceId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "block": 3
        },
        "usedPaymentMethod": {
            "type": "enum",
            "format": "PaymentMethod",
        },
        "requestedPaymentMethods": {
            "type": "string",
            "nullable": true
        }
    }
}


export default definition