import Matrix from "../../../Views/Components/Matrix/Matrix"
import StatisticsDashboard from "../../../Views/Components/Statistics/StatisticsDashboard"

const definition = {
    "format": "AdvisorDetails",
    "type": "object",
    "request": {
        "get": { url: "advisor/:AccountId" }
    },
    "route": {
        "name": "/:AccountId"
    },
    "numberPath": "account.accountName",
    "components": [
        {
            "component": Matrix,
            "name": "Matrix",
            "type": "tab"
        },
        {
            "component": StatisticsDashboard,
            "name": "StatisticsDashboard",
            "type": "object"
        }
    ],
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "status": {
            "type": "enum",
            "format": "AdvisorStatus"
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "block": 2,
            "line": 2
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 2,
            "line": 2
        },
        "sponsorId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "sponsorAccountName": {
            "type": "string",
            "nullable": true
        },
        "matrixNode": {
            "type": "object",
            "format": "MatrixNode",
            "tab_hide": true,
            "object_hide": true
        },
        "account": {
            "type": "object",
            "format": "AccountDetails",
            "request": {
                "get": { url: "accounts/:AccountId" },
            },
            "tab_name": "Account",
            "route": { name: "account" }
        },
        "registrationDate": {
            "type": "string",
            "format": "date-time",
            "block": 2
        },
        "activationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 2
        },
        "deactivationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 2
        },
        "salesRankName": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "field_hide": true
        },
        "highestSalesRankName": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "field_hide": true
        },
        "networkRankName": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "field_hide": true
        },
        "highestNetworkRankName": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "field_hide": true
        },
        "statistics": {
            "format": "AdvisorStatistics",
            "type": "object",
            "route": { name: "statistics" },
            "request": {
                "get": { url: "advisor/:AccountId/statistics" }
            },
            "field_hide": true,
            "object_hide": true
        }
    }
}
export default definition