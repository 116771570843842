export const uiActionTypes = {
    START_ACTION: "START_ACTION",
    STOP_ACTION: "STOP_ACTION",
    REFRESH_ACTION_START: "REFRESH_ACTION_START",
    REFRESH_ACTION_STOP: "REFRESH_ACTION_STOP"
}

export const authActionTypes = {
    SIGN_IN: "SIGN_IN",
    SIGN_IN_SUCCES: "SIGN_IN_SUCCES",
    SIGN_IN_FAILED: "SIGN_IN_FAILED",
    SIGN_OUT: "SIGN_OUT"
}

export const userActionTypes = {
    GET_USER_INFO: "GET_USER_INFO",
    GET_USER_INFO_SUCCES: "GET_USER_INFO_SUCCES",
    GET_USER_INFO_FAILED: "GET_USER_INFO_FAILED",
}

export const dataActionTypes = {
    GET_DATA: "GET_DATA",
    GET_DATA_SUCCES: "GET_DATA_SUCCES",
    GET_DATA_FAILED: "GET_DATA_FAILED",
    GET_DETAILS: "GET_DETAILS",
    GET_DETAILS_SUCCES: "GET_DETAILS_SUCCES",
    GET_DETAILS_FAILED: "GET_DETAILS_FAILED",
    RESET_DATA: "RESET_DATA",
    POST_DATA: "POST_DATA",
    POST_DATA_SUCCES: "POST_DATA_SUCCES",
    POST_DATA_FAILED: "POST_DATA_FAILED",
    DELETE_DATA: "DELETE_DATA",
    DELETE_DATA_SUCCES: "DELETE_DATA_SUCCES",
    DELETE_DATA_FAILED: "DELETE_DATA_FAILED",
    PUT_DATA: "PUT_DATA",
    PUT_DATA_SUCCES: "PUT_DATA_SUCCES",
    PUT_DATA_FAILED: "PUT_DATA_FAILED",
}