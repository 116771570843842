import ApiService from '../../utils/ApiService';

export const getData = (payload) => {
    return (ApiService.callApi({
        url: payload.url,
        options: {
            method: payload.method ? payload.method : 'GET',
            headers: {
                Accept: 'application/json',
            },
            validateStatus(status) {
                return [200, 400, 401].includes(status);
            },
            params: {
                MaxResultCount: payload.maxResultCount,
                SkipCount: payload.skipCount,
                Sorting: payload.sorting,
                ...payload.filters
            }
        },
        includeAuthorizationHeader: true,
    }))
}

export const getDetails = (payload) => {
    return (ApiService.callApi({
        url: payload.url,
        options: {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            validateStatus(status) {
                return [200, 400, 401].includes(status);
            },
        },
        includeAuthorizationHeader: true,
    }))
}


export const postData = (payload) => {
    return (ApiService.callApi({
        url: payload.url,
        options: {
            data: payload.data,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Accept: 'application/json'
            },
            validateStatus(status) {
                return [200, 400, 401].includes(status);
            },
        },
        includeAuthorizationHeader: true,
    }))
}

export const putData = (payload) => {
    return (ApiService.callApi({
        url: payload.url,
        options: {
            data: payload.data,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Accept: 'application/json'
            },
            validateStatus(status) {
                return [200, 400, 401].includes(status);
            },
        },
        includeAuthorizationHeader: true,
    }))
}

export const deleteData = (payload) => {
    return (ApiService.callApi({
        url: payload.url,
        options: {
            method: 'DELETE',
            headers: {
                Accept: 'application/json'
            },
            validateStatus(status) {
                return [200, 400, 401].includes(status);
            },
        },
        includeAuthorizationHeader: true,
    }))
}