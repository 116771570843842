import { enums } from "../../common/definitions"
import { objects } from "../../common/definitions"

export function getEnum(enumName) {
    return enums.some((object) => object.name == enumName) ? enums.find((object) => object.name == enumName) : null
}


export function getObject(objectName) {
    return objects.some((object) => object.format == objectName) ? objects.find((object) => object.format == objectName) : null
}


