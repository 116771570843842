
const definition = {
    "format": "CompanyCreate",

    "fields": {
        "name": {
            "type": "string",
            "nullable": true
        },
        "vatNumber": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "vatStatus": {
            "type": "enum",
            "format": "VatStatus",
            "block": 2
        },
        "type": {
            "type": "enum",
            "format": "CompanyType"
        }
    }
}
export default definition