import { CheckBox, Info } from "@mui/icons-material";
import { CircularProgress, Divider, IconButton, Paper, Typography } from "@mui/material"
import { Box } from "@mui/system";
import { connect } from "react-redux";
import { dataActionTypes } from "../../../redux/actionTypes";
import { nestedToFlatJson } from "../../../utils/formatters/ObjectFormatters";
import CustomDataGrid from "../../Components/Grid/CustomDataGrid";
import Moment from "react-moment"
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { getData, getDetails, resetData } from "../../../redux/data/actions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JsonToURLParameters } from "../../../utils/formatters/urlFormatters";
import { getObject } from "../../../utils/objectDefinitionUtils/basicUtils";
import { withRouter } from "../../Components/wrappers/withRouter";
import { selectData, selectDetails, selectDetailsInner, selectError, selectTotalData } from "../../../redux/data/selectors";
import { getUpdatingItems } from "../../../redux/ui/selectors";
import { selectRoles } from "../../../redux/user/selectors";
import { hasRoles } from "../../../utils/userUils/rolesUtils";
// import { object } from "yup";
import Add from '@mui/icons-material/Add';
import DetailsModal from "./detailsModal";
import ConfirmModal from "./confirmModal";


function ObjectDetails(props) {
    const [data, setData] = useState(null)
    const objectDefinition = getObject(props.objectDefinition.format)
    const [isLoading, setIsLoading] = useState(true)
    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    var defs = {}

    Object.keys(objectDefinition.fields).filter(key => !["object", "array"].includes(objectDefinition.fields[key].type) && !objectDefinition.fields[key].field_hide).map(key => defs[key] = objectDefinition.fields[key])

    if (Object.keys(defs).length === 0) {
        defs = objectDefinition.fields
    }

    useEffect(() => {
        const objectDefinitionTemp = props.objectDefinition.request?.get?.url ? props.objectDefinition : props.parentObjectDefinition

        if (objectDefinitionTemp.request && !objectDefinitionTemp.request.get.route) {
            const urlParams = Object.assign(props.parentObjectDefinition && props.parentObjectDefinition.request ? { ...props.selectData(JsonToURLParameters(props.params, props.parentObjectDefinition.request.get.url)) } : {}, { ...props.params })
            const url = JsonToURLParameters(urlParams, objectDefinitionTemp.request.get.url)
            setIsLoading(props.loadingItems.some(item => item.type == url))
        }
    }, [props.loadingItems])

    useEffect(() => {
        if (props.parentObjectDefinition && props.parentObjectDefinition.request && !props.selectData(JsonToURLParameters(props.params, props.parentObjectDefinition.request.get.url)) && !props.loadingItems.some(item => item.type == JsonToURLParameters(props.params, props.parentObjectDefinition.request.get.url))) {
            props.getDetails(JsonToURLParameters(props.params, props.parentObjectDefinition.request.get.url))
        }
        if (props.objectDefinition.request) {
            const urlParams = Object.assign(props.parentObjectDefinition && props.parentObjectDefinition.request ? { ...props.selectData(JsonToURLParameters(props.params, props.parentObjectDefinition.request.get.url)) } : {}, { ...props.params })
            const url = JsonToURLParameters(urlParams, props.objectDefinition.request.get.url)
            props.objectDefinition.type == "object" ? props.getDetails(url) : props.getData(url, 10, 0)
        }
    }, [props.navigate, objectDefinition])

    const navigate = useNavigate()

    const handleDetailsClick = (newData) => {
        const id = newData ? objectDefinition.identifier ? (newData[objectDefinition.identifier]).replace(/\s/g, '') : newData.id : null
        if (objectDefinition && objectDefinition.route?.isMain) {
            navigate("/" + objectDefinition.route.name + "/" + id, { replace: true })
        } else {
            const route = (props.nav ? props.nav + "/" : "") + (props.location.pathname.replace(/\/$/, "").split("/").pop() == props.objectDefinition.route.name ? "" : props.objectDefinition.route.name + "/") + (newData ? id : "")
            navigate(JsonToURLParameters(Object.assign({ ...props.selectData(JsonToURLParameters(props.params, props.parentObjectDefinition.request.get.url)) }, { ...props.params }), route), {
                replace: false
            })
        }
    }

    const handleCreate = () => {
        // const createObjDef = objectDefinition.request.post.format
        setShowCreate(true)
    }
    const handleEdit = () => {
        // const createObjDef = objectDefinition.request.post.format
        setShowEdit(true)
    }
    const handleDelete = () => {
        // const createObjDef = objectDefinition.request.post.format
        setShowDelete(true)
    }
    const handleClose = () => {
        setShowCreate(false)
        setShowDelete(false)
        setShowEdit(false)
    }

    const updateData = ((page, pageSize, sorting, filters, reset) => {
        const urlParams = Object.assign(props.parentObjectDefinition && props.parentObjectDefinition.request ? { ...props.selectData(JsonToURLParameters(props.params, props.parentObjectDefinition.request.get.url)) } : {}, { ...props.params })
        const url = JsonToURLParameters(urlParams, props.objectDefinition.request.get.url)
        if (reset) {
            props.resetData(url)
        }
        props.getData(url, pageSize, pageSize * page, sorting, filters)
    })

    //Hooks
    useEffect(() => {
        if (props.loadingItems.length === 0 && Array.isArray(props.data)) {
            var tempData = []
            props.data.forEach(row => {
                var newRow = { ...row }
                Object.keys(objectDefinition.fields).forEach(key => {
                    if (objectDefinition.fields[key].type == "object" && objectDefinition.fields[key].request) {
                        const urlParams = Object.assign({
                            ...row
                        }, { ...props.params })
                        const url = JsonToURLParameters(urlParams, objectDefinition.fields[key].request.get.url)
                        const details = props.selectDetails(url)
                        newRow = Object.assign(newRow, nestedToFlatJson({ [key]: { ...details } }))
                    }
                })
                tempData.push(newRow)
            })
            setData(tempData)
        }
    }, [props.loadingItems])

    useEffect(() => {
        var tempData = props.data

        if (Array.isArray(props.data)) {
            tempData = []
            props.data.forEach(row => {
                var newRow = { ...row }
                Object.keys(objectDefinition.fields).forEach(key => {
                    if (objectDefinition.fields[key].type == "object" && objectDefinition.fields[key].request) {
                        const urlParams = Object.assign({
                            ...row
                        }, { ...props.params })
                        const url = JsonToURLParameters(urlParams, objectDefinition.fields[key].request.get.url)
                        const details = props.selectDetails(url)
                        if (details == null) {
                            props.getDetails(url)
                        } else {
                            newRow = Object.assign(newRow, nestedToFlatJson({ [key]: { ...details } }))
                        }
                    }
                })
                tempData.push(newRow)
            })
        }
        setData(tempData)

    }, [props.data])

    var maxBlocks = Math.max(...Object.keys(defs).map(key => defs[key].block ? defs[key].block : 1))

    return (
        <Box position="relative" sx={{ marginX: objectDefinition.route?.isMain ? 0 : 2, marginY: objectDefinition.route?.isMain ? 0 : 2, overflowY: "auto" }}>

            {!data && !(objectDefinition.type == "array" || Array.isArray(data)) && <Box position="absolute" width="100%" height="100%"
                // bgcolor="background.transparent" 
                sx={{ zIndex: 1 }} display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Box>
            }

            {!data && props.loadingItems.length == 0 ? null : <Paper sx={{ paddingX: 5, paddingTop: 2, overflowY: "auto", height: "100%" }} elevation={objectDefinition.route?.isMain ? 0 : 1}>
                {
                    objectDefinition.type == "array" || Array.isArray(data) ?
                        <Box >
                            <Box px={2} pb={1} display="flex">
                                {objectDefinition.route.isMain ? null : <Box flex={1} display="flex" alignItems="center" justifyContent="flex-start" >
                                    <Typography variant="h4" color="action.active"  >
                                        {objectDefinition.format}
                                    </Typography>
                                </Box>}
                                <Box position="absolute" right={50} >
                                    {objectDefinition.request && objectDefinition.request.post && (objectDefinition.request.post.role ? hasRoles(props.roles, objectDefinition.request.post.role) : true) ? <IconButton onClick={() => handleCreate()} ><Add color="active" /></IconButton> : null}
                                    {!props.hideMoreInfo && objectDefinition.route ? <IconButton onClick={() => handleDetailsClick()} ><Info color="active" /></IconButton> : null}
                                </Box>
                            </Box>
                            <CustomDataGrid
                                hideSearch={(props.objectDefinition.request && !props.objectDefinition.request.get.route) ? false : true}
                                autoHeight={true}
                                objectDefinition={objectDefinition}
                                objects={data}
                                isLoading={isLoading}
                                totalRows={(props.objectDefinition.request && !props.objectDefinition.request.get.route) ? props.totalRows : data ? data.length : 0}
                                onDetailsClick={handleDetailsClick}
                                updateObjects={(props.objectDefinition.request && !props.objectDefinition.request.get.route) ? updateData : null}
                            />
                        </Box>
                        : <Box width="100%">
                            <Box px={2} pb={3} display="flex">
                                <Box flex={1} display="flex" alignItems="center" justifyContent="flex-start" >
                                    <Typography variant="h4" color="action.active"  >
                                        {objectDefinition.format}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="flex-end"  >
                                    {objectDefinition.request && objectDefinition.request.delete && (objectDefinition.request.delete.role ? hasRoles(props.roles, objectDefinition.request.delete.role) : true) ? <IconButton onClick={handleDelete}><DeleteIcon color="error" /></IconButton> : null}
                                    {objectDefinition.request && objectDefinition.request.put && (objectDefinition.request.put.role ? hasRoles(props.roles, objectDefinition.request.put.role) : true) ? <IconButton onClick={handleEdit}><Edit color="primary" /></IconButton> : null}
                                    {!props.moreParams && !props.hideMoreInfo && props.objectDefinition.route?.name ? <IconButton onClick={() => handleDetailsClick()}><Info color="active" /></IconButton> : null}
                                </Box>
                            </Box>
                            <Box  >
                                {[...Array(maxBlocks)].map((e, block) => {
                                    const fieldsInBlock = {}
                                    Object.keys(defs).filter(key => defs[key].block === block + 1 || (block == 0 && !defs[key].block ? true : false)).map(key => fieldsInBlock[key] = defs[key])
                                    var maxLines = Math.max(...Object.keys(fieldsInBlock).map(key => fieldsInBlock[key].line ? fieldsInBlock[key].line : 1))
                                    return (<Box key={block}>
                                        {[...Array(maxLines)].map((e, line) => {
                                            const fieldsInLine = {}
                                            Object.keys(fieldsInBlock).filter(key => (fieldsInBlock[key].line === line + 1 || (line == 0 && !fieldsInBlock[key].line ? true : false)) && (fieldsInBlock[key].hideIfEmpty ? (fieldsInBlock[key].hideIfEmpty == true && (data ? data[key] : false)) : true)).map(key => fieldsInLine[key] = objectDefinition.fields[key])
                                            return (
                                                <Box key={line} display="flex" flexDirection="row" flexWrap='wrap'
                                                >
                                                    {
                                                        Object.keys(fieldsInLine).map(fieldDefKey => {
                                                            const fieldDef = fieldsInLine[fieldDefKey]
                                                            return (
                                                                <Box key={fieldDefKey} pr={5} py={1} minWidth="200px">
                                                                    {fieldDef.type == "object" ?
                                                                        <Typography variant="caption">
                                                                            {fieldDefKey}
                                                                        </Typography> :
                                                                        <Box item key={fieldDef.field} justify="space-between"  >
                                                                            <Box display="flex" flexDirection={fieldDef.type == 'boolean' ? "row" : "column"} justifyItems="center" >
                                                                                <Typography variant="captionPaper" align="left">
                                                                                    {fieldDefKey}
                                                                                </Typography>
                                                                                {data ? <a download={objectDefinition.fields[fieldDefKey].link_name ? data[objectDefinition.fields[fieldDefKey].link_name] : null} href={fieldDef.format == 'link' ? (objectDefinition.fields[fieldDefKey].link_type && objectDefinition.fields[fieldDefKey].link_body) ? `data:${data[objectDefinition.fields[fieldDefKey].link_type]};base64,
                                                                                ${data[objectDefinition.fields[fieldDefKey].link_body]
                                                                                    }` : data[fieldDefKey] : null}  >
                                                                                    <Box display="flex" flexDirection={fieldDef.type == 'boolean' ? "row" : "column"} justifyItems="center" >

                                                                                        {data[fieldDefKey] ? <Typography style={{ display: 'inline-block' }} variant="body1" mb={1} align="left" >
                                                                                            {fieldDef.type == 'html' ? <div dangerouslySetInnerHTML={{ __html: data[fieldDefKey] }}></div> : null}
                                                                                            {fieldDef.format == 'date-time' ? <Moment format="HH:mm DD/MM/YYYY">{data[fieldDefKey]}</Moment> : null}
                                                                                            {fieldDef.type == 'boolean' ?
                                                                                                <CheckBox style={{
                                                                                                    transform: "scale(0.75)", transformOrigin: "top left", marginLeft: 5
                                                                                                }} label={fieldDefKey} disabled checked={data[fieldDefKey]} /> : null}
                                                                                            {['string', 'integer', 'enum', 'number'].includes(fieldDef.type) && fieldDef.format != 'date-time' ? data[fieldDefKey] : null}
                                                                                        </Typography> : <Typography variant="body1" mb={1} align="left">
                                                                                            &nbsp;
                                                                                        </Typography>} </Box></a> : <Typography variant="body1" mb={1} align="left">
                                                                                    &nbsp;
                                                                                </Typography>}
                                                                            </Box>
                                                                        </Box>}
                                                                </Box>)
                                                        })
                                                    }
                                                </Box>
                                            )
                                        })}
                                        <Divider style={{ width: "90%", marginBottom: 5 }} />
                                    </Box>)
                                })}
                            </Box></Box>
                }
            </Paper >}
            {data ?
                <Box>
                    <DetailsModal open={showCreate} onClose={handleClose} title={"Create " + objectDefinition.format}
                        objectDefinition={objectDefinition} editType="Create"></DetailsModal>
                    <ConfirmModal open={showDelete} onClose={handleClose} title={"Delete " + objectDefinition.format}
                        objectDefinition={objectDefinition} editType="Delete"></ConfirmModal>
                    <DetailsModal open={showEdit} onClose={handleClose} title={"Edit " + objectDefinition.format}
                        objectDefinition={objectDefinition} editType="Edit" data={{ ...data }}></DetailsModal>
                </Box>
                : null}
        </Box >

    )

}

const mapDispatchToProps = dispatch => {
    return {
        getDetails: (url) => dispatch(getDetails(url, url)),
        getData: (url, pageSize, page, sorting, filter) => dispatch(getData(url, url, pageSize, page, sorting, filter)),
        resetData: (url) => dispatch(resetData(url))
    }
}

const mapStateToProps = (state, props) => {
    const objectDefinition = props.objectDefinition.request?.get?.url ? props.objectDefinition : props.parentObjectDefinition
    const urlParams = Object.assign(props.parentObjectDefinition && props.parentObjectDefinition.request ? { ...selectDetailsInner(JsonToURLParameters(props.params, props.parentObjectDefinition.request.get.url), state, props.parentObjectDefinition.request.get.route) } : {}, { ...props.params }, { ...props.moreParams })
    return ({
        selectData: (url) => selectDetails(url, state),
        data: objectDefinition.type == "object" ? selectDetailsInner(JsonToURLParameters(urlParams, objectDefinition.request.get.url), state, props.objectDefinition.request ? props.objectDefinition.request.get.route : props.objectDefinition.route ? props.objectDefinition.route.name : props.name, urlParams, props.objectDefinition.identifier ? props.objectDefinition.identifier : "id") : selectData(JsonToURLParameters(urlParams, objectDefinition.request.get.url), state),
        loadingItems: getUpdatingItems(state, dataActionTypes.GET_DETAILS, dataActionTypes.GET_DATA),
        totalRows: objectDefinition.type == "object" ? 0 : selectTotalData(JsonToURLParameters(urlParams, objectDefinition.request.get.url), state),
        selectDetails: (url) => selectDetails(url, state),
        roles: selectRoles(state),
        error: selectError(state)

    })
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ObjectDetails))
