import { palette } from "../base/palette"
const { neutral } = palette;

export const borders = {
    borderColor: neutral[300],

    borderWidth: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
    },

    borderRadius: {
        xs: `${2 / 16}rem`,
        sm: `${4 / 16}rem`,
        md: `${8 / 16}rem`,
        lg: `${12 / 16}rem`,
        xl: `${16 / 16}rem`,
        xxl: `${24 / 16}rem`,
    },
};
