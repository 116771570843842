import { palette } from '../base/palette'
import { borders } from '../base/borders'
import { typography } from '../base/typography'

const { neutral } = palette
const { borderWidth } = borders

export const inputBase = {
    styleOverrides: {
        root: {
            color: `${neutral[800]} !important`,
            padding: `${10 / 16}rem ${20 / 16}rem`,
            fontSize: `${typography.size.md} !important`,
        },
        input: {
            height: `${20 / 16}rem`,
            padding: "0 !important",
            border: `${borderWidth[3]} solid ${palette.text.active}`,
        }
    }
}