import { Box } from "@mui/system";
import { getObject } from "../../../utils/objectDefinitionUtils/basicUtils";
import { Divider, IconButton, Modal, Tab, Tabs, Typography } from "@mui/material";
import Close from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { postData, putData } from "../../../redux/data/actions";
import { FormikProvider, useFormik } from "formik";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "../../Components/wrappers/withRouter";
import { JsonToURLParameters } from "../../../utils/formatters/urlFormatters";
import { generateValidationSchema } from "../../../utils/generators/validationSchema";
import { useEffect, useState } from "react";
import { selectRoles } from "../../../redux/user/selectors";
import { getUpdatingItems } from "../../../redux/ui/selectors";
import { dataActionTypes } from "../../../redux/actionTypes";
import { selectDetails, selectError } from "../../../redux/data/selectors";
import DetailsModalObject from "./detailsModalObject";
import { generateInitialValues, generateTabDefinition } from "../../../utils/generators/tabGenerator";
import DetailsModalArray from "./detailsModalArray";

function DetailsModal({ objectDefinition, editType, open, title, onClose, data, details, ...props }) {
    const [isLoading, setIsLoading] = useState(false)
    const [tab, setTab] = useState(0);
    const dispatch = useDispatch();
    //Constants

    useEffect(() => {
        if (isLoading == true && !props.loadingItems.some(item => item.type == JsonToURLParameters(props.params, objectDefinition.request.post.url)) && !props.loadingItems.some(item => item.type == JsonToURLParameters(props.params, objectDefinition.request.put.url))) {
            setIsLoading(false)
            if (!props.error || props.error == "") {
                formik.resetForm()
                onClose()
            }

        }
    }, [props.loadingItems])

    var object = null
    const style = {
        position: 'absolute',
        top: '50px',
        left: '50%',
        transform: 'translate(-50%,0)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        // minWidth: "500px",
        // maxHeight: "70vh",

    };
    if (editType == "Change") {
        object = objectDefinition
    } else if (editType == "Add") {
        object = objectDefinition
    } else if (editType == "Create" && objectDefinition.request && objectDefinition.request.post) {
        object = getObject(objectDefinition.request.post.format)
    } else if (editType == "Edit" && objectDefinition.request && objectDefinition.request.put) {
        object = getObject(objectDefinition.request.put.format)
    }


    var tabs = object ? generateTabDefinition(object) : []

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const onSubmit = (values) => {
        if (editType == "Create") {
            setIsLoading(true)
            dispatch(postData(JsonToURLParameters(props.params, objectDefinition.request.post.url), values, JsonToURLParameters(props.params, getObject(objectDefinition.request.post.response).request.get.url)))
        } else if (editType == "Edit") {
            setIsLoading(true)
            dispatch(putData(JsonToURLParameters(props.params, objectDefinition.request.put.url), objectDefinition.request.put.route ? { ...(objectDefinition.request.put.initialize ? details(JsonToURLParameters(props.params, getObject(objectDefinition.request.put.initialize).request.get.url)) : null), [objectDefinition.request.put.route]: values } : values, JsonToURLParameters(props.params, getObject(objectDefinition.request.put.response).request.get.url)))
        } else if (editType == "Add") {
            props.onSubmit(values)
        } else if (editType == "Change") {
            props.onSubmit(values)
        }
    }
    const formik = useFormik({
        initialValues: object ? generateInitialValues(object, data) : null,
        validationSchema: object ? generateValidationSchema(object) : null,
        onSubmit: (values) => {
            onSubmit(values)
        },
        validateOnChange: true,
        validateOnBlur: true
    })


    useEffect(() => {
        if (object && data) {
            var newInitialValues = {}
            Object.keys(object.fields).forEach(key => {
                if ((editType == "Edit" || editType == "Change") && data) {
                    newInitialValues[key] = data[key]
                } else {
                    newInitialValues[key] = ""
                }
            })
            formik.setValues(newInitialValues)
        }
    }, [data])
    return (
        <Box>
            <FormikProvider value={formik}>

                {
                    object && <Modal open={open}
                        onClose={onClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Box display="flex" justifyContent="left" alignItems="center" mb={2} mx={6} mt={3}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {title}
                                </Typography>
                                <Box position="absolute" right={20} >
                                    <IconButton onClick={() => onClose()}><Close color="primary" /></IconButton>
                                </Box>
                            </Box>
                            <Divider></Divider>
                            {tabs.length > 1 ?
                                <Box mb={2} mx={6}>
                                    <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" centered>
                                        {tabs.map((def, index) => {
                                            return (
                                                <Tab key={index} label={def.tab_name ? def.tab_name : def.name} {...a11yProps(0)} />
                                            )
                                        })}
                                    </Tabs>
                                    <Box sx={{ overflowY: "auto" }}>
                                        {tabs.map((def, index) => {

                                            return (
                                                <Box key={index}> {tab === index &&

                                                    < Box >{
                                                        def.type == "object" ? <DetailsModalObject formik={formik} onClose={onClose}
                                                            object={def} editType={editType} data={{ ...data }}></DetailsModalObject> : <DetailsModalArray formik={formik} onClose={onClose}
                                                                object={def} editType={editType} data={{ ...data }}></DetailsModalArray>
                                                    }</Box>
                                                }</Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                                :
                                < DetailsModalObject formik={formik} onClose={onClose} title={title}
                                    object={object} editType={editType} data={{ ...data }}></DetailsModalObject>
                            }
                            <Box mt={5} display="flex" justifyContent="center" mb={2} mx={10}>
                                <LoadingButton
                                    style={{ width: "80%", maxWidth: "250px" }}
                                    loading={isLoading}
                                    type='submit'
                                    onClick={formik.handleSubmit}
                                    variant='contained' >
                                    {"Submit"}
                                </LoadingButton>
                            </Box>

                        </Box>

                    </Modal >
                }
            </FormikProvider>
        </Box >
    )
}

const mapStateToProps = (state) => {
    // const objectDefinition = props.objectDefinition.request?.get?.url ? props.objectDefinition : props.parentObjectDefinition
    // const urlParams = Object.assign(props.parentObjectDefinition.request ? { ...selectDetailsInner(JsonToURLParameters(props.params, props.parentObjectDefinition.request.get.url), state, props.parentObjectDefinition.request.get.route) } : {}, { ...props.params })

    return ({
        loadingItems: getUpdatingItems(state, dataActionTypes.POST_DATA, dataActionTypes.EDIT_DATA),
        roles: selectRoles(state),
        error: selectError(state),
        details: (url) => selectDetails(url, state)

    })
};
export default withRouter(connect(mapStateToProps, null)(DetailsModal))
