import { TablePagination } from "@mui/material";
import { gridPageSizeSelector, gridRowCountSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dataActionTypes } from "../../../redux/actionTypes";
import { getUpdatingItems } from "../../../redux/ui/selectors";


function dataGridPagination(props) {
    const [isDisabled, setIsDisabled] = useState(true)
    const [page, setPage] = useState(0)

    const apiRef = useGridApiContext();
    // const page = useGridSelector(apiRef, gridPageSelector);
    const rowCount = useGridSelector(apiRef, gridRowCountSelector);
    const rowsPerPage = useGridSelector(apiRef, gridPageSizeSelector);

    useEffect(() => {
        setIsDisabled(props.loadingItems.some(item => item.maxResultCount == rowsPerPage && item.skipCount == (page) * rowsPerPage))

    }, [props.loadingItems]);
    const handleChangePage = (value) => {
        setPage(value)
        apiRef.current.setPage(value)
    }
    return (
        <TablePagination
            SelectProps={
                {
                    disabled: isDisabled
                }}
            backIconButtonProps={
                {
                    disabled: isDisabled || page == 0
                }
            }
            nextIconButtonProps={
                {
                    disabled: isDisabled || page == Math.floor(rowCount / rowsPerPage)
                }
            }
            component="div"
            count={rowCount}
            page={page}
            onPageChange={(event, value) => handleChangePage(value)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={event => apiRef.current.setPageSize(parseInt(event.target.value, 10))}
        ></TablePagination>
    );
}

const mapStateToProps = state => ({
    loadingItems: getUpdatingItems(state, dataActionTypes.GET_DATA),
});

export default connect(mapStateToProps, null)(dataGridPagination)