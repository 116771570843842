import { combineReducers } from "redux"
import { all } from "redux-saga/effects"
import auth from "./auth/reducer"
import { watchSignInSaga } from "./auth/saga"
import ui from "./ui/reducer"
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import user from "./user/reducer"
import data from "./data/reducer"
import { watchGetDataSaga, watchGetDetailsSaga, watchPostDataSaga, watchDeleteDataSaga, watchPutDataSaga } from "./data/saga"

const authConfig = {
    key: 'auth',
    storage,
    blacklist: ['error']
};

const dataConfig = {
    key: 'data',
    storage,
    blacklist: ['data', 'details']
};

const uiConfig = {
    key: 'ui',
    storage,
    blacklist: ['loader']

}

export const rootReducer = combineReducers({
    auth: persistReducer(authConfig, auth),
    ui: persistReducer(uiConfig, ui),
    user,
    data: persistReducer(dataConfig, data),
})

export default function* rootSaga() {
    yield all([
        watchSignInSaga(),
        watchGetDataSaga(),
        watchGetDetailsSaga(),
        watchPostDataSaga(),
        watchDeleteDataSaga(),
        watchPutDataSaga()
    ])
}