
const definition = {
    "format": "Customer",
    "type": "array",
    "request": {
        "get": { url: "customers" },
        // "put": { url: "accounts/:AccountId/bank-account/:bankAccountId", format: "BankAccountCreate", response: "AccountDetails" },
        // "delete": { url: "accounts/:AccountId/bank-account/:bankAccountId", role: "Admin", response: "AccountDetails", redirect: "/advisor/:AccountId/account/bankAccounts" },
        "post": { url: "Customers", format: "CustomerCreate", response: "CustomerDetails" }

    },
    "route": {
        "name": "customer",
        "isMain": true,
        "details": "CustomerDetails"
    },
    "numberPath": "account.number",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true,
        },
        "emailOptIn": {
            "type": "boolean",
            "column_hide": true,
        },
        "donorRelationType": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "column_hide": true,
        },
        "advisorId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            // "column_search": true,
            // "column_filterParameter": "AdvisorId",
            "column_hide": true,
        },
        "account": {
            "type": "object",
            "format": "Account",
            "columnFlex": 0.5,
            "tab_hide": true,
            "object_hide": true

        }
    }
}

export default definition