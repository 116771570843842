import { Box } from '@mui/system';
import PropTypes from "prop-types";
import { palette } from '../../../common/theme/base/palette';
export default function PageLayout({ children }) {
    return (
        <Box
            width="100%"
            height="93vh"
            minHeight="93vh"
            bgColor={palette.background}
            pt={1} px={5}
            sx={{ overflowY: "hidden", justifyContent: "center", display: "flex", alignItems: "center" }}
        >
            {children}
        </Box >
    );
}

PageLayout.propTypes = {
    children: PropTypes.node.isRequired,
};