import { userActionTypes } from '../actionTypes';

export const getUserInfo = () => ({
    type: userActionTypes.GET_USER_INFO,
})

export const getUserInfoSucces = (response) => ({
    type: userActionTypes.GET_USER_INFO_SUCCES,
    payload: response.data
});

export const getUserInfoFailed = () => ({
    type: userActionTypes.GET_USER_INFO_FAILED,
});