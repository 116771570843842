import * as yup from 'yup';
import "../../../utils/generators/yupExtenstions"

const definition = {
    "format": "CustomerCreate",
    "fields": {
        "advisorId": {
            "type": "string",
            "format": "uuid",
            "block": 2,
            "searchable": {
                "request": {
                    "get": { url: "advisor?Alias=:searchContent" },
                    "field": "id"
                },
            },
            "validationSchema": yup.string('').required('This field is required'),
        },
        "emailOptIn": {
            "type": "boolean"
        },
        "donorRelationType": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "validationSchema": yup.string('').nullable(''),
        },
        "account": {
            "type": "object",
            "format": "AccountCreate"
        }
    }
}
export default definition