import { Box, Grid, Typography } from "@mui/material"
function Footer() {
    return (
        <Grid container sx={{ height: "10vh", width: "100vw" }}>
            <Grid md={5} item >
                <Box
                    height="100%"
                    bgcolor="background.obg"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: 'column'
                    }}>
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                md={7}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" color="primary">
                    {process.env.REACT_APP_LABEL_FOOTER}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Footer