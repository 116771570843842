import { palette } from '../base/palette'
import { typography } from '../base/typography'

const { neutral } = palette

export const dataGrid = {
    styleOverrides: {
        root: {
            "& .MuiDataGrid-main": { borderRadius: 2 },
            '& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
                color: palette.background.obg,
            },
            "& .MuiDataGrid-virtualScrollerRenderZone": {
                "& .MuiDataGrid-row": {
                    "&:nth-of-type(2n)": {
                        backgroundColor: neutral[100],
                    },
                    "&:hover": {
                        backgroundColor: palette.action.active,
                    }
                }
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: palette.background.obg,
                color: palette.background.paper,
                fontSize: typography.size.sm
            }
        }
    },


}