
const definition = {
    "format": "Period",
    "type": "object",
    "request": {
        "get": { url: "cost-center/:costCenterId", route: "billCycle" }
    },
    "route": {
        "name": "period"
    },
    "fields": {
        "duration": {
            "type": "integer",
            "format": "int32"
        },
        "unit": {
            "type": "enum",
            "format": "PeriodUnit"
        }
    }
}
export default definition