const definition = {
    "format": "MessageDetails",
    "type": "object",
    "request": {
        "get": { url: "messages/:MessageId" },
    },
    "route": { name: "/:MessageId" },
    "numberPath": "subject",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "accountId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "state": {
            "type": "enum",
            "format": "MessageState",
        },
        "from": {
            "type": "string",
            "nullable": true
        },
        "recipient": {
            "type": "string",
            "nullable": true
        },
        "dateSend": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "line": 2
        },
        "subject": {
            "type": "string",
            "nullable": true,
            "line": 2
        },
        "type": {
            "type": "enum",
            "format": "MessageType",
            "line": 2
        },
        "model": {
            "type": "json",
            "field_hide": true
        },
        "body": {
            "type": "html",
            "nullable": true,
            "field_hide": true

        },
        "customData": {
            "type": "string",
            "nullable": true,
            "field_hide": true
        },
        "headers": {
            "type": "string",
            "nullable": true,
            "field_hide": true
        },
        "language": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "message": {
            "type": "object",
            "format": "MessageBody",
            "request": {
                "get": { url: "messages/:MessageId" },
            },
            "object_hide": true,
            "tab_name": "body"
        },
        "fileAssetIds": {
            "type": "string",
            "items": {
                "type": "string",
                "format": "uuid"
            },
            "hideTab": true,
            "hideField": true,
            "nullable": true
        }
    }
}
export default definition