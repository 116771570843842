
const definition = {
    "format": "InvoiceDetails",
    "type": "object",
    "request": {
        "get": { url: "invoice/:InvoiceId" },
    },
    "route": { name: "/:InvoiceId" },
    "numberPath": "number",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "accountId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "orderId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "contractId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "billRunId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "costCenterId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "accountNumber": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "number": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "reference": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "type": {
            "type": "enum",
            "format": "InvoiceType",
            "block": 2,
            "line": 2
        },
        "status": {
            "type": "enum",
            "format": "InvoiceStatus",
            "block": 2,
            "line": 2
        },
        "totalExcludingVat": {
            "type": "number",
            "format": "double",
            "block": 3,
            "line": 2
        },
        "totalIncludingVat": {
            "type": "number",
            "format": "double",
            "block": 3,
            "line": 2
        },
        "totalVat": {
            "type": "number",
            "format": "double",
            "block": 3,
            "line": 2
        },
        "bankAccount": {
            "type": "string",
            "nullable": true,
            "block": 3
        },
        "paymentMethod": {
            "type": "enum",
            "format": "PaymentType",
            "block": 3
        },
        "currency": {
            "type": "enum",
            "format": "Currency",
            "block": 3
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "block": 4
        },
        "invoiceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 4
        },
        "collectionDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 4
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 4
        },
        "rejected": {
            "type": "boolean"
        },
        "rejectionReason": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 4
        },
        "language": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 2
        },
        "recipientEmail": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 3
        },
        "recipientName": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 3
        },
        "billingAddress": {
            "type": "object",
            "format": "AddressValue",
            "route": { name: "billingAddress" },
            "object_hide": true,
            "tab_name": "billingAddress"
        },
        "vatNumber": {
            "type": "string",
            "nullable": true,
            "block": 3
        },

        "comment": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 2
        },
        "clientReference": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 4
        },
        "isExported": {
            "type": "boolean"
        },
        "pdfFileAssetId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "pdf": {
            "type": "object",
            "format": "FileAsset",
            "tab_name": "pdf",
            "request": {
                get: { url: "file-asset/:pdfFileAssetId" }
            },
            "object_hide": true
        },
        "lines": {
            "type": "array",
            "format": "InvoiceLine",
            "items": {
                "type": "object",
                "format": "InvoiceLine",
                "hideObject": true
            },
            "route": { name: "lines" },
            "nullable": true,
            "object_hide": true
        },
        "payments": {
            "type": "array",
            "format": "Payment",
            "route": { name: "payments" },
            "items": {
                "type": "object",
                "format": "Payment",
            },
            "nullable": true,
            "object_hide": true
        }
    }
}
export default definition