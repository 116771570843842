import { typography } from "../base/typography";

export const formControlLabel = {
    styleOverrides: {
        root: {
        },
        label: {
            // display: "inline-block",
            color: '#13461',
            fontSize: typography.size.md + " !important",
            fontWeight: typography.fontWeightMedium + " !important"
        }
    }
}