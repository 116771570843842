import { uiActionTypes } from '../actionTypes';
import isEqual from 'lodash/isEqual';

const initialState = {
    loader: {
        actions: [],
        refreshing: []
    }
};

export default function ui(state = initialState, { type, payload }) {
    const { loader } = state;
    const { actions, refreshing } = loader;
    switch (type) {
        case uiActionTypes.START_ACTION:
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...actions, payload.action]
                }
            };
        case uiActionTypes.STOP_ACTION:
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: actions.filter(action => {
                        if (action.name !== payload.action.name) {
                            return true;
                        } else if (!isEqual(action.params, payload.action.params)) {
                            return true
                        } else {
                            return false
                        }
                    })
                }
            };
        case uiActionTypes.REFRESH_ACTION_START:
            return {
                ...state,
                loader: {
                    ...loader,
                    refreshing: [...refreshing, payload.refreshAction]
                }
            };
        case uiActionTypes.REFRESH_ACTION_STOP:
            return {
                ...state,
                loader: {
                    ...loader,
                    refreshing: refreshing.filter(refresh => refresh !== payload.refreshAction)
                }
            };
        default:
            return state;
    }
};

