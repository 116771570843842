
const definition = {
    "format": "Company",
    "route": {
        "name": "company"
    },
    "type": "object",
    "request": {
        "get": { url: "accounts/:AccountId", route: "company" },
        "put": { url: "accounts/:AccountId", route: "company", initialize: "AccountDetails", format: "CompanyCreate", response: "AccountDetails" },
    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true,
            "field_hide": true
        },
        "name": {
            "type": "string",
            "nullable": true,
            "columnFlex": 0.5,
            "column_hide": true,
            "column_description": "Company Name"
        },
        "vatNumber": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "block": 2
        },
        "vatStatus": {
            "type": "enum",
            "format": "VatStatus",
            "column_hide": true,
            "block": 2
        },
        "type": {
            "type": "enum",
            "format": "CompanyType",
            "columnFlex": 0.5,
            "column_hide": true,
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "column_hide": true,
            "block": 3
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
            "block": 3
        }
    }
}
export default definition