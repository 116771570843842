import * as yup from 'yup';
import "../../../utils/generators/yupExtenstions"

const definition = {
    "format": "AccountCreate",
    "fields": {
        "accountName": {
            "type": "string",
            "nullable": true,
            "validationSchema": yup.string('').nullable(''),
        },
        "language": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "validationSchema": yup.string('').nullable(''),
        },
        "type": {
            "type": "enum",
            "format": "AccountType",
            "block": 2
        },
        "person": {
            "type": "object",
            "format": "PersonCreate"
        },
        "company": {
            "type": "object",
            "format": "CompanyCreate"
        },
        "addresses": {
            "type": "array",
            "items": {
                "type": "object",
                "format": "AddressCreate"
            },
            "nullable": true,
            "validationSchema": yup.array('').nullable(''),
        },
        "contactInformation": {
            "type": "array",
            "items": {
                "type": "object",
                "format": "ContactInformationCreate"
            },
            "nullable": true,
            "validationSchema": yup.array().required().min(1),
        },
        "bankAccounts": {
            "type": "array",
            "items": {
                "type": "object",
                "format": "BankAccountCreate"
            },
            "nullable": true,
            "validationSchema": yup.array('').nullable(''),
        }
    }
}
export default definition