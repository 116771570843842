import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { withRouter } from '../wrappers/withRouter';
import { connect, useDispatch } from 'react-redux';
import { getObject } from '../../../utils/objectDefinitionUtils/basicUtils';
import { selectDetails } from '../../../redux/data/selectors';
import { JsonToURLParameters } from '../../../utils/formatters/urlFormatters';
import { getDetails, postData } from '../../../redux/data/actions';
import { Button, Divider, Modal, TextField, Typography } from '@mui/material';
import { palette } from "../../../common/theme/base/palette"
import { LoadingButton } from '@mui/lab';
import { dataActionTypes } from '../../../redux/actionTypes';
import { getUpdatingItems } from '../../../redux/ui/selectors';
import moment from 'moment'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    minWidth: "500px",
    // maxHeight: "70vh",
};

function ContractButtons(props) {
    const [showConfirmation, setShowConfirmation] = useState({ state: false, type: null });
    const [isLoading, setIsLoading] = useState(false)
    const [date, setDate] = useState()
    const [error, setError] = useState(null)

    useEffect(() => {
        var now = moment().format('YYYY-MM-DDThh:mm:ss');
        setDate(now)
    }, [])

    const handleClose = () => setShowConfirmation({ state: false, type: null });

    const dispatch = useDispatch()

    const isValid = (data) => {
        var valid = true

        switch (props.contract.status) {
            case "Signed":
                valid = Boolean(moment(data).isAfter(props.contract.signedDate) > 0)
                if (!valid) {
                    setError("date must be after Sign date")
                }
                break;
            case "Accepted":
                valid = Boolean(moment(data).isAfter(props.contract.acceptationDate) > 0)
                if (!valid) {
                    setError("date must be after acceptation date")
                }
                break;
            case "Validated":
                valid = Boolean(moment(data).isAfter(props.contract.validationDate) > 0)
                if (!valid) {
                    setError("date must be after validation date")
                }
                break;
            case "Active":
                valid = Boolean(moment(data).isAfter(props.contract.activationDate) > 0)
                if (!valid) {
                    setError("date must be after activation date")
                }
                break;
            default:
                break;
        }
        if (valid) {
            setError(null)
        }
        return valid
    }

    const onConfirm = () => {
        const data = {}
        if (showConfirmation.type == "accept") {
            data.acceptDate = moment(date).toISOString()
        } else if (showConfirmation.type == "sign") {
            data.signDate = moment(date).toISOString()
        } else {
            data.date = moment(date).toISOString()
        }
        if (isValid(date)) {
            setIsLoading(true)

            dispatch(postData(JsonToURLParameters(props.params, "contracts/:ContractId/" + showConfirmation.type), data, JsonToURLParameters(props.params, "contracts/:ContractId")
            ))
        }
    }

    const handleDateChange = (e) => {
        isValid(e.target.value)
        setDate(e.target.value)
    }

    useEffect(() => {
        if (isLoading == true && !props.loadingItems.some(item => item && JsonToURLParameters(props.params, "contracts/:ContractId/" + showConfirmation.type))) {
            setIsLoading(false)
            if (!props.error || props.error == "") {
                handleClose()
            }
        }
    }, [props.loadingItems])

    const renderSwitch = () => {
        switch (props.contract.status) {
            case "Active":
                return (<Button variant="contained" size="small" onClick={() => setShowConfirmation({ state: true, type: "terminate" })}>
                    terminate
                </Button>)
            case "Signed":
                return (
                    <Box display="flex" flexDirection="row" >
                        <Box mx="5px">
                            <Button variant="contained" size="small" onClick={() => setShowConfirmation({ state: true, type: "cancel" })}>
                                Cancel
                            </Button>
                        </Box>
                        <Box mx="5px">
                            <Button variant="contained" size="small" onClick={() => setShowConfirmation({ state: true, type: "accept" })}>
                                Accept
                            </Button>
                        </Box>
                    </Box>)
            case "Accepted":
                return (
                    <Box display="flex" flexDirection="row" >
                        <Box mx="5px">
                            <Button variant="contained" size="small" onClick={() => setShowConfirmation({ state: true, type: "cancel" })}>
                                Cancel
                            </Button>
                        </Box>
                        <Box mx="5px">
                            <Button variant="contained" size="small" onClick={() => setShowConfirmation({ state: true, type: "validate" })}>
                                Validate
                            </Button>
                        </Box>
                    </Box>)
            case "Created":
                return (
                    <Box display="flex" flexDirection="row" >
                        <Box mx="5px">
                            <Button variant="contained" size="small" onClick={() => setShowConfirmation({ state: true, type: "cancel" })}>
                                Cancel
                            </Button>
                        </Box>
                        <Box mx="5px">
                            <Button variant="contained" size="small" onClick={() => setShowConfirmation({ state: true, type: "sign" })}>
                                Sign
                            </Button>
                        </Box>
                    </Box>)
            case "Validated":
                return (
                    <Box display="flex" flexDirection="row" >
                        <Box mx="5px">
                            <Button variant="contained" size="small" onClick={() => setShowConfirmation({ state: true, type: "cancel" })}>
                                Cancel
                            </Button>

                        </Box>
                        <Box mx="5px">

                            <Button variant="contained" size="small" onClick={() => setShowConfirmation({ state: true, type: "activate" })}>
                                Activate
                            </Button>
                        </Box>

                    </Box>

                )
            default:
                return null
        }
    }


    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "right" }}>
            {props.contract ? <Box marginRight={"25px"} alignItems={"center"} display="flex">
                <Typography variant="body2">Status:&nbsp; </Typography>
                <Typography variant="body2">{props.contract.status}</Typography>

            </Box>
                : null}

            <Box marginRight="200px" display="flex" flexDirection="row">
                {props.contract ? renderSwitch() : null}
            </Box>
            {props.contract ? <Modal
                open={showConfirmation.state}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {showConfirmation.type + " Contract"}
                    </Typography>
                    <Box mt={5} mb={2}>
                        <Typography id="modal-modal-description" variant="body2" sx={{ mt: 2 }}>
                            Are you sure you want to {showConfirmation.type} the contract with following details:
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {props.contract.number}
                        </Typography>
                        {props.contract.lines ? props.contract.lines.map(line => <Typography key={line} id="modal-modal-description" sx={{ mt: 2 }}>
                            {line.productName} , Quantity : {line.quantity}
                        </Typography>) : "No contractLines"}
                    </Box>
                    <Divider></Divider>
                    <Box display="flex" flexDirection="column">
                        <Typography id="modal-modal-description" variant="body2" sx={{ mt: 2 }}>
                            {showConfirmation.type} on :
                        </Typography>
                        <TextField
                            type="datetime-local"
                            value={date}
                            onChange={(e) => handleDateChange(e)}
                        >                </TextField>

                    </Box>
                    {error ? <Typography variant="error">
                        {error}
                    </Typography> : null}
                    <Divider></Divider>
                    <Box mt={3} display="flex" justifyContent="center">
                        <LoadingButton
                            loading={isLoading}
                            onClick={onConfirm}
                            disabled={error ? true : false}
                            style={{ marginRight: 10 }}
                            variant='contained' >
                            {"Confirm"}

                        </LoadingButton>
                        <Button
                            style={{ backgroundColor: palette.error.main }}
                            onClick={() => handleClose()}
                            variant='contained' >
                            {"Cancel"}
                        </Button>
                    </Box>
                </Box>
            </Modal> : null
            }
        </Box >
    );
}

const mapDispatchToProps = dispatch => {
    return {
        getDetails: (url) => dispatch(getDetails(url, url)),
        postData: (url, data, response) => dispatch(postData(url, data, response))
    }
}
const mapStateToProps = (state, props) => {
    const ContractDetails = getObject("ContractDetails")
    const ContractDetailsUrl = JsonToURLParameters(props.params, ContractDetails.request.get.url)
    return ({
        contract: selectDetails(ContractDetailsUrl, state),
        loadingItems: getUpdatingItems(state, dataActionTypes.POST_DATA),
    })
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractButtons))
