import { CircularProgress, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { connect } from "react-redux";
import ObjectDetails from "./objectDetails";
import { getDetails } from "../../../redux/data/actions";
import { JsonToURLParameters } from "../../../utils/formatters/urlFormatters";

import { withRouter } from "../../Components/wrappers/withRouter";
import { getObject } from "../../../utils/objectDefinitionUtils/basicUtils";
import PageTitle from "../../Components/General/PageTitle";
import { getUpdatingItems } from "../../../redux/ui/selectors";
import { dataActionTypes } from "../../../redux/actionTypes";
import PageLayout from "../../Components/layouts/PageLayout";
import DetailsTabPanel from "./DetailsTabPanel";
import { selectDetails } from "../../../redux/data/selectors";
function Details({ params, ...props }) {
    const objectDefinition = getObject(props.objectDefinition.format)
    //Constants
    const [tab, setTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const tabs = [objectDefinition, ...(Object.keys(objectDefinition.fields).filter(key => ["object", "array"].includes(objectDefinition.fields[key].type) && !objectDefinition.fields[key].tab_hide).map(key => objectDefinition.fields[key]))]
    //Handlers
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    const useEffect = () => {

    };
    //Hooks
    useEffect(() => {
        const url = JsonToURLParameters(params, objectDefinition.request.get.url)
        setIsLoading(props.loadingItems.some(item => item.type == url))
    }, [props.loadingItems])
    //Defs
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    //Hooks
    useEffect(() => {
        const url = JsonToURLParameters(params, objectDefinition.request.get.url)
        props.getDetails(url, url)
    }, [])

    return (<PageLayout >

        <Box height="100%" sx={{ overflowY: "auto" }} width="100%" flexDirection="column">

            <Box>
                <PageTitle objectDefinition={objectDefinition}></PageTitle>
                <Box >
                    {isLoading ? <CircularProgress></CircularProgress> : <Box height="100%">
                        {tabs.length > 1 ?
                            <Box height="100%"  >
                                <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" centered>
                                    {tabs.map((def, index) => {
                                        return (
                                            <Tab key={index} label={index == 0 ? "General" : (def.tab_name ? def.tab_name : def.format)} {...a11yProps(0)} />
                                        )
                                    })}
                                    {objectDefinition.components ? objectDefinition.components.filter(component => component.type == "tab").map((component) => {
                                        return (
                                            <Tab key={component.name} label={component.name} {...a11yProps(0)} />
                                        )
                                    }) : null}
                                </Tabs>
                                <Box width="100%" >
                                    {tabs.map((def, index) => {

                                        return (
                                            <DetailsTabPanel
                                                parentObjectDefinition={props.objectDefinition}
                                                objectDefinition={def}
                                                moreParams={def.identifier && props.details ? { [def.identifier.replace]: props.details[def.identifier.orig] } : null}
                                                key={index}
                                                value={tab}
                                                index={index}
                                                label={index == 0 ? "General" : (def.tab_name ? def.tab_name : def.format)} />
                                        )
                                    })}
                                    {objectDefinition.components ? objectDefinition.components.filter(component => component.type == "tab").map((component, index) => {
                                        return (
                                            <Box
                                                key={tabs.length + index} index={tabs.length + index}
                                                role="tabpanel"
                                                id={`simple-tabpanel-${index}`}
                                                aria-labelledby={`simple-tab-${index}`}
                                                height="100%"
                                            >
                                                {tab === tabs.length + index && <component.component objectDefinition={props.objectDefinition} id={params.id} />}
                                            </Box>
                                        )
                                    }) : null}
                                </Box>
                            </Box>
                            :
                            <ObjectDetails hideMoreInfo objectDefinition={props.objectDefinition} name={objectDefinition.format} parentObjectDefinition={props.parentObjectDefinition}></ObjectDetails>
                        }</Box>
                    }</Box>
            </Box>
        </Box>
    </PageLayout >)
}

const mapDispatchToProps = dispatch => {
    return {
        getDetails: (name, url) => dispatch(getDetails(name, url)),
    }
}

const mapStateToProps = (state, props) => {
    const url = props.objectDefinition.request && JsonToURLParameters(props.params, props.objectDefinition.request.get.url)
    return ({
        loadingItems: getUpdatingItems(state, dataActionTypes.GET_DATA, dataActionTypes.GET_DETAILS),
        details: selectDetails(url, state)
    })
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details))
