
const definition = {
    "format": "ContactInformation",
    "type": "object",
    "route": {
        "name": "/:contactInformationId"
    },
    "request": {
        "get": { url: "accounts/:AccountId", route: "contactInformation/:contactInformationId" },
        "put": { url: "accounts/:AccountId/contact-information/:contactInformationId", format: "ContactInformationCreate", response: "AccountDetails" },
        "delete": { url: "accounts/:AccountId/contact-information/:contactInformationId", role: "Admin", response: "AccountDetails", redirect: "/advisor/:AccountId/account/contactInformation" },
        "post": { url: "accounts/:AccountId/contact-information", format: "ContactInformationCreate", response: "AccountDetails" }
    },
    "numberPath": "label",

    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true,
            "field_hide": true,
        },
        "label": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "email": {
            "type": "string",
            "nullable": true,
            "columnMinWidth": 400,
            "block": 2,
            "line": 1,
            "column_search": true,
            "column_filterParameter": "Email"
        },
        "phone": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 1
        },
        "mobile": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 1,
            "hideIfEmpty": true
        },
        "fax": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "block": 2,
            "line": 1,
            "hideIfEmpty": true
        },
        "website": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "block": 2,
            "line": 2,
            "hideIfEmpty": true
        },
        "telegram": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "block": 2,
            "line": 2,
            "hideIfEmpty": true
        },
        "linkedIn": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "block": 2,
            "line": 2,
            "hideIfEmpty": true
        },
        "facebook": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "block": 2,
            "line": 2,
            "hideIfEmpty": true
        },
        "instagram": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "block": 2,
            "line": 2,
            "hideIfEmpty": true
        },
        "pushToken": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "field_hide": true
        },
        "isMain": {
            "type": "boolean",
            "block": 1
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "column_hide": true,
            "block": 3
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
            "block": 3,
            "hideIfEmpty": true
        }
    }
}
export default definition