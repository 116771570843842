import { Divider, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { generateRoutes } from '../../../utils/generators/routeGenerators';
import { selectDetails } from '../../../redux/data/selectors';
import { connect } from 'react-redux';
import { nestedToFlatJson } from '../../../utils/formatters/ObjectFormatters';
import { JsonToURLParameters } from '../../../utils/formatters/urlFormatters';

function PageTitle({ objectDefinition, ...props }) {
    const [navs, setNavs] = useState([])

    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    useEffect(() => {
        var newNavs = []
        var currentRoute = ''
        const allRoutes = generateRoutes()
        location.pathname.slice(1).split('/').forEach(path => {
            const key = Object.keys(params).find(key => params[key] == path)
            if (key) {
                currentRoute += '/' + ":" + key
                const route = allRoutes.find(route => route.path == currentRoute.slice(1))
                const object = props.selectDetails(JsonToURLParameters(params, route.objectDefinition.request.get.url))
                const objectName = nestedToFlatJson(object)[route.objectDefinition.numberPath]
                newNavs.push({ name: path, objectName: objectName ? objectName : objectDefinition.format })
            } else {
                currentRoute += '/' + path
                newNavs.push({ name: path })
            }
        })
        setNavs(newNavs)
    }, [location])

    const handleNavigate = (index) => {
        navigate('/' + navs.slice(0, index + 1).map(nav => nav.name).join('/'), { replace: true })
    }

    return (
        <Box display="flex" flexDirection="column" height="7vh" justifyContent="center">
            <Box display="flex" >
                {navs.map((item, index) =>
                    <Box display="flex" alignItems='center' flexWrap='wrap' key={item.name} >
                        <IconButton
                            onClick={() => handleNavigate(index)}
                        >
                            {item.objectName ? <Box display="flex"><Typography>
                                &nbsp;
                            </Typography>
                                <Typography >
                                    {item.objectName}
                                </Typography></Box> : <Typography  >
                                {item.name}
                            </Typography>}
                        </IconButton> {index < navs.length - 1 ?
                            <ArrowForwardIosIcon sx={{ fontSize: "1rem" }} /> : null}
                    </Box>)
                }
            </Box>

            <Box flex={1} display="flex" alignItems="center" justifyContent="center">
                <Divider style={{ width: "50%" }} ><Typography variant="h3">{objectDefinition ? objectDefinition.format : null}</Typography></Divider>
            </Box>
        </Box >
    );
}

const mapStateToProps = (state) => {
    return ({
        selectDetails: (type) => selectDetails(type, state),
    })
};

export default connect(mapStateToProps, null)(PageTitle)
