import { typography } from "../base/typography";
import { borders } from "../base/borders";
import { palette } from "../base/palette";

const { fontWeightBold, size, fontWeightRegular } = typography;
const { borderRadius } = borders
const { text, background } = palette
export const button = {
    defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application!
    },
    styleOverrides: {
        root: {
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: borderRadius.xs,
            fontSize: size.xm,
            fontWeight: fontWeightBold,
            padding: `${8 / 16}rem ${16 / 16}rem`,
            textAlign: "center",
            textTransform: "uppercase",
            "&:disabled": {
                pointerEvent: "none",
                opacity: 0.65,
            },
        },
        contained: {
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: borderRadius.sm,
            width: "100%",
            fontSize: size.xm,
            fontWeight: fontWeightBold,
            padding: `${8 / 16}rem ${16 / 16}rem`,
            textAlign: "center",
            textTransform: "uppercase",
            "&:disabled": {
                pointerEvent: "none",
                opacity: 0.65,
            },
            "&:hover": {
                backgroundColor: text.active
            },
            backgroundColor: background.obg,
        },
        menu: {
            height: "100%",
            fontSize: size.xm,
            fontWeight: fontWeightRegular,
            textTransform: 'none',
            padding: `${8 / 16}rem ${16 / 16}rem`,
            "&:hover": {
                backgroundColor: "#FFFFFF",
                color: text.secondary,
            },
            "&:active": {
                backgroundColor: "#FFFFFF",
                color: text.active,
            },
            "&.active": {
                backgroundColor: "#FFFFFF",
                color: text.active,
            },
        }
    },
}