import { objects } from "../../common/definitions"
import { getObject } from "../objectDefinitionUtils/basicUtils"


export function generateRoutes() {
    var result = []
    objects.map(object => {
        if (object.route && object.route.isMain) {
            result.push({ path: object.route.name, objectDefinition: object, type: object.type })
            if (object.route.details) {
                const details = getObject(object.route.details)
                result.push({ path: object.route.name + details.route.name, objectDefinition: details, type: "object" })
                result = [...result, ...generateSubRoutes(details, object, object.route.name + details.route.name, "")]
            }
        }
    })
    return result
}

function generateSubRoutes(objectDefinition, parentObjectDefinition, path) {

    var result = []
    Object.keys(objectDefinition.fields).map(key => {
        const object = objectDefinition.fields[key]
        if (objectDefinition.fields[key].route && (object.type == "object" || (object.type == "array" && object.items.type == "object"))) {
            const innerObject = getObject(object.type == "array" ? objectDefinition.fields[key].items.format : objectDefinition.fields[key].format)
            // const resultObjectDefinition = objectDefinition.fields[key].needsFetching ? (parentObjectDefinition.details ? getObject(parentObjectDefinition.details) : parentObjectDefinition) : (innerObject.urls ? innerObject : parentObjectDefinition.isMainRoute ? objectDefinition : parentObjectDefinition)
            // const resultRoute = objectDefinition.fields[key].needsFetching ? key : (innerObject.urls ? "" : route + "." + key)

            result.push({ path: path + "/" + objectDefinition.fields[key].route.name, objectDefinition: objectDefinition.fields[key], type: "object", parentObjectDefinition: objectDefinition })
            if (objectDefinition.fields[key].type == "array") {
                result.push({ path: path + "/" + objectDefinition.fields[key].route.name + innerObject.route.name, objectDefinition: innerObject, type: "object", parentObjectDefinition: objectDefinition.fields[key] })
                result = [...result, ...generateSubRoutes(innerObject, objectDefinition, path + "/" + objectDefinition.fields[key].route.name + innerObject.route.name)]
            } else {
                result = [...result, ...generateSubRoutes(innerObject, objectDefinition, path + "/" + objectDefinition.fields[key].route.name)]
            }
            // if (innerObject && !innerObject.isMainRoute && object.type == "array") {
            //     result.push({ path: path + "/" + key + "/:" + innerObject.name + "Id", objectDefinition: resultObjectDefinition, route: resultRoute + ".$" + innerObject.name + "Id", type: "object" })
            //     result = [...result, ...generateSubRoutes(innerObject, innerObject.urls ? innerObject : parentObjectDefinition, path + "/" + key + "/:" + innerObject.name + "Id", innerObject.urls ? "" : resultRoute + ".$" + innerObject.name + "Id")]
            // } else {
            //     result = [...result, ...generateSubRoutes(innerObject, innerObject.urls ? innerObject : parentObjectDefinition, path + "/" + key, innerObject.urls ? "" : route + "." + key)]
            // }
        }
    })

    return result
}