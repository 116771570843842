
const definition = {
    "format": "AdvisorStatistics",
    "type": "object",
    "route": {
        "name": "statistics"
    },
    "request": {
        "get": { url: "advisor/:AccountId/statistics" },
    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "block": 4
        },
        "creatorId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 4
        },
        "lastModifierId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
        },
        "isDeleted": {
            "type": "boolean"
        },
        "deleterId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
        },
        "deletionTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 4
        },
        "matrixValidatedTeamStars": {
            "type": "number",
            "format": "double",
            "block": 3
        },
        "matrixPendingTeamStars": {
            "type": "number",
            "format": "double",
            "block": 3
        },
        "matrixPendingAdvisors": {
            "type": "integer",
            "format": "int32",
            "block": 3
        },
        "matrixActiveAdvisors": {
            "type": "integer",
            "format": "int32",
            "block": 3
        },
        "matrixPendingFirstLevelAdvisors": {
            "type": "integer",
            "format": "int32",
            "block": 3
        },
        "matrixActiveFirstLevelAdvisors": {
            "type": "integer",
            "format": "int32",
            "block": 3
        },
        "enrollerValidatedTeamStars": {
            "type": "number",
            "format": "double",
            "block": 3
        },
        "enrollerPendingTeamStars": {
            "type": "number",
            "format": "double",
            "block": 3
        },
        "enrollerPendingAdvisors": {
            "type": "integer",
            "format": "int32",
            "block": 3
        },
        "enrollerActiveAdvisors": {
            "type": "integer",
            "format": "int32",
            "block": 3
        },
        "enrollerPendingFirstLevelAdvisors": {
            "type": "integer",
            "format": "int32",
            "block": 3
        },
        "enrollerActiveFirstLevelAdvisors": {
            "type": "integer",
            "format": "int32",
            "block": 3
        },
        "personalStars": {
            "type": "number",
            "format": "double",
            "block": 2
        },
        "personalPendingStars": {
            "type": "number",
            "format": "double",
            "block": 2
        },
        "invalid": {
            "type": "boolean"
        },
        "alias": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "sponsorId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "sponsorAlias": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "prefix": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "firstname": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "infix": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "lastname": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "companyname": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "email": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "phone": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "mobile": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "language": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "networkRank": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "salesRank": {
            "type": "string",
            "nullable": true,
            "block": 2
        }
    }
}
export default definition