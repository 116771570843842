import { useEffect, useState } from "react";
import {
    DataGrid, GridColDef
} from '@mui/x-data-grid';
import dataGridPagination from "../Grid/dataGridPagination";
import advisorGridHeaders from "../../../common/definitions/order/advisorGridHeaders.json"
import { generateColumnDefinition } from "../../../utils/generators/dataGridGenerators";
import { nestedToFlatJson } from "../../../utils/formatters/ObjectFormatters";
import { Box, Button } from "@mui/material";
import GridSearch from "./gridSearch"
import moment from 'moment';

function CustomDataGrid({ hideSearch, autoHeight, objectDefinition, objects, updateObjects, totalRows, isLoading, onDetailsClick, ...props }) {
    //States
    const [gridOptions, setGridOptions] = useState({
        totalRows: 0,
        rowsPerPageOptions: [5, 10, 25, 100],
        pageSize: 10,
        page: 0,
        sorting: "",
        filters: {},
    });
    const [rows, setRows] = useState([])
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({})
    const [columns, setColumns] = useState([])

    useEffect(() => {
        setGridOptions({
            totalRows: totalRows,
            rowsPerPageOptions: [5, 10, 25, 100],
            pageSize: 10,
            page: 0,
            sorting: "",
            filters: {},
        })
    }, [])

    //HOOKS
    //Update rows on change of advisors
    useEffect(() => {
        let rowsTemp = objects &&
            objects.length > 0
            ? objects.map(obj => {
                return (nestedToFlatJson(obj));
            })
            : [];
        if (objects) {
            columns.forEach(column => {
                if (column.format == "date-time") {
                    rowsTemp.forEach(row => row[column.field] = moment(row[column.field]).format("HH:mm DD/MM/YYYY"))
                }
            })
        }
        setRows([...rowsTemp]);

        setGridOptions(prevGridOptions => {
            return {
                ...prevGridOptions,
                totalRows: totalRows
            }
        })

    }, [objects, totalRows]);

    useEffect(() => {
        var columnstemp: GridColDef[] = generateColumnDefinition(objectDefinition.fields)
        columnstemp.push({
            field: 'col6',
            headerName: 'Actions',
            width: 100 * (props.actions ? props.actions.length + 1 : 1),
            renderCell: (params) => {
                return (
                    <Box display="flex" justifyContent={"center"} alignItems="center" width="100%">
                        <Box flex={1} mx={1}>
                            <Button
                                variant='contained'
                                size="small"
                                style={{ justifySelf: 'center', alignSelf: "center" }}
                                onClick={() => onDetailsClick(params.row)}
                            >
                                {props.detailsName ? props.detailsName : "Details"}
                            </Button>
                        </Box>
                        {props.actions ? props.actions.map(action =>

                            <Box key={action.name} flex={1} mr={1}>
                                <Button
                                    variant='contained'
                                    size="small"
                                    style={{ justifySelf: 'center', alignSelf: "center" }}
                                    onClick={() => action.onClick(params.row)}
                                >
                                    {action.name}
                                </Button>
                            </Box>
                        ) : null}
                    </Box >
                );
            },
            disableClickEventBubbling: true,
        })

        columnstemp.sort(function (a, b) {
            if (!advisorGridHeaders.some(x => x === a.field)) {
                return 1
            } else if (!advisorGridHeaders.some(x => x === b.field)) {
                return -1
            }
            return advisorGridHeaders.findIndex(x => x === a.field) - advisorGridHeaders.findIndex(x => x === b.field)
        })
        setColumns(columnstemp)
        var columnVisibilityModelTemp = {}
        columnstemp.forEach(column => {
            columnVisibilityModelTemp[column.field] = column.hide ? !column.hide : true
        })
        setColumnVisibilityModel(columnVisibilityModelTemp)
    }, []);

    // //Refresh advisors on change of grid
    const updateGrid = ((page, pageSize, sorting) => {
        updateObjects ? updateObjects(page, pageSize, sorting, gridOptions.filters, sorting != gridOptions.sorting ? true : false) : null
        setGridOptions(prevGridOptions => {
            return {
                ...prevGridOptions,
                page: page,
                pageSize: pageSize,
                sorting: sorting
            }
        })
    })

    // //Refresh advisors on change of grid
    const updateGridFiltering = ((newFilters) => {
        var { filters } = gridOptions
        Object.assign(filters, newFilters)
        setGridOptions(prevGridOptions => {
            return {
                ...prevGridOptions,
                filters,
                page: 0,
                totalRows: 0,

            }
        })
        updateObjects(0, gridOptions.pageSize, gridOptions.sorting, filters, true);
    })

    //handlers
    const handleOnPageChange = (data) => {
        updateGrid(data, gridOptions.pageSize, gridOptions.sorting)
    }

    const handleOnPageSizeChange = (data) => {
        updateGrid(0, data, gridOptions.sorting)
    }

    const handleOnSortModelChange = (data) => {
        const model = data[0] ? data[0].field + " " + data[0]['sort'] : ""
        updateGrid(0, gridOptions.pageSize, model)
    }

    return (
        <Box width="100%" display="flex" flexDirection="column" height="100%">
            {!hideSearch && <Box flex="1">
                <GridSearch objectDefinition={objectDefinition} updateObjects={updateGridFiltering} filter={gridOptions.filters}></GridSearch>
            </Box>}
            <Box flex="1">
                <DataGrid
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                    autoHeight={autoHeight ? true : false}
                    getRowId={(row) => row.id ? row.id : row[objectDefinition.identifier]}
                    rows={rows}
                    disableColumnFilter={updateObjects ? true : false}
                    columns={columns}
                    page={gridOptions.page}
                    loading={isLoading}
                    rowsPerPageOptions={gridOptions.rowsPerPageOptions}
                    pageSize={gridOptions.pageSize}
                    rowCount={gridOptions.totalRows}
                    components={gridOptions.totalRows == 0 ? null : { Pagination: dataGridPagination }}
                    onPageChange={(data) => handleOnPageChange(data)}
                    onPageSizeChange={(data) => handleOnPageSizeChange(data)}
                    onSortModelChange={updateObjects ? (data) => handleOnSortModelChange(data) : null}
                />
            </Box>
        </Box >
    )
}

export default CustomDataGrid
