import React, { useEffect, useState } from 'react';
import TreeView from './TreeView';
import styles from './classes.css';
import './styles.css';
import { connect } from 'react-redux';
import TeamNode from "../../../common/definitions/objects/TeamNode"
import { JsonToURLParameters } from '../../../utils/formatters/urlFormatters';
import { Button, MenuItem, Select } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { selectDetails } from '../../../redux/data/selectors';
import { getUpdatingItems } from '../../../redux/ui/selectors';
import { dataActionTypes } from '../../../redux/actionTypes';
import { withRouter } from '../wrappers/withRouter';
import { getDetails } from '../../../redux/data/actions';
import { Box } from '@mui/system';

function Matrix(props) {
    const [rows, setRows] = useState(3)

    const [tree, setTree] = useState([]);
    const [overlay, setOverlay] = useState("Default");

    useEffect(() => {
        const url = JsonToURLParameters(props.params, TeamNode.request.get.url)
        props.getDetails(url)
    }, [])

    useEffect(() => {
        var change = false
        var newTree = [...tree]

        if (props.data) {
            if (newTree.length === 0) {
                change = true
                newTree = [{
                    node: '0:0',
                    parent_node: null,
                    state: "loading",
                    id: props.data.advisorId,
                    stars: props.data.stars,
                    teamStars: props.data.teamStars
                }]
                for (var currentRow = 1; currentRow < rows; currentRow++) {
                    for (var currentColumn = 0; currentColumn < Math.pow(3, currentRow); currentColumn++) {
                        newTree.push(
                            {
                                node: currentRow + ':' + currentColumn,
                                parent_node: (currentRow - 1) + ':' + Math.floor(currentColumn / 3),
                                state: "new",
                                stars: 0

                            }
                        )
                    }
                }
            }
            const loadingTeamNodes = props.isLoading.filter(item => item.type.includes("teams"))
            newTree ? newTree.map(node => {
                if (node.state == "new") {
                    const parent_node = newTree.find(parentNode => parentNode.node == node.parent_node)
                    if (parent_node && parent_node.state == "done") {
                        const childPosition = node.node.split(":")[1] % 3
                        change = true
                        const detailsTemp = props.details(parent_node.id)
                        if (detailsTemp.children && detailsTemp.children[childPosition]) {
                            const child = detailsTemp.children[childPosition]
                            if (!props.details(child.advisorId) && !loadingTeamNodes.some(item => item.type == JsonToURLParameters({ AccountId: child.advisorId }, TeamNode.request.get.url))) {
                                props.getDetails(JsonToURLParameters({ AccountId: child.advisorId }, TeamNode.request.get.url))
                            }
                            node.id = child.advisorId
                            node.state = "loading"
                            node.stars = 0

                        } else {
                            node.state = "empty"
                            node.stars = 0

                        }
                    } else if (parent_node && parent_node.state == "empty") {
                        change = true

                        node.state = "empty"
                        node.stars = 0
                    }
                } else if (node.state == "loading" && !loadingTeamNodes.some(item => item.type == JsonToURLParameters({ AccountId: node.id }, TeamNode.request.get.url))) {
                    const det = props.details(node.id)
                    if (det) {
                        change = true
                        node.state = "done"
                        node.id = det.advisorId
                        node.name = det.advisorFirstname + " " + det.advisorLastname
                        node.stars = det.stars ? det.stars : 0
                        node.teamStars = det.teamStars ? det.teamStars : 0
                    }
                }

            }
            ) : null
            if (change) {
                setTree(newTree)
            }

        }
    }, [props.isLoading, tree])

    const handleAddLevel = () => {
        var newLevels = []
        const newRow = rows
        for (var currentColumn = 0; currentColumn < Math.pow(3, newRow); currentColumn++) {
            newLevels.push(
                {
                    node: newRow + ':' + currentColumn,
                    parent_node: (newRow - 1) + ':' + Math.floor(currentColumn / 3),
                    state: "new",
                    stars: 0

                }
            )
        }
        setTree(oldTree => ([...oldTree, ...newLevels]))
        setRows(newRow + 1)

    }

    const treeProps = {
        overlay: overlay,
        nodeviewclasses: styles,
        style: {
            cursor: 'auto',
            outline: 'none',
        },
    };
    return (
        <div className="wrapper">
            <Box display="flex">
                <Box style={{ position: "absolute", right: 100 }}>
                    <Button onClick={handleAddLevel} style={{ position: "absolute", right: 100, width: 200 }} variant="outlined" startIcon={<AddIcon />}>
                        Add level
                    </Button>
                </Box>
                <Box style={{ position: "absolute", right: 100 }} >
                    <Select
                        onChange={e => setOverlay(e.target.value)}
                        value={overlay}
                    >
                        <MenuItem
                            value={"Default"}>Default
                        </MenuItem>
                        <MenuItem
                            value={"Activity"}>Activity
                        </MenuItem>
                    </Select>
                </Box>

            </Box>
            <TreeView overlay={overlay} tree={tree} key={tree} {...treeProps} />

        </div >
    );

}

const mapDispatchToProps = dispatch => {

    return {
        getDetails: (url) => dispatch(getDetails(url, url)),
    }
}

const mapStateToProps = (state, props) => {

    const url = JsonToURLParameters(props.params, TeamNode.request.get.url)

    return ({
        data: selectDetails(url, state),
        details: (id) => selectDetails(JsonToURLParameters({ AccountId: id }, TeamNode.request.get.url), state),
        isLoading: getUpdatingItems(state, dataActionTypes.GET_DETAILS),
    })
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Matrix))
