import { palette } from "./palette";
const { text, neutral, warning, error } = palette;

const properties = {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    xxs: `${10.5 / 16}rem`,
    xs: `${12 / 16}rem`,
    sm: `${14 / 16}rem`,
    md: `${16 / 16}rem`,
    lg: `${18 / 16}rem`,
    xl: `${20 / 16}rem`,
}

export const typography = {
    fontFamily: [
        'Monserrat'
        , 'Rubik'
    ].join(','),
    fontWeightLight: properties.fontWeightLight,
    fontWeightRegular: properties.fontWeightRegular,
    fontWeightMedium: properties.fontWeightMedium,
    fontWeightBold: properties.fontWeightBold,
    size: {
        xxs: properties.xxs,
        xs: properties.xs,
        sm: properties.sm,
        md: properties.md,
        lg: properties.lg,
        xl: properties.xl,
    },
    h1: {
        fontSize: `${40 / 16}rem`,
        fontWeight: properties.fontWeightBold,
    },

    h2: {
        fontSize: `${32 / 16}rem`,
        fontWeight: properties.fontWeightBold,
    },

    h3: {
        fontSize: `${28 / 16}rem`,
        fontWeight: properties.fontWeightBold,
        color: text.obg
    },

    h4: {
        fontSize: `${24 / 16}rem`,
    },

    h5: {
        fontSize: properties.xl,
    },

    h6: {
        fontSize: properties.md,
    },

    subtitle1: {
        fontSize: properties.xl,
    },

    subtitle2: {
        fontSize: properties.md,
        fontWeight: properties.fontWeightRegular,
        color: neutral[500]
    },

    body1: {
        fontSize: properties.sm,
        color: text.obg

    },

    body2: {
        fontSize: properties.sm,
        color: text.obg

    },
    body3: {
        fontSize: properties.xs,
        color: text.active

    },
    button: {
        fontSize: properties.sm,
        textTransform: "uppercase",
    },

    caption: {
        fontSize: properties.xs,
    },
    captionPaper: {
        fontSize: properties.xs,
        color: neutral[500],
        fontWeight: properties.fontWeightMedium,

    },
    overline: {
    },

    warning: {
        fontSize: properties.xs,
        color: warning.main
    },

    error: {
        fontSize: properties.md,
        color: error.main,
        fontWeight: properties.fontWeightRegular,
    },
}