import { dataActionTypes } from '../actionTypes';

const initialState = {
    data: [],
    details: [],
    error: "",
};

export default function data(state = initialState, { type, payload }) {
    switch (type) {
        case dataActionTypes.GET_DATA:
            return state;
        case dataActionTypes.GET_DATA_SUCCES: {
            var data = { ...state.data.find(item => item.type == payload.type) }
            var temp = Object.keys(data).length === 0 ? { "type": payload.type, "totalCount": payload.response.totalCount, items: [] } : data
            temp.items = temp.items.slice(0, payload.skipCount).concat(payload.response.items)
            temp.totalCount = payload.response.totalCount
            return {
                ...state,
                data: [...state.data.filter(item => item.type != payload.type)].concat(temp)
            }
        }
        case dataActionTypes.GET_DATA_FAILED:
            return {
                ...state,
                error: payload.error ? payload.error : "",
            };
        case dataActionTypes.GET_DETAILS:
            return state;
        case dataActionTypes.GET_DETAILS_SUCCES: {
            var details = state.details ? { ...state.details.find(item => item.type == payload.type) } : {}
            temp = Object.keys(details).length === 0 ? { "type": payload.type } : details
            temp.data = payload.response
            return {
                ...state,
                details: [...state.details.filter(item => item.type != payload.type)].concat(temp)
            }
        }
        case dataActionTypes.GET_DETAILS_FAILED:
            return {
                ...state,
                error: payload.error ? payload.error : "",
            };
        case dataActionTypes.RESET_DATA:
            return {
                ...state,
                data: [...state.data.filter(item => item.type == payload)]
            };
        case dataActionTypes.POST_DATA:
            return state;
        case dataActionTypes.POST_DATA_SUCCES: {
            const temp = { "type": payload.response }
            temp.data = { ...payload.data }

            return {
                ...state,
                details: [...state.details.filter(item => item.type != payload.response)].concat(temp)
            };
        }
        case dataActionTypes.POST_DATA_FAILED:
            return {
                ...state,
                error: payload ? payload : "",
            };

        case dataActionTypes.DELETE_DATA_SUCCES: {
            const temp = { "type": payload.response }
            temp.data = { ...payload.data }

            return {
                ...state,
                details: [...state.details.filter(item => item.type != payload.response)].concat(temp)
            };
        }

        case dataActionTypes.DELETE_DATA_FAILED:
            return {
                ...state,
                error: payload ? payload : "",
            };
        case dataActionTypes.PUT_DATA:
            return state;
        case dataActionTypes.PUT_DATA_SUCCES: {
            const temp = { "type": payload.response }
            temp.data = { ...payload.data }

            return {
                ...state,
                details: [...state.details.filter(item => item.type != payload.response)].concat(temp)
            };
        }
        case dataActionTypes.PUT_DATA_FAILED:
            return {
                ...state,
                error: payload ? payload : "",
            };
        default:
            return state;
    }
};
