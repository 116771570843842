
const definition = {
    "format": "MatrixNode",

    "fields": {
        "sponsorId": {
            "type": "string",
            "format": "uuid",
            "nullable": true
        },
        "parentId": {
            "type": "string",
            "format": "uuid",
            "nullable": true
        },
        "position": {
            "type": "integer",
            "format": "int32"
        },
        "numberOfLegs": {
            "type": "integer",
            "format": "int32"
        },
        "matrixLevel": {
            "type": "integer",
            "format": "int32"
        },
        "enrollerLevel": {
            "type": "integer",
            "format": "int32"
        }
    }
}
export default definition