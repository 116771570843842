import { Search } from "@mui/icons-material";
import { Autocomplete, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { dataActionTypes } from "../../../redux/actionTypes";
import { getData } from "../../../redux/data/actions";
import { selectData } from "../../../redux/data/selectors";
import { getUpdatingItems } from "../../../redux/ui/selectors";
import { JsonToURLParameters } from "../../../utils/formatters/urlFormatters";
import { withRouter } from "../../Components/wrappers/withRouter";
import PersonIcon from '@mui/icons-material/Person';
import Face from '@mui/icons-material/Face';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Receipt from '@mui/icons-material/Receipt';

function SearchBox(props) {
    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const { t } = useTranslation()
    const CustomPaper = (props) => {
        return <Paper elevation={5} {...props} />;
    };


    const handleChange = (e) => {
        setSearchValue(e.target.value)
        const newOptions = []
        if (/^([c]{1})?\d+$/i.test(e.target.value)) {
            //Contracts
            const contracts = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "contracts?Number=:searchContent"))
            if (!contracts) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "contracts?Number=:searchContent"))
            } else {
                contracts.map(item => newOptions.push({ title: item.number, type: "Contract", url: JsonToURLParameters({ AccountId: item.id }, "/contract/:AccountId") }))
            }
        } else if (/^([o]{1})?\d+$/i.test(e.target.value)) {
            //Orders
            const orders = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "orders?Number=:searchContent"))
            if (!orders) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "orders?Number=:searchContent"))
            } else {
                orders.map(item => newOptions.push({ title: item.number, type: "Order", url: JsonToURLParameters({ AccountId: item.id }, "/order/:AccountId") }))
            }
        } else if (/^([i]{1})?\d+$/i.test(e.target.value)) {
            //Invoices
            const invoices = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "invoice?Number=:searchContent"))
            if (!invoices) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "invoice?Number=:searchContent"))
            } else {
                invoices.map(item => newOptions.push({ title: item.number, type: "Invoice", url: JsonToURLParameters({ AccountId: item.id }, "/invoice/:AccountId") }))
            }
        } else if (/^([a]{1})?\d+$/i.test(e.target.value)) {
            //Advisors
            var advisors = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "advisor?Number=:searchContent"))
            if (!advisors) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "advisor?Number=:searchContent"))
            } else {
                advisors.map(item => newOptions.push({ title: item.account.number + ", " + item.account.accountName, type: "Advisor", url: JsonToURLParameters({ AccountId: item.id }, "/advisor/:AccountId") }))
            }
            //Customers
            var customers = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "customers?Number=:searchContent"))
            if (!customers) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "customers?Number=:searchContent"))
            } else {
                customers.map(item => newOptions.push({ title: item.account.number + ", " + item.account.person.firstname + " " + item.account.person.lastname, type: "Customer", url: JsonToURLParameters({ AccountId: item.id }, "/customer/:AccountId") }))
            }
        } else {
            //Advisors
            var advisors2 = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "advisor?Number=:searchContent"))
            if (!advisors2) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "advisor?Number=:searchContent"))
            } else {
                advisors2.map(item => newOptions.push({ title: item.account.number + ", " + item.account.accountName, type: "Advisor", url: JsonToURLParameters({ AccountId: item.id }, "/advisor/:AccountId") }))
            }
            //Advisors email
            advisors2 = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "advisor?Email=:searchContent"))
            if (!advisors2) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "advisor?Email=:searchContent"))
            } else {
                advisors2.map(item => newOptions.push({ title: item.account.number + ", " + item.account.accountName, type: "Advisor", url: JsonToURLParameters({ AccountId: item.id }, "/advisor/:AccountId") }))
            }
            //Advisors alias
            advisors2 = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "advisor?Alias=:searchContent"))
            if (!advisors2) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "advisor?Alias=:searchContent"))
            } else {
                advisors2.map(item => newOptions.push({ title: item.account.number + ", " + item.account.accountName, type: "Advisor", url: JsonToURLParameters({ AccountId: item.id }, "/advisor/:AccountId") }))
            }
            //Customers
            var customers2 = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "customers?Number=:searchContent"))
            if (!customers2) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "customers?Number=:searchContent"))
            } else {
                customers2.map(item => newOptions.push({ title: item.account.number + ", " + item.account.person.firstname + " " + item.account.person.lastname, type: "Customer", url: JsonToURLParameters({ AccountId: item.id }, "/customer/:AccountId") }))
            }
            //Customers name
            customers2 = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "customers?Name=:searchContent"))
            if (!customers2) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "customers?Name=:searchContent"))
            } else {
                customers2.map(item => newOptions.push({ title: item.account.number + ", " + item.account.person.firstname + " " + item.account.person.lastname, type: "Customer", url: JsonToURLParameters({ AccountId: item.id }, "/customer/:AccountId") }))
            }
            //Customers email
            customers2 = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "customers?Email=:searchContent"))
            if (!customers2) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "customers?Email=:searchContent"))
            } else {
                customers2.map(item => newOptions.push({ title: item.account.number + ", " + item.account.person.firstname + " " + item.account.person.lastname, type: "Customer", url: JsonToURLParameters({ AccountId: item.id }, "/customer/:AccountId") }))
            }
            //Contracts
            const contracts = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "contracts?Number=:searchContent"))
            if (!contracts) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "contracts?Number=:searchContent"))
            } else {
                contracts.map(item => newOptions.push({ title: item.number, type: "Contract", url: JsonToURLParameters({ AccountId: item.id }, "/contract/:AccountId") }))
            }

            //Orders
            const orders = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "orders?Number=:searchContent"))
            if (!orders) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "orders?Number=:searchContent"))
            } else {
                orders.map(item => newOptions.push({ title: item.number, type: "Order", url: JsonToURLParameters({ AccountId: item.id }, "/order/:AccountId") }))
            }

            //Invoices
            const invoices = props.selectData(JsonToURLParameters({ searchContent: e.target.value }, "invoice?Number=:searchContent"))
            if (!invoices) {
                props.getData(JsonToURLParameters({ searchContent: e.target.value }, "invoice?Number=:searchContent"))
            } else {
                invoices.map(item => newOptions.push({ title: item.number, type: "Invoice", url: JsonToURLParameters({ AccountId: item.id }, "/invoice/:AccountId") }))
            }
        }
        setOptions(newOptions)
    }

    useEffect(() => {
        const newOptions = []
        //Advisors
        var advisors = props.selectData(JsonToURLParameters({ searchContent: searchValue }, "advisor?Number=:searchContent"))
        if (advisors) {
            advisors.map(item => newOptions.push({ title: item.account.number + " ,  " + item.account.accountName, type: "Advisor", url: JsonToURLParameters({ AccountId: item.id }, "/advisor/:AccountId") }))
        }
        //Advisors
        advisors = props.selectData(JsonToURLParameters({ searchContent: searchValue }, "advisor?Email=:searchContent"))
        if (advisors) {
            advisors.map(item => newOptions.push({ title: item.account.number + " ,  " + item.account.accountName, type: "Advisor", url: JsonToURLParameters({ AccountId: item.id }, "/advisor/:AccountId") }))
        }
        //Advisors
        advisors = props.selectData(JsonToURLParameters({ searchContent: searchValue }, "advisor?Alias=:searchContent"))
        if (advisors) {
            advisors.map(item => newOptions.push({ title: item.account.number + " ,  " + item.account.accountName, type: "Advisor", url: JsonToURLParameters({ AccountId: item.id }, "/advisor/:AccountId") }))
        }
        //Customers
        var customers = props.selectData(JsonToURLParameters({ searchContent: searchValue }, "customers?Number=:searchContent"))
        if (customers) {
            customers.map(item => newOptions.push({ title: item.account.number + ", " + item.account.person.firstname + " " + item.account.person.firstname, type: "Customer", url: JsonToURLParameters({ AccountId: item.id }, "/customer/:AccountId") }))
        }
        //Customers
        customers = props.selectData(JsonToURLParameters({ searchContent: searchValue }, "customers?Name=:searchContent"))
        if (customers) {
            customers.map(item => newOptions.push({ title: item.account.number + ", " + item.account.person.firstname + " " + item.account.person.firstname, type: "Customer", url: JsonToURLParameters({ AccountId: item.id }, "/customer/:AccountId") }))
        }
        //Customers
        customers = props.selectData(JsonToURLParameters({ searchContent: searchValue }, "customers?Email=:searchContent"))
        if (customers) {
            customers.map(item => newOptions.push({ title: item.account.number + ", " + item.account.person.firstname + " " + item.account.person.firstname, type: "Customer", url: JsonToURLParameters({ AccountId: item.id }, "/customer/:AccountId") }))
        }
        //Contracts
        const contracts = props.selectData(JsonToURLParameters({ searchContent: searchValue }, "contracts?Number=:searchContent"))
        if (contracts) {
            contracts.map(item => newOptions.push({ title: item.number, type: "Contract", url: JsonToURLParameters({ AccountId: item.id }, "/contract/:AccountId") }))
        }
        //Orders
        const orders = props.selectData(JsonToURLParameters({ searchContent: searchValue }, "orders?Number=:searchContent"))
        if (orders) {
            orders.map(item => newOptions.push({ title: item.number, type: "Order", url: JsonToURLParameters({ AccountId: item.id }, "/order/:AccountId") }))
        }


        //Invoices
        const invoices = props.selectData(JsonToURLParameters({ searchContent: searchValue }, "invoice?Number=:searchContent"))
        if (invoices) {
            invoices.map(item => newOptions.push({ title: item.number, type: "Invoice", url: JsonToURLParameters({ AccountId: item.id }, "/invoice/:AccountId") }))
        }
        setOptions(newOptions)

    }, [props.loadingItems])

    var debouncedChangeHandler = debounce(handleChange, 250);
    return (
        <Box flexGrow={3}>
            <Autocomplete
                freeSolo
                disableClearable
                options={options}
                filterOptions={x => x}
                getOptionLabel={option => option.title}
                loading={props.isLoading}
                PaperComponent={CustomPaper}
                onChange={(e, option) => props.navigate(option.url, { replace: true })}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.type == "Advisor" ? <PersonIcon ></PersonIcon> : null}
                        {option.type == "Customer" ? <Face ></Face> : null
                        }
                        {option.type == "Contract" ? <ReceiptLong ></ReceiptLong> : null}
                        {option.type == "Order" ? <ShoppingCart ></ShoppingCart> : null}
                        {option.type == "Invoice" ? <Receipt ></Receipt> : null}


                        <Box ml={"10px"}>
                            <Typography>{option.title}
                            </Typography>
                        </Box>
                    </Box >
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        className="search"
                        placeholder={t('search')}
                        onChange={(e) => debouncedChangeHandler(e)}
                        value={searchValue}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            endAdornment: <InputAdornment position="end" >
                                <Search color="primary"></Search>
                            </InputAdornment>
                        }}
                    />
                )}></Autocomplete >
        </Box >
    )
}
const mapDispatchToProps = dispatch => {
    return {
        getData: (url) => dispatch(getData(url, url, 5, 0, null, null)),
    }
}

const mapStateToProps = (state) => {

    return ({
        selectData: (url) => selectData(url, state),
        loadingItems: getUpdatingItems(state, dataActionTypes.GET_DATA),
        isLoading: getUpdatingItems(state, dataActionTypes.GET_DATA).length != 0,

    })
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchBox))
