const definition = {
    "format": "AccountDetails",
    "type": "object",
    "request": {
        "get": { url: "accounts/:AccountId" },
    },
    "route": { name: "/:AccountId" },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "block": 3
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "number": {
            "type": "string",
            "nullable": true
        },
        "accountName": {
            "type": "string",
            "nullable": true
        },
        "label": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "type": {
            "type": "enum",
            "format": "AccountType",
            "block": 2
        },
        "language": {
            "type": "string",
            "nullable": true
        },
        "parentId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "person": {
            "type": "object",
            "format": "Person",
            "tab_hide": true,
            "route": { name: "person" }
        },
        "company": {
            "type": "object",
            "format": "Company",
            "tab_hide": true,
            "route": { name: "company" }
        },
        "avatarFileAssetId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "addresses": {
            "format": "Address",
            "type": "array",
            "route": { name: "addresses" },
            "items": {
                "type": "object",
                "format": "Address",
            },
            "nullable": true,
            "field_hide": true,
            "object_hide": true
        },
        "contactInformation": {
            "format": "ContactInformation",
            "type": "array",
            "route": { name: "contactInformation" },
            "items": {
                "type": "object",
                "format": "ContactInformation",
            },
            "nullable": true,
            "field_hide": true,
            "object_hide": true

        },
        "bankAccounts": {
            "format": "BankAccount",
            "type": "array",
            "route": { name: "bankAccounts" },
            "items": {
                "type": "object",
                "format": "BankAccount",
            },
            "tab_name": "bankAccounts",
            "nullable": true,
            "field_hide": true,
            "object_hide": true
        },
        "contracts": {
            "format": "Contract",
            "type": "array",
            "items": {
                "type": "object",
                "format": "ContractDetails"
            },
            "route": { name: "contracts" },
            "request": {
                "get": { url: "accounts/:AccountId/contracts" }
            },
            "tab_name": "contracts",
            "field_hide": true,
            "object_hide": true
        },
        "orders": {
            "format": "Order",
            "type": "array",
            "items": {
                "type": "object",
                "format": "OrderDetails"
            },
            "route": { name: "orders" },
            "request": {
                "get": { url: "accounts/:AccountId/orders" }
            },
            "tab_name": "orders",
            "field_hide": true,
            "object_hide": true
        },
        "salesContracts": {
            "format": "Contract",
            "type": "array",
            "tab_name": "salesContracts",
            "items": {
                "type": "object",
                "format": "ContractDetails"
            },
            "route": { name: "salesContracts" },
            "request": {
                "get": { url: "accounts/:AccountId/sales-contracts" }
            },
            "field_hide": true,
            "object_hide": true
        },
        "salesOrders": {
            "format": "Order",
            "type": "array",
            "items": {
                "type": "object",
                "format": "OrderDetails"
            },
            "route": { name: "salesOrders" },
            "request": {
                "get": { url: "accounts/:AccountId/sales-orders" }
            },
            "field_hide": true,
            "object_hide": true,
            "tab_name": "salesOrders",
        },
        "invoices": {
            "format": "Invoice",
            "type": "array",
            "items": {
                "type": "object",
                "format": "InvoiceDetails"
            },
            "route": { name: "invoices" },
            "request": {
                "get": { url: "invoice?accountId=:AccountId" }
            },
            "field_hide": true,
            "object_hide": true,
            "tab_name": "invoices",
        },
        "messages": {
            "format": "Message",
            "type": "array",
            "items": {
                "type": "object",
                "format": "MessageDetails"
            },
            "route": { name: "messages" },
            "request": {
                "get": { url: "accounts/:AccountId/messages" }
            },
            "field_hide": true,
            "object_hide": true
        },
        "accountIncentives": {
            "format": "AccountIncentives",
            "type": "array",
            "items": {
                "type": "object",
                "format": "AccountIncentives"
            },
            "route": { name: "accountIncentives" },
            "request": {
                "get": { url: "account-incentives?AccountId=:AccountId" }
            },
            "object_hide": true
        }
        , "costCenter": {
            "format": "CostCenter",
            "type": "array",
            "items": {
                "type": "object",
                "format": "CostCenterDetails",
            },
            "route": { name: "costCenter" },
            "request": {
                "get": { url: "cost-center?AccountId=:AccountId" }
            },
            "hideField": true,
            "object_hide": true
        }
    }
}
export default definition
