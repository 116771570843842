
const definition = {
    "format": "BillCycle",
    "type": "object",
    "request": {
        "get": { url: "cost-center/:costCenterId", route: "billCycle" }
    },
    "route": {
        "name": "billCycle"
    },
    "numberPath": "paymentMethod",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "name": {
            "type": "string",
            "nullable": true
        },
        "description": {
            "type": "string",
            "nullable": true
        },
        "recurringPeriod": {
            "type": "object",
            "format": "Period",
            "route": { name: "billCycle/recurringPeriod" },
            "hideTab": true,
            "object_hide": true,
            "tab_name": "recurringPeriod"
        },
        "delayPeriod": {
            "type": "object",
            "format": "Period",
            "route": { name: "billCycle/delayPeriod" },
            "object_hide": true,
            "tab_name": "delayPeriod"
        },
        "startDate": {
            "type": "string",
            "format": "date-time",
            "block": 2
        }
    }
}
export default definition