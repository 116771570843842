
const definition = {
    "format": "CostCenterDetails",
    "type": "object",
    "request": {
        "get": { url: "cost-center/:costCenterId" }
    },
    "route": {
        "name": "/:costCenterId"
    },
    "numberPath": "paymentMethod",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "paymentMethod": {
            "type": "enum",
            "format": "PaymentType"
        },
        "paymentDeliveryMethod": {
            "type": "enum",
            "format": "PaymentDeliveryMethod"
        },
        "billCycleId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,

        },
        "billCycle": {
            "type": "object",
            "format": "BillCycle",
            "object_hide": true,
            "route": { name: "billCycle" },
            "request": {
                "get": { url: "cost-center/:costCenterId", route: "billCycle" }
            },
        }
    }
}
export default definition