const definition = {
    "format": "Account",
    "type": "object",
    "request": {
        "get": { url: "accounts/:AccountId" },
        "put": { url: "accounts/:AccountId", format: "AccountCreate", response: "AccountDetails" },
        "post": { url: "accounts", format: "AccountCreate", response: "AccountDetails" }

    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true,
            "field_hide": true
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "column_hide": true,
            "block": 3
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
            "block": 3
        },
        "number": {
            "type": "string",
            "nullable": true,
            "columnFlex": 0.5,
            "block": 1,
            "column_search": true,
            "column_filterParameter": "Number"
        },
        "label": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
            "block": 1
        },
        "Email": {
            "type": "string",
            "column_search": true,
            "column_filterParameter": "Email",
            "column_hide": true,
        },
        "type": {
            "type": "enum",
            "format": "AccountType",
            "columnFlex": 0.5,
            "column_hide": true,
            "block": 2
        },
        "parentId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "column_hide": true,
            "field_hide": true
        },
        "person": {
            "type": "object",
            "format": "Person",
            "tab_hide": true
        },
        "company": {
            "type": "object",
            "format": "Company",
            "tab_hide": true
        },
        "accountName": {
            "type": "string",
            "nullable": true,
            "columnFlex": 0.5,
        },
        "avatarFileAssetId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "column_hide": true,
            "field_hide": true
        },
        "language": {
            "type": "string",
            "nullable": true,
            "columnFlex": 0.5,
            "block": 2
        },
    }
}
export default definition