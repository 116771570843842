
const definition = {
    "format": "MessageBody",
    "type": "object",
    "fields": {
        "body": {
            "type": "html",
            "field_hide": true
        },

    }
}
export default definition