import { Avatar, Divider, Drawer, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { palette } from "../../../common/theme/base/palette"

function CustomDrawer({ pageNames, redirects, settings, settingsRedirects, roles, name, routeChange }) {
    const [state, setState] = useState({
        open: false,
    });

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, open: open });
    };

    const list = () => (
        <Box
            sx={{ width: 150, alignItems: "flex-end", display: "flex", flexDirection: "column" }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <Box ml={1} mt={1} display="flex" flexDirection="column">
                <IconButton >
                    <Avatar sx={{ backgroundColor: palette.background.obg }}>{name.slice(0, 1)}</ Avatar >
                </IconButton>
                <Box display="flex" flexDirection="column" alignItems="flex-end" mr={2} mt={2}>
                    <Typography variant="body1" fontWeight='bold'>
                        {name}
                    </Typography>
                    <Typography variant="body3" color={palette.action.active}>
                        {roles}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <List>
                {settings.map((text, index) => (
                    <ListItem button key={text} onClick={() => routeChange(settingsRedirects[index])}>
                        <ListItemText primary={text} style={{ display: 'flex', justifyContent: 'flex-end' }} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {pageNames.map((text, index) => (
                    <ListItem button key={text} onClick={() => routeChange(redirects[index])}>
                        <ListItemText primary={text} style={{ display: 'flex', justifyContent: 'flex-end' }} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box position="absolute" width="100vw" display="flex" sx={{ justifyContent: "flex-end", alignItems: "center" }}>
            <Drawer
                anchor="right"
                open={state.open}
                onClose={toggleDrawer(false)}
            >
                {list()}
            </Drawer>
            <IconButton aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
            </IconButton>
        </Box>
    )
}

export default CustomDrawer