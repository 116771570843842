import * as yup from 'yup';
import validator from 'validator';
yup.addMethod(yup.string, "iban", function (errorMessage) {
    return this.test(`test-iban`, errorMessage, function (value) {
        const { path, createError } = this;
        return (
            (!value || value != "" && validator.isIBAN(value)) ||
            createError({ path, message: errorMessage })
        );
    });
});
yup.addMethod(yup.string, "bic", function (errorMessage) {
    return this.test(`test-bic`, errorMessage, function (value) {
        const { path, createError } = this;
        return (
            (!value || value != "" && validator.isBIC(value)) ||
            createError({ path, message: errorMessage })
        );
    });
});