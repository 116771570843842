import ContractButtons from "../../../Views/Components/Contract/ContractButtons";

export default {
    "format": "ContractDetails",
    "type": "object",
    "request": {
        "get": { url: "contracts/:ContractId" },
    },
    "route": {
        "name": "/:ContractId"
    },
    "numberPath": "number",
    "components": [
        {
            "component": ContractButtons,
            "name": "ContractButtons",
            "type": "object",
            "placement": "top"
        }
    ],
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "number": {
            "type": "string",
            "nullable": true
        },
        "status": {
            "type": "enum",
            "format": "ContractStatus"
        },
        "totalExcludingVat": {
            "type": "number",
            "format": "double",
            "block": 2
        },
        "totalIncludingVat": {
            "type": "number",
            "format": "double",
            "block": 2
        },
        "totalVat": {
            "type": "number",
            "format": "double",
            "block": 2
        },
        "currency": {
            "type": "enum",
            "format": "Currency",
            "block": 2
        },
        "acceptationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "validationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "activationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "cancellationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "terminationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "signedDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "block": 3,
            "line": 2
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3,
            "line": 2
        },
        "accountId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "sellerAccountId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "createdByOrderId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "reference": {
            "type": "string",
            "nullable": true,
            "field_hide": true
        },
        "costCenterId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "lines": {
            "format": "ContractLine",
            "type": "array",
            "items": {
                "type": "object",
                "format": "ContractLine",
            },
            "route": { name: "lines" },
            "nullable": true,
            "object_hide": true
        },
        "costCenter": {
            "format": "CostCenterDetails",
            "type": "object",
            "route": { name: ":costCenterId" },
            "request": {
                "get": { url: "cost-center/:costCenterId" }
            },
            "field_hide": true,
            "object_hide": true

        },
        "buyerAccount": {
            "format": "AccountDetails",
            "type": "object",
            "request": {
                "get": { url: "accounts/:accountId" }
            },
            "field_hide": true,
            "object_hide": true,
            "tab_name": "buyerAccount",
            "identifier": { orig: "accountId", replace: "AccountId" }

        },
        "sellerAccount": {
            "format": "AccountDetails",
            "type": "object",
            "request": {
                "get": { url: "accounts/:sellerAccountId" }
            },
            "field_hide": true,
            "object_hide": true,
            "tab_name": "sellerAccount",
            "identifier": { orig: "sellerAccountId", replace: "AccountId" }
        },
        "createdByOrder": {
            "type": "object",
            "tab_name": "createdByOrder",
            "format": "Order",
            "request": {
                "get": { url: "orders/:createdByOrderId" }
            },
            "hideField": true,
            "object_hide": true
        },
        "accountIncentives": {
            "format": "AccountIncentives",
            "type": "array",
            "items": {
                "type": "object",
                "format": "AccountIncentives"
            },
            "request": {
                "get": { url: "account-incentives?ContractId=:ContractId" }
            },
            "route": { name: "accountIncentives" },
            "hideField": true,
            "object_hide": true
        },
    }

}
