import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./common/theme/index"
import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import Routes from "./Router/routes"
import "./utils/generators/yupExtenstions"

function App() {

  const history = createBrowserHistory();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter history={history}>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );

}

export default App;
