import { getEnum, getObject } from "../objectDefinitionUtils/basicUtils"

export function generateTabDefinition(objectDefinition, name = "general", prefix = "") {
    var innerTab = []
    var tempTab = {}
    const definition = getObject(objectDefinition.type == "array" ? objectDefinition.items.format : objectDefinition.format)

    Object.keys(definition.fields).forEach(key => {
        if (!["object", "array"].includes(definition.fields[key].type)) {
            tempTab[key] = { ...definition.fields[key], field: prefix == "" ? key : prefix + "." + key }
        }
    })
    innerTab.push({ name: name, type: objectDefinition.type ? objectDefinition.type : "object", field: prefix, format: objectDefinition.items?.format ? objectDefinition.items.format : null, fields: { ...tempTab } })

    Object.keys(definition.fields).forEach(key => {
        if (definition.fields[key].type == "object") {
            innerTab.push(...generateTabDefinition(definition.fields[key], key, prefix == "" ? key : prefix + "." + key))
        } else if (definition.fields[key].type == "array") {
            innerTab.push(...generateTabDefinition(definition.fields[key], key, prefix == "" ? key : prefix + "." + key))
        }
    })
    return innerTab

}

export function generateInitialValues(objectDefinition) {
    var initialValue = {}

    Object.keys(objectDefinition.fields).forEach(key => {
        if (!["object", "array"].includes(objectDefinition.fields[key].type)) {
            if (objectDefinition.fields[key].nullable) {
                initialValue[key] = null

            } else if (objectDefinition.fields[key].type == "boolean") {
                initialValue[key] = false

            } else if (objectDefinition.fields[key].type == "enum") {
                initialValue[key] = getEnum(objectDefinition.fields[key].format).array[0]

            } else if (objectDefinition.fields[key].format == "date-time" && objectDefinition.fields[key].type == "string") {

                initialValue[key] = new Date()

            } else {
                initialValue[key] = ""
            }
        } else {
            if (objectDefinition.fields[key].type == "object") {
                initialValue[key] = generateInitialValues(getObject(objectDefinition.fields[key].format))
            } else {
                initialValue[key] = []
            }
        }
    })

    return initialValue

}