import { createTheme } from "@mui/material/styles";
import { palette } from "./base/palette";
import { breakpoints } from "./base/breakpoints";
import { typography } from "./base/typography";
import { button } from "./components/button"
import { borders } from "./base/borders"
import { inputBase } from "./components/inputBase"
import { formControl } from "./components/formControl";
import { formControlLabel } from "./components/formControlLabel";
import { switchButton } from "./components/switchButton";
import { formHelperText } from "./components/formHelperText";
import { appBar } from "./components/appBar";
import { toolBar } from "./components/toolBar";
import { autoComplete } from "./components/autoComplete";
import { dataGrid } from "./components/dataGrid";

export default createTheme({
    palette: { ...palette },
    breakpoints: { ...breakpoints },
    typography: { ...typography },
    borders: { ...borders },
    components: {
        MuiButton: { ...button },
        MuiInputBase: { ...inputBase },
        MuiFormControl: { ...formControl },
        MuiFormControlLabel: { ...formControlLabel },
        MuiSwitch: { ...switchButton },
        MuiFormHelperText: { ...formHelperText },
        MuiAppBar: { ...appBar },
        MuiToolbar: { ...toolBar },
        MuiAutocomplete: { ...autoComplete },
        MuiDataGrid: { ...dataGrid },
    }
})