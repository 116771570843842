


const definition = {
    "format": "AccountIncentiveTarget",
    "type": "object",
    "route": {
        "name": "/:AccountIncentiveTargetId"
    },
    "request": {
        "get": { url: "account-incentives/:AccountIncentivesId", route: "targets/:AccountIncentiveTargetId" },
    },
    "numberPath": "target",
    "identifier": "name",
    "fields": {
        "criteriaId": {
            "type": "string",
            "format": "uuid",
            "column_hide": true,
            "field_hide": true
        },
        "criteriaType": {
            "type": "enum",
            "format": "TargetType",
            "column_flex": 0.5,
            "block": 2
        },
        "startDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_flex": 1,
            "block": 3
        },
        "endDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_flex": 1,
            "block": 3
        },
        "name": {
            "type": "string",
            "nullable": true,
            "column_flex": 1,
            "block": 2
        },
        "target": {
            "type": "number",
            "format": "double",
            "column_flex": 0.5,
            "block": 2
        },
        "pending": {
            "type": "number",
            "format": "double",
            "column_flex": 0.5
        },
        "validated": {
            "type": "number",
            "format": "double",
            "column_flex": 0.5
        }
    }
}
export default definition