
const definition = {
    "format": "Contract",
    "type": "array",
    "request": {
        "get": { url: "contracts" }
    },
    "route": {
        "name": "contract",
        "isMain": true,
        "details": "ContractDetails"
    },
    "numberPath": "number",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true,

        },
        "number": {
            "type": "string",
            "nullable": true,
            "column_search": true,
            "column_filterParameter": "Number"
        },
        "status": {
            "type": "enum",
            "format": "ContractStatus",
            "column_search": true,
            "column_filterParameter": "Status"
        },
        "totalExcludingVat": {
            "type": "number",
            "format": "double",
            "column_hide": true,
        },
        "totalIncludingVat": {
            "type": "number",
            "format": "double"
        },
        "totalVat": {
            "type": "number",
            "format": "double",
            "column_hide": true,
        },
        "currency": {
            "type": "enum",
            "format": "Currency"
        },
        "acceptationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
        },
        "validationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
        },
        "activationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
        },
        "cancellationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
        },
        "terminationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
        },
        "signedDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "column_hide": true,
        },
        "accountId": {
            "type": "string",
            "format": "uuid",
            // "column_search": true,
            "column_hide": true,
            // "column_filterParameter": "AccountId"
        },
        "activationDateTo": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_hide": true,
            "column_filterParameter": "ActivationDateTo"
        },
        "activationDateFrom": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_hide": true,
            "column_filterParameter": "ActivationDateFrom"
        },
        "modificationTimeTo": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_hide": true,
            "column_filterParameter": "ModificationTimeTo"
        },
        "modificationTimeFrom": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_hide": true,
            "column_filterParameter": "ModificationTimeFrom"
        },
        "terminationDateTo": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_hide": true,
            "column_filterParameter": "TerminationDateTo"
        },
        "terminationDateFrom": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_hide": true,
            "column_filterParameter": "TerminationDateFrom"
        },
        "creationTimeFrom": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_hide": true,
            "column_filterParameter": "CreationTimeFrom"
        },
        "creationTimeTo": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_hide": true,
            "column_filterParameter": "CreationTimeTo"
        },
        "sellerAccountId": {
            "type": "string",
            "format": "uuid",
            // "column_search": true,
            "column_hide": true,
            // "column_filterParameter": "SellerAccountId"
        },
        "createdByOrderId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "column_hide": true,
        },
        "reference": {
            "type": "string",
            "nullable": true,
            "column_hide": true,
        },
        "costCenterId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "column_hide": true,
            // "column_search": true,
            // "column_filterParameter": "CostCenterId"
        }
    }
}
export default definition