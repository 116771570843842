
const definition = {
    "format": "FileAsset",
    "type": "object",
    "request": {
        "get": { url: "file-asset/:fileAssetId" }
    },
    "route": {
        "name": "fileAsset"
    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "block": 2
        },
        "creatorId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 2
        },
        "lastModifierId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "name": {
            "type": "string",
            "format": "link",
            "link_type": "contentType",
            "link_body": "content",
            "link_name": "name",
            "nullable": true
        },
        "description": {
            "type": "string",
            "nullable": true,
            "field_hide": true
        },
        "contentType": {
            "type": "string",
            "nullable": true
        },
        "contentLength": {
            "type": "integer",
            "format": "int64",
            "field_hide": true
        },
        "remarks": {
            "type": "string",
            "nullable": true,
            "field_hide": true
        },
        "location": {
            "type": "string",
            "nullable": true,
            "field_hide": true
        },
        "content": {
            "type": "string",
            "format": "byte",
            "nullable": true,
            "field_hide": true
        }
    }
}
export default definition