export function nestedToFlatJson(obj, prefix = "") {
    const simpleObj = {};

    for (let key in obj) {
        const value = obj[key];
        const type = typeof value;
        if (['string', 'boolean'].includes(type) || (type === 'number' && !isNaN(value))) {
            if (prefix == "") {
                simpleObj[key] = value;
            } else {
                simpleObj[prefix + '.' + key] = value;
            }
        } else if (type === 'object') {
            Object.assign(simpleObj, nestedToFlatJson(value, prefix == "" ? key : prefix + "." + key));
        }
    }
    return simpleObj;
}

