import merge from 'lodash.merge';

function withOptions(apiRequest, optionsToMerge) {
    return (...args) => {
        const request = apiRequest(...args);
        merge(request.options, optionsToMerge);
        return request;
    }
}

export function enhanceApiRequests(apiRequests, sharedOptions) {
    for (const key in apiRequests) {
        apiRequests[key] = withOptions(apiRequests[key], sharedOptions)
    }
}