import { call, put, takeLeading } from 'redux-saga/effects';
import {
    authActionTypes,
} from '../actionTypes';
import {
    signIn
} from './service';
import {
    startAction,
    stopAction,
    refreshActionStart,
    refreshActionStop
} from '../ui/actions';
import { signInFailed, signInSucces } from './actions';
import { getUserInfo, getUserInfoSucces } from '../user/actions';
import { getUserInfo as userService } from '../user/service'
export function* signInSaga({ type, payload }) {
    try {

        //Default check for refresh + add to UI loading state
        const { refreshing } = payload;
        yield put(refreshing ? refreshActionStart(type) : startAction(type));

        //signing in
        let response = yield call(signIn, payload);
        yield put(signInSucces(response));

        //Get user info
        yield put(getUserInfo());
        response = yield call(userService);
        yield put(getUserInfoSucces(response));

    } catch (error) {
        console.log('error authenticating', error.message);

        yield put(signInFailed(error.message));

        console.log('error authenticating', error.message);
    } finally {
        //Stop UI Loading state
        yield put(payload.refreshing ? refreshActionStop(type) : stopAction(type));
    }
}

export function* watchSignInSaga() {
    yield takeLeading(authActionTypes.SIGN_IN, signInSaga);
}
