import ApiService from '../../utils/ApiService';

export const getUserInfo = () => ApiService.callApi({
    url: process.env.REACT_APP_URL_USER_INFO,
    options: {
        method: 'GET',
        baseURL: process.env.REACT_APP_URL_BASE_AUTH,
        headers: {
            Accept: 'application/json',
        },
        validateStatus(status) {
            return [200, 400, 401].includes(status);
        },
    },
    includeAuthorizationHeader: true,
})


