import { formatText } from "../formatters/text";
import { getObject } from "../objectDefinitionUtils/basicUtils";
export function generateColumnDefinition(definition, columns = [], prefix = "") {
    for (let key in definition) {
        var value = definition[key];
        const type = typeof value;
        if (type == 'object' && value.type != "array" && key != "validationSchema") {
            if (value.type === "object") {
                value = getObject(value.format).fields
            }
            columns.concat(generateColumnDefinition(value, columns, key == "format" ? prefix : prefix == "" ? key : prefix + "." + key, definition));
        } else if (['format', 'nullable', 'column_hide', 'column_minWidth', 'column_flex', "column_description"].includes(key)) {
            if (key === 'column_hide' && value === true) {
                let column = columns.find(column => column.field === prefix)
                column ? column['hide'] = true : null
            } else if (key === 'column_minWidth') {
                let column = columns.find(column => column.field === prefix)
                column ? column['minWidth'] = value : null
            } else if (key === 'column_flex') {
                let column = columns.find(column => column.field === prefix)
                column ? column['flex'] = value : null
            } else if (key === 'column_description') {
                let column = columns.find(column => column.field === prefix)
                column ? column['headerName'] = value : null
            }
        } else
            if (key === 'type' && ['string', 'number', 'boolean', 'enum'].includes(value)) {
                let headerName = formatText(prefix.split(".").pop());
                if (columns.some(column => column.headerName == headerName)) {
                    const temp = prefix.split(".").reverse()[1]
                    if (temp) {
                        headerName = formatText(prefix.split(".").reverse()[1]) + " " + formatText(prefix.split(".").pop())
                    } else {
                        headerName = formatText(prefix.split(".").pop())
                    }
                }
                columns.push({ field: prefix, headerName: headerName, flex: 1, format: definition.format ? definition.format : null })
            }
    }
    return columns
}

export function generateSearchDefinition(definition, searchDefs = [], prefix = "") {

    for (let key in definition) {
        var value = definition[key];
        const type = typeof value;
        const isArray = Array.isArray(value)

        if (type == 'object' && !isArray) {
            if (value.type === "object") {
                value = getObject(value.format).fields
            }
            searchDefs.concat(generateSearchDefinition(value, searchDefs, key == "format" ? prefix : prefix == "" ? key : prefix + "." + key));
        } else if (['column_search', 'column_filterParameter', 'type', 'format'].includes(key)) {
            if (key === 'column_search' && value === true) {
                let search = searchDefs.find(search => search.field === prefix)
                search ? Object.assign(search, {
                    field: prefix,
                    allowFilter: true
                }) : searchDefs.push({ field: prefix, name: prefix, allowFilter: true })
            } else if (key === 'column_filterParameter') {
                let search = searchDefs.find(search => search.field === prefix)
                search ? search['name'] = value : searchDefs.push({ field: prefix, name: value, title: value })
            } else if (key === 'type') {
                let search = searchDefs.find(search => search.field === prefix)
                search ? search['type'] = value : searchDefs.push({ field: prefix, type: value })
            } else if (key === 'format') {
                let search = searchDefs.find(search => search.field === prefix)
                search ? search['format'] = value : searchDefs.push({ field: prefix, format: value })
            }
        }
    }
    return searchDefs.filter((def) => def.allowFilter)
}


