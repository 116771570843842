// Objects
import Person from "./objects/Person"
import Period from "./objects/Period"
import Payment from "./objects/Payment"
import OrderLine from "./objects/OrderLine"
import OrderDetails from "./objects/OrderDetails"
import Order from "./objects/Order"
import MessageDetails from "./objects/MessageDetails"
import Message from "./objects/Message"
import MatrixNode from "./objects/MatrixNode"
import InvoiceLine from "./objects/InvoiceLine"
import InvoiceDetails from "./objects/InvoiceDetails"
import Invoice from "./objects/Invoice"
import CustomerDetails from "./objects/CustomerDetails"
import Customer from "./objects/Customer"
import CostCenterDetails from "./objects/CostCenterDetails"
import CostCenter from "./objects/CostCenter"
import ContractLine from "./objects/ContractLine"
import ContractDetails from "./objects/ContractDetails"
import Contract from "./objects/Contract"
import ContactInformation from "./objects/ContactInformation"
import Company from "./objects/Company"
import BillCycle from "./objects/BillCycle"
import BankAccount from "./objects/BankAccount"
import AdvisorStatistics from "./objects/AdvisorStatistics"
import AdvisorDetails from "./objects/AdvisorDetails"
import Advisor from "./objects/Advisor"
import AddressValue from "./objects/AddressValue"
import Address from "./objects/Address"
import AccountIncentiveTarget from "./objects/AccountIncentiveTarget"
import AccountIncentives from "./objects/AccountIncentives"
import AccountDetails from "./objects/AccountDetails"
import Account from "./objects/Account"
import TeamNode from "./objects/TeamNode"
import MessageBody from "./objects/MessageBody"
import AccountIdentifier from "./objects/AccountIdentifier"
import FileAsset from "./objects/FileAsset"
import AccountBalance from "./objects/AccountBalance"
import BankAccountCreate from "./objects/BankAccountCreate"
import ContactInformationCreate from "./objects/ContactInformationCreate"
import AddressCreate from "./objects/AddressCreate"
import CustomerCreate from "./objects/CustomerCreate"
import AccountCreate from "./objects/AccountCreate"
import CompanyCreate from "./objects/CompanyCreate"
import PersonCreate from "./objects/PersonCreate"

// Enums
import AccountType from "./enum/AccountType"
import AdvisorStatus from "./enum/AdvisorStatus"
import BonusStatus from "./enum/BonusStatus"
import CompanyType from "./enum/CompanyType"
import ContractStatus from "./enum/ContractStatus"
import Currency from "./enum/Currency"
import Gender from "./enum/Gender"
import InvoiceStatus from "./enum/InvoiceStatus"
import InvoiceType from "./enum/InvoiceType"
import MessageState from "./enum/MessageState"
import MessageType from "./enum/MessageType"
import OrderStatus from "./enum/OrderStatus"
import PaymentDeliveryMethod from "./enum/PaymentDeliveryMethod"
import PaymentMethod from "./enum/PaymentMethod"
import PaymentStatus from "./enum/PaymentStatus"
import PaymentType from "./enum/PaymentType"
import PeriodUnit from "./enum/PeriodUnit"
import TargetType from "./enum/TargetType"
import TeamScope from "./enum/TeamScope"
import ValueType from "./enum/ValueType"
import VatStatus from "./enum/VatStatus"



export const enums = [
    AccountType
    , AdvisorStatus
    , BonusStatus
    , CompanyType
    , ContractStatus
    , Currency
    , Gender
    , InvoiceStatus
    , InvoiceType
    , MessageState
    , MessageType
    , OrderStatus
    , PaymentDeliveryMethod
    , PaymentMethod
    , PaymentStatus
    , PaymentType
    , PeriodUnit
    , TargetType
    , TeamScope
    , ValueType
    , VatStatus
]
export const objects = [
    Person
    , Period
    , Payment
    , OrderLine
    , OrderDetails
    , Order
    , MessageDetails
    , Message
    , MatrixNode
    , InvoiceLine
    , InvoiceDetails
    , Invoice
    , CustomerDetails
    , Customer
    , CostCenterDetails
    , CostCenter
    , ContractLine
    , ContractDetails
    , Contract
    , ContactInformation
    , Company
    , BillCycle
    , BankAccount
    , AdvisorStatistics
    , AdvisorDetails
    , Advisor
    , AddressValue
    , AccountIncentiveTarget
    , AccountIncentives
    , AccountDetails
    , Account
    , Address
    , MessageBody
    , AccountIdentifier
    , FileAsset
    , TeamNode
    , AccountBalance
    , BankAccountCreate
    , ContactInformationCreate
    , AddressCreate
    , CustomerCreate
    , AccountCreate
    , CompanyCreate
    , PersonCreate

]