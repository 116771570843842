import * as yup from 'yup';
import "../../../utils/generators/yupExtenstions"

const definition = {
    "format": "BankAccountCreate",
    "fields": {
        "bic": {
            "type": "string",
            "nullable": true
            , validationSchema: yup.string('').nullable().bic("not a valid bic")
        },
        "iban": {
            "type": "string",
            "nullable": true
            , validationSchema: yup.string('').nullable().required("IBAN is required").iban("not a valid iban")

        }
    }
}
export default definition