import { CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { palette } from '../../../common/theme/base/palette'
export default class MatrixNode extends React.Component {

    render() {
        const { description, id, name, state, backgroundColor, overlay } = this.props
        return (
            <div className={"view"} id={id}
                style={{
                    height: overlay == "Default" ? "200px" : null, width: overlay == "Default" ? "200px" : null,
                    backgroundColor: state == "empty" ? "rgba(55, 65, 81, 0.12)" : backgroundColor,
                    marginRight: overlay == "Default" ? "40px" : "0px"
                }} >
                {overlay == "Default" ?
                    <Box>
                        <Typography variant="h5">{name}</Typography>
                        <Typography variant="body3" style={{ color: palette.background.paper }} >{id}</Typography>
                        {state == "loading" ? <CircularProgress></CircularProgress> :
                            <Typography variant="body1" color={"background.paper"} style={{ whiteSpace: 'pre-line' }}>{description}</Typography>}
                    </Box> : null}
            </ div>
        )
    }
}