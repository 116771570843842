import axios from 'axios';
import reduxConfig from '../redux/store';
import { selectAccessToken } from '../redux/auth/selectors';

const defaultOptions = {
    baseURL: process.env.REACT_APP_URL_BASE_API,
    headers: {
        Accept: 'application/json'
    },
    validateStatus(status) {
        return [200, 201].includes(status);
    },
    timeout: 50000
};

async function callApi({ url, options, includeAuthorizationHeader = true }) {
    let fetchOptions = { ...defaultOptions, ...options };
    if (includeAuthorizationHeader) {
        const accessToken = selectAccessToken(reduxConfig.store.getState());
        fetchOptions = {
            ...fetchOptions,
            headers: { ...fetchOptions.headers, Authorization: `${accessToken}` }
        };
    }
    return axios(url, fetchOptions);
}

export default {
    callApi
};

