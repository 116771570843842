
const definition = {
    "format": "BankAccount",
    "type": "object",
    "route": {
        "name": "/:bankAccountId"
    },
    "request": {
        "get": { url: "accounts/:AccountId", route: "bankAccounts/:bankAccountId" },
        "put": { url: "accounts/:AccountId/bank-account/:bankAccountId", format: "BankAccountCreate", response: "AccountDetails" },
        "delete": { url: "accounts/:AccountId/bank-account/:bankAccountId", role: "Admin", response: "AccountDetails", redirect: "/advisor/:AccountId/account/bankAccounts" },
        "post": { url: "accounts/:AccountId/bank-account", format: "BankAccountCreate", response: "AccountDetails" }
    },
    "numberPath": "label",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true,
            "field_hide": true
        },
        "iban": {
            "type": "string",
            "nullable": true
        },
        "bic": {
            "type": "string",
            "nullable": true
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
            "block": 2,
            "hideIfEmpty": true
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "column_hide": true,
            "hideIfEmpty": true,
            "block": 2
        }
    }
}
export default definition