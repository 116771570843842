import { palette } from "../base/palette"
export const appBar = {
    styleOverrides: {
        // root: {
        //     // flexGrow: 1,
        //     // height: `${52 / 16}rem`,
        //     paddingLeft: 0,
        //     whiteSpace: "nowrap",
        //     border: "none",
        //     height: `5%vh`,
        //     minHeight: `5%vh`,
        // },
        colorPrimary: {
            backgroundColor: palette.background.default
        },
        // paper: {
        //     height: `5%vh`,
        //     margin: `${16 / 16}rem`,
        //     border: "none",
        // },
        // ".MuiContainer-root": {
        //     paddingLeft: 0

        // },
        // paperAnchorDockedLeft: {
        //     borderRight: "none",
        // },
    }
}