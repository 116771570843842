import { Box } from "@mui/system";
import ObjectDetails from "./objectDetails";
import { getObject } from "../../../utils/objectDefinitionUtils/basicUtils";

function DetailsTabPanel({ objectDefinition, parentObjectDefinition, index, value, ...props }) {
    //Constants
    const object = getObject(objectDefinition.format)
    return (
        <Box
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...props}
        >
            {value === index && (
                <Box >
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}>  {
                            objectDefinition.components ? objectDefinition.components.filter(component => component.type == "object" && component.placement == "top").map((component, index) => {
                                return (
                                    <Box key={index} sx={{ flex: "0 0 100%", flexGrow: 1 }} >
                                        <component.component objectDefinition={objectDefinition} />
                                    </Box>)
                            })
                                : null
                        }
                        {
                            <Box key={"General"} sx={{
                                flex: objectDefinition.type == "array" ? "0 0 100%" : null, flexGrow: 1, overflowY: "auto", height: "100%"
                            }} >
                                < ObjectDetails parentObjectDefinition={parentObjectDefinition} hideMoreInfo={props.label == "General" ? true : false} objectDefinition={objectDefinition} name={object.format} ></ObjectDetails>
                            </Box>
                        }
                        {Object.keys(object.fields).filter(key => ["object", "array"].includes(object.fields[key].type) && !object.fields[key].object_hide).map((key) => {
                            return (
                                <Box key={key} sx={{ flex: object.fields[key].type == "array" ? "0 0 100%" : null, flexGrow: 1, heigt: "100%" }} >
                                    < ObjectDetails moreParams={props.moreParams} parentObjectDefinition={object} objectDefinition={object.fields[key]} name={key} nav={props.label == "General" ? "" : objectDefinition.route ? objectDefinition.route.name : ""}></ObjectDetails>
                                </Box>
                            )
                        }
                        )
                        }
                        {
                            objectDefinition.components ? objectDefinition.components.filter(component => component.type == "object" && component.placement != "top").map((component, index) => {
                                return (
                                    <Box key={index} sx={{ flex: "0 0 100%", flexGrow: 1 }} >
                                        <component.component objectDefinition={objectDefinition} />
                                    </Box>)
                            })
                                : null
                        }
                    </Box>
                </Box>
            )
            }

        </Box >
    )
}

export default DetailsTabPanel
