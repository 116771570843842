
const definition = {
    "format": "Invoice",
    "type": "array",
    "request": {
        "get": { url: "invoice" }
    },
    "route": {
        "name": "invoice",
        "isMain": true,
        "details": "InvoiceDetails"
    },
    "numberPath": "number",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true
        },
        "accountId": {
            "type": "string",
            "format": "uuid",
            // "column_search": true,
            // "column_filterParameter": "AccountId",
            "column_hide": true
        },
        "orderId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            // "column_search": true,
            // "column_filterParameter": "OrderId",
            "column_hide": true
        },
        "contractId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            // "column_search": true,
            // "column_filterParameter": "ContractId",
            "column_hide": true

        },
        "billRunId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "column_hide": true
        },
        "costCenterId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "column_hide": true
        },
        "accountNumber": {
            "type": "string",
            "nullable": true
        },
        "number": {
            "type": "string",
            "nullable": true,
            "column_search": true,
            "column_filterParameter": "Number"
        },
        "reference": {
            "type": "string",
            "nullable": true,
            "column_hide": true
        },
        "type": {
            "type": "enum",
            "format": "InvoiceType",
        },
        "status": {
            "type": "enum",
            "format": "InvoiceStatus",
            "column_search": true,
            "column_filterParameter": "Status"
        },
        "totalExcludingVat": {
            "type": "number",
            "format": "double",
            "column_hide": true
        },
        "totalIncludingVat": {
            "type": "number",
            "format": "double"
        },
        "totalVat": {
            "type": "number",
            "format": "double",
            "column_hide": true
        },
        "currency": {
            "type": "enum",
            "format": "Currency",
            "column_hide": true
        },
        "invoiceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true
        },
        "collectionDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "column_hide": true
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true
        },
        "creationTimeTo": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_filterParameter": "CreationTimeTo",
            "column_hide": true
        },
        "creationTimeFrom": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_filterParameter": "CreationTimeFrom",
            "column_hide": true
        },
        "modificationTimeFrom": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_hide": true,
            "column_filterParameter": "NodificationTimeFrom"
        },
        "modificationTimeTo": {
            "type": "string",
            "format": "date-time",
            "column_search": true,
            "column_filterParameter": "ModificationTimeTo",
            "column_hide": true
        },
        "rejected": {
            "type": "boolean"
        },
        "rejectionReason": {
            "type": "string",
            "nullable": true,
            "column_hide": true
        },
        "language": {
            "type": "string",
            "nullable": true
        },
        "recipientEmail": {
            "type": "string",
            "nullable": true,
            "column_hide": true
        },
        "recipientName": {
            "type": "string",
            "nullable": true,
            "column_hide": true
        },
        // "billingAddress": {
        //     "type": "object",
        //     "format": "AddressValue",
        //     "hideObject": true
        // },
        "vatNumber": {
            "type": "string",
            "nullable": true,
            "column_hide": true
        },
        "bankAccount": {
            "type": "string",
            "nullable": true,
            "column_hide": true
        },
        "clientReference": {
            "type": "string",
            "nullable": true,
            "column_hide": true
        },
        "comment": {
            "type": "string",
            "nullable": true
        },
        "paymentMethod": {
            "type": "enum",
            "format": "PaymentType",
            "column_hide": true
        },
        "isExported": {
            "type": "boolean",
            "column_hide": true
        }
    }
}
export default definition