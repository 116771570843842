export const toolBar = {
    styleOverrides: {
        root: {
            height: `7vh !important`,
            minHeight: `7vh !important`,
            paddingLeft: '0px !important'
        },
        ".MuiContainer-root": {
            paddingLeft: 0

        },
    }
}