import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from "react-router-dom";
import { selectRoles, selectUsername } from '../../redux/user/selectors';
import { connect, useDispatch } from 'react-redux';
import { signOut } from '../../redux/auth/actions';
import { getData } from '../../redux/data/actions';
import reduxConfig from '../../redux/store';
import TopBar from './components/TopBar';
import Logo from "../../assets/icons/OBG-icon-grey-text";
import CustomDrawer from './components/CustomDrawer';

const pageNames = ['Advisors', 'Customers', 'Invoices', 'Contracts', 'Orders'];
const redirects = ['/advisor', '/customer', '/invoice', "/contract", "/order"];
const settings = ['Sign out'];
const settingsRedirects = ['/signin'];

// Hooks
function NavigationBar(props) {
    const { name, roles } = props
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const routeChange = (string) => {
        if (string == '/signin') {
            reduxConfig.persistor.purge()
            dispatch(signOut())
        }
        navigate(string);
    }

    return (
        <Box>
            <Box display={{ xs: "none", md: "block" }}>
                <TopBar
                    pageNames={pageNames}
                    redirects={redirects}
                    settings={settings}
                    settingsRedirects={settingsRedirects}
                    roles={roles}
                    name={name}
                    routeChange={routeChange}
                ></TopBar>
            </Box >
            <Box display={{ md: "none", xs: "block" }}>
                <AppBar position="static"   >
                    <Toolbar disableGutters display="flex" sx={{ justifyContent: "center", alignItems: "center" }}>
                        <Box height="6vh" display="flex">
                            <Logo />
                        </Box>
                        <CustomDrawer
                            pageNames={pageNames}
                            redirects={redirects}
                            settings={settings}
                            settingsRedirects={settingsRedirects}
                            roles={roles}
                            name={name}
                            routeChange={routeChange} />
                    </Toolbar>
                </AppBar>
            </Box>
        </Box >
    );
};

const mapStateToProps = state => ({
    name: selectUsername(state),
    roles: selectRoles(state)
});

const mapDispatchToProps = dispatch => {
    return {
        getData: (name, url, maxResultCount, skipCount) => dispatch(getData(name, url, maxResultCount, skipCount))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
