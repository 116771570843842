
const definition = {
    "format": "AccountBalance",
    "type": "object",
    "request": {
        "get": { url: "accounts/:AccountId/wallet-balance?currency=EUR" }
    },
    "route": {
        "name": "accountBalance"
    },
    "fields": {
        "accountId": {
            "type": "string",
            "format": "uuid"
        },
        "currency": {
            "type": "string",
            "format": "Currency"
        },
        "balance": {
            "type": "number",
            "format": "double"
        },
        "pendingDeposit": {
            "type": "number",
            "format": "double"
        },
        "pendingWithdraw": {
            "type": "number",
            "format": "double"
        }
    }
}
export default definition