

const definition = {
    "format": "ContractLine",
    "type": "object",
    "route": {
        "name": "/:contractLineId"
    },
    "request": {
        "get": { url: "contracts/:ContractId", route: "lines/:contractLineId" },
    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true
        },
        "contractId": {
            "type": "string",
            "format": "uuid",
            "column_hide": true
        },
        "productId": {
            "type": "string",
            "format": "uuid",
            "column_hide": true
        },
        "productName": {
            "type": "string",
            "nullable": true
        },
        "productNumber": {
            "type": "string",
            "nullable": true
        },
        "quantity": {
            "type": "integer",
            "format": "int32"
        },
        "fromDate": {
            "type": "string",
            "format": "date-time"
        },
        "toDate": {
            "type": "string",
            "format": "date-time"
        },
        "productPriceExcludingVat": {
            "type": "number",
            "format": "double",
            "column_hide": true
        },
        "productPriceIncludingVat": {
            "type": "number",
            "format": "double",
            "column_hide": true
        },
        "subtotalExcludingVat": {
            "type": "number",
            "format": "double",
            "column_hide": true
        },
        "subtotalIncludingVat": {
            "type": "number",
            "format": "double",
            "column_hide": true
        },
        "subtotalVat": {
            "type": "number",
            "format": "double",
            "column_hide": true
        },
        "vatPercentage": {
            "type": "number",
            "format": "double",
            "column_hide": true
        },
        "createdByOrderLineId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "column_hide": true
        }
    }
}
export default definition