// import Contract from "../objects/Contract.js"
const definition = {

    "format": "OrderDetails",
    "type": "object",
    "request": {
        "get": { url: "orders/:OrderId" },
    },
    "route": { name: "/:OrderId" },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "customerAccountId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "column_hide": true
        },
        "sellerAccountId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "number": {
            "type": "string",
            "nullable": true,
            "block": 1
        },
        "reference": {
            "type": "string",
            "nullable": true,
            "field_hide": true
        },
        "status": {
            "type": "enum",
            "format": "OrderStatus",
            "block": 1
        },
        "totalExcludingVat": {
            "type": "number",
            "format": "double",
            "block": 2
        },
        "totalIncludingVat": {
            "type": "number",
            "format": "double",
            "block": 2
        },
        "totalVat": {
            "type": "number",
            "format": "double",
            "block": 2
        },
        "currency": {
            "type": "enum",
            "format": "Currency",
            "block": 2
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "block": 3
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "modifiesContractId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "modificationSwitchDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "invoiceId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "contractPaymentMethod": {
            "type": "enum",
            "format": "PaymentType",
            "block": 2
        },
        "note": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 2
        },
        "billingAddress": {
            "type": "object",
            "format": "AddressValue",
            "tab_name": "billingAddress",
            "object_hide": true,
            "route": { name: "billingAddress" }
        },
        "shippingAddress": {
            "type": "object",
            "format": "AddressValue",
            "tab_name": "shippingAddress",
            "route": { name: "shippingAddress" },
            "object_hide": true
        },
        "lines": {
            "type": "array",
            "format": "OrderLine",
            "items": {
                "type": "object",
                "format": "OrderLine"
            },
            "route": { name: "lines" },
            "nullable": true,
            "object_hide": true
        },
        "createdContracts": {
            "type": "array",
            "format": "Contract",
            "items": {
                "type": "object",
                "format": "ContractDetails"
            },
            "route": { name: "createdContracts" },
            "nullable": true,
            "tab_name": "createdContracts",
            "object_hide": true
        }
    }
}
export default definition