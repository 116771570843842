const definition = {
    "format": "Message",
    "type": "array",
    "request": {
        "get": { url: "messages" }
    },
    "route": {
        "name": "messages",
    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true,
        },
        "accountId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "column_hide": true,
        },
        "recipient": {
            "type": "string",
            "nullable": true
        },
        "dateSend": {
            "type": "string",
            "format": "date-time",
            "nullable": true
        },
        "subject": {
            "type": "string",
            "nullable": true
        },
        "type": {
            "type": "enum",
            "format": "MessageType",
        },
        "state": {
            "type": "enum",
            "format": "MessageState",
        }
    }
}
export default definition