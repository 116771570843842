import { Box, TextField, Typography } from "@mui/material"
// import { useState } from "react";
import { useTranslation } from "react-i18next"
import { signIn } from "../../../redux/auth/actions";
import { connect } from "react-redux"
import { useDispatch } from "react-redux";
import { selectError } from "../../../redux/auth/selectors";
import * as yup from 'yup';
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { authActionTypes } from "../../../redux/actionTypes";
import { checkIfLoading } from "../../../redux/ui/selectors";

function SignInForm(props) {
    // Constants
    const placeholder = {
        value: 'OBG@onebillglobal.com',
        password: 'password',
    }
    const { error, isLoading } = props

    // States 
    // const [rememberMe, setRememberMe] = useState(false);

    // Handlers
    // const handleSetRememberMe = () => setRememberMe(!rememberMe);

    // Validation
    const validationSchema = yup.object({
        value: yup
            .string('')
            .required('Email or ID required'),
        password: yup
            .string('')
            .min(6, 'Password should be of minimum 6 characters length')
            .required('Password is required'),
    });

    // Hooks
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            value: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(signIn(values.value, values.password))
        },
        validateOnChange: false,
        validateOnBlur: false
    });

    return (
        <Box type="form" mt={2} width="100%" onKeyDown={(e) => {
            if (e.key === 'Enter') {
                formik.handleSubmit();
            }
        }}>
            <Box
                mb={3}
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                flexDirection="column"
            >
                <Box mb={1}>
                    <Typography variant="caption">
                        {t('Email/Name')}
                    </Typography>
                </Box>
                <TextField
                    fullWidth
                    size="small"
                    required
                    id="value"
                    name="value"
                    value={formik.values.value}
                    onChange={formik.handleChange}
                    type="text"
                    placeholder={placeholder.value}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.value)}
                    helperText={formik.errors.value}
                    width="100%"
                />
            </Box>
            <Box
                mb={3}
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                flexDirection="column"
            >
                <Box mb={1}>
                    <Typography variant="caption">
                        {t('Password')}
                    </Typography >
                </Box>
                <TextField
                    fullWidth
                    required
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    type="password"
                    onBlur={formik.handleBlur}
                    placeholder={t(placeholder.password)}
                    error={Boolean(formik.errors.password) || error != ""}
                    helperText={formik.errors.password || t(error)}
                    width="100%" />
            </Box>
            {/* <Box mt={3} mb={3} display='flex' justifyContent="flex-end" alignContent="center">
                <FormControlLabel
                    value={rememberMe}
                    control={<Switch size="small" checked={rememberMe} onChange={handleSetRememberMe} />}
                    label={t('Remember_me')}
                    labelPlacement="start"
                />
            </Box> */}
            <Box mt={5} >
                <LoadingButton loading={isLoading} type='submit' onClick={formik.handleSubmit} variant='contained' >
                    {t('sign_in')}
                </LoadingButton>
            </Box>
        </Box >)
}

const mapStateToProps = state => ({
    error: selectError(state),
    isLoading: checkIfLoading(state, authActionTypes.SIGN_IN)
});

export default connect(mapStateToProps, null)(SignInForm)