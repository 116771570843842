import { palette } from '../base/palette'
import { borders } from '../base/borders'
const { borderWidth } = borders

export const autoComplete = {
    styleOverrides: {
        root: {
            paddingRight: 0,

        },
        inputRoot: {
            paddingRight: "5px !important",
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: palette.background.hover,
                borderWidth: borderWidth[1],
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: palette.action.obg,
                borderWidth: borderWidth[2]
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: palette.action.active,
                borderWidth: borderWidth[2]
            }
        }
    },
}