import React from 'react'

class EmptyMatrixNode extends React.Component {
    render() {
        return (
            <div className={"empty"} {...this.props}>
                Empty
            </div>
        )
    }
}

export default EmptyMatrixNode