
const definition = {
    "format": "AccountIdentifier",
    "type": "object",
    "request": {
        "get": { url: "accounts/:AccountId/identifier" }
    },
    "route": {
        "name": "accountIdentifier"
    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true,
            "column_flex": 0.5
        },
        "number": {
            "type": "string",
            "nullable": true,
            "column_flex": 0.5
        },
    }
}
export default definition