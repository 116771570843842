import { Typography } from "@mui/material";
import { Box } from "@mui/system";

function DashBoard() {

    return (
        <Box >
            <Box mb={2}>
                <Typography>
                </Typography>
            </Box>
        </Box>
    )
}

export default DashBoard;
