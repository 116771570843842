import * as yup from 'yup';
import { getObject } from '../objectDefinitionUtils/basicUtils';


export function generateValidationSchema(objectDefinition, returnObject = false) {
    var schema = {}
    Object.keys(objectDefinition.fields).forEach(key => {
        if (objectDefinition.fields[key].validationSchema) {
            schema[key] = objectDefinition.fields[key].validationSchema
        } else {
            if (objectDefinition.fields[key].type == 'object') {
                schema[key] = yup.object().shape(generateValidationSchema(getObject(objectDefinition.fields[key].format), true))
            } else {
                schema[key] = yup.string().nullable()
            }
        }
    })
    if (returnObject) {
        return schema
    } else {
        const validationSchema = yup.object(schema);
        return validationSchema
    }

}