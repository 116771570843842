import { Box } from "@mui/system";
import { getEnum } from "../../../utils/objectDefinitionUtils/basicUtils";
import { Autocomplete, Checkbox, FormControlLabel, MenuItem, TextField, Typography } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "../../Components/wrappers/withRouter";
import { selectRoles } from "../../../redux/user/selectors";
import { getUpdatingItems } from "../../../redux/ui/selectors";
import { dataActionTypes } from "../../../redux/actionTypes";
import { selectData, selectError } from "../../../redux/data/selectors";
import get from 'lodash/get';
import { JsonToURLParameters } from "../../../utils/formatters/urlFormatters";
import { getData } from "../../../redux/data/actions";
import debounce from 'lodash.debounce';
import { useEffect, useState } from "react";

function DetailsModalObject({ object, formik, data, ...props }) {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState({ state: false, url: null });

    useEffect(() => {
        if (loading.state) {
            if (!props.loadingItems.some(item => item.type == loading.url)) {
                const data = props.selectData(loading.url)
                if (data) {
                    setOptions([...data])
                    setLoading(false)
                } else {
                    props.getData(loading.url)
                }
            }
        }
    }, [loading, props.loadingItems, formik]);


    var maxBlocks = object ? Math.max(...Object.keys(object.fields).map(key => object.fields[key].block ? object.fields[key].block : 1)) : 1

    const handleSearch = (e, newValue, fieldDef) => {
        if (newValue) {
            formik.setFieldValue(fieldDef.field, newValue.id)
        }
        else {
            formik.handleChange(e);
            if (e.target.value) {
                const url = JsonToURLParameters({ searchContent: e.target.value }, fieldDef.searchable.request.get.url)
                setLoading({ state: true, url: url })
            }
        }
    }

    var debouncedChangeHandler = debounce(handleSearch, 250);
    return (
        <Box mx={4}>
            {
                object &&

                <Box>

                    <Box
                        mt={3}
                        mb={3}

                        display="flex"
                        alignItems="flex-start"
                        justifyContent="center"
                        flexDirection="column"
                        id="modal-modal-description"
                        width="100%"
                        position="relative"
                        flexWrap='wrap'
                    >
                        {[...Array(maxBlocks)].map((e, block) => {
                            const fieldsInBlock = {}
                            Object.keys(object.fields).filter(key => object.fields[key].block === block + 1 || (block == 0 && !object.fields[key].block ? true : false)).map(key => fieldsInBlock[key] = object.fields[key])
                            var maxLines = Math.max(...Object.keys(fieldsInBlock).map(key => fieldsInBlock[key].line ? fieldsInBlock[key].line : 1))
                            return (<Box key={block}>
                                {[...Array(maxLines)].map((e, line) => {
                                    const fieldsInLine = {}
                                    Object.keys(fieldsInBlock).filter(key => (fieldsInBlock[key].line === line + 1 || (line == 0 && !fieldsInBlock[key].line ? true : false)) && (fieldsInBlock[key].hideIfEmpty ? (fieldsInBlock[key].hideIfEmpty == true && (data ? data[key] : false)) : true)).map(key => fieldsInLine[key] = object.fields[key])
                                    return (
                                        <Box key={line} display="flex" flexDirection="row" flexWrap='wrap'
                                        >
                                            {
                                                Object.keys(fieldsInLine).map(fieldDefKey => {
                                                    const fieldDef = fieldsInLine[fieldDefKey]
                                                    return (<Box
                                                        mb={1}
                                                        display="flex"
                                                        alignItems="flex-start"
                                                        justifyContent="center"
                                                        flexDirection="column"
                                                        px={2} py={1} minWidth="200px"
                                                        key={fieldDefKey}
                                                    >
                                                        <Box mb={1}>
                                                            <Typography variant="caption">
                                                                {fieldDefKey}
                                                            </Typography >
                                                        </Box>
                                                        {['string', 'integer', 'number'].includes(fieldDef.type) ?
                                                            fieldDef.searchable ?
                                                                <Box width="100%" minWidth={"400px"}>
                                                                    <Autocomplete
                                                                        freeSolo
                                                                        disableClearable
                                                                        options={options}
                                                                        filterOptions={x => x}
                                                                        getOptionLabel={option => option.id ? option.id : option}
                                                                        loading={loading.state}
                                                                        onChange={
                                                                            (e, value) =>
                                                                                debouncedChangeHandler(e, value, fieldDef)
                                                                        }
                                                                        // error={Boolean(fieldDef.field ? (get(formik.errors, fieldDef.field) != null ? get(formik.errors, fieldDef.field) : "") : formik.errors[fieldDefKey])}
                                                                        // helperText={fieldDef.field ? (get(formik.errors, fieldDef.field) != null ? get(formik.errors, fieldDef.field) : "") : formik.errors[fieldDefKey]}
                                                                        value={fieldDef.field ? (get(formik.values, fieldDef.field) != null ? get(formik.values, fieldDef.field) : "") : formik.values[fieldDefKey]}
                                                                        id={fieldDefKey}
                                                                        name={fieldDef.field ? fieldDef.field : fieldDefKey}
                                                                        renderOption={(props, option) => {
                                                                            return (
                                                                                <Box component="li" {...props}>
                                                                                    {option.account.number} ({option.account.person.firstname} {option.account.person.lastname})
                                                                                </Box>
                                                                            )
                                                                        }}
                                                                        renderInput={(params) => {
                                                                            return (
                                                                                <TextField
                                                                                    {...params}
                                                                                    fullWidth
                                                                                    required
                                                                                    id={fieldDefKey}
                                                                                    name={fieldDef.field ? fieldDef.field : fieldDefKey}
                                                                                    value={fieldDef.field ? (get(formik.values, fieldDef.field) != null ? get(formik.values, fieldDef.field) : "") : formik.values[fieldDefKey]}
                                                                                    // onChange={formik.handleChange}
                                                                                    onChange={
                                                                                        (e, value) =>
                                                                                            debouncedChangeHandler(e, value, fieldDef)
                                                                                    }
                                                                                    type={object.fields[fieldDefKey].format == "date-time" ? "date" : object.fields[fieldDefKey].type}
                                                                                    onBlur={formik.handleBlur}
                                                                                    error={Boolean(fieldDef.field ? formik.errors[fieldDef.field] : formik.errors[fieldDefKey])}
                                                                                    helperText={fieldDef.field ? formik.errors[fieldDef.field] : formik.errors[fieldDefKey]}
                                                                                    width="100%"
                                                                                />
                                                                            )
                                                                        }}></Autocomplete>                                                        </Box>
                                                                :
                                                                <TextField
                                                                    fullWidth
                                                                    required
                                                                    id={fieldDefKey}
                                                                    name={fieldDef.field ? fieldDef.field : fieldDefKey}
                                                                    value={fieldDef.field ? (get(formik.values, fieldDef.field) != null ? get(formik.values, fieldDef.field) : "") : formik.values[fieldDefKey]}
                                                                    onChange={formik.handleChange}
                                                                    type={object.fields[fieldDefKey].format == "date-time" ? "date" : object.fields[fieldDefKey].type}
                                                                    onBlur={formik.handleBlur}
                                                                    // placeholder={t(placeholder.password)}
                                                                    error={Boolean(fieldDef.field ? (get(formik.errors, fieldDef.field) != null ? get(formik.errors, fieldDef.field) : "") : formik.errors[fieldDefKey])}
                                                                    helperText={fieldDef.field ? (get(formik.errors, fieldDef.field) != null ? get(formik.errors, fieldDef.field) : "") : formik.errors[fieldDefKey]}
                                                                    width="100%"
                                                                /> : null}
                                                        {fieldDef.type == 'boolean' ?
                                                            <FormControlLabel
                                                                name={fieldDef.field ? fieldDef.field : fieldDefKey}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                error={fieldDef.field ? formik.errors[fieldDef.field] : formik.errors[fieldDefKey]}
                                                                id={fieldDefKey}
                                                                control={<Checkbox name={fieldDef.field}
                                                                    id={fieldDefKey}
                                                                    style={{
                                                                        transform: "scale(0.75)", transformOrigin: "top left", marginLeft: 5
                                                                    }} checked={fieldDef.field ? (get(formik.values, fieldDef.field) != null ? get(formik.values, fieldDef.field) : "") : formik.values[fieldDefKey]} />}
                                                            /> : null}
                                                        {fieldDef.type == "enum" ?
                                                            <TextField
                                                                name={fieldDef.field ? fieldDef.field : fieldDefKey}
                                                                id={fieldDef.field ? fieldDef.field : fieldDefKey}
                                                                select
                                                                value={fieldDef.field ? (get(formik.values, fieldDef.field) != null ? get(formik.values, fieldDef.field) : "") : formik.values[fieldDefKey]}
                                                                onChange={

                                                                    formik.handleChange
                                                                }
                                                                fullWidth
                                                            >
                                                                {getEnum(fieldDef.format).array.map((el, index) => { return <MenuItem key={index} value={el}>{el}</MenuItem> })}
                                                            </TextField>
                                                            : null}
                                                    </Box>)
                                                })}</Box>)
                                })}</Box>)
                        })}

                    </Box>
                </Box>
            }
        </Box >
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (url) => dispatch(getData(url, url, 100, 0, null, null)),
    }
}

const mapStateToProps = (state) => {
    // const objectDefinition = props.objectDefinition.request?.get?.url ? props.objectDefinition : props.parentObjectDefinition
    // const urlParams = Object.assign(props.parentObjectDefinition.request ? { ...selectDetailsInner(JsonToURLParameters(props.params, props.parentObjectDefinition.request.get.url), state, props.parentObjectDefinition.request.get.route) } : {}, { ...props.params })

    return ({
        selectData: (url) => selectData(url, state),
        loadingItems: getUpdatingItems(state, dataActionTypes.GET_DATA),
        roles: selectRoles(state),
        error: selectError(state)
    })
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsModalObject))
