
const definition = {
    "format": "TeamNode",
    "request": {
        "get": { url: "teams/:AccountId/matrix-team?levels=2" },
    },
    "fields": {
        "type": {
            "type": "enum",
            "format": "TeamType",
        },
        "parentId": {
            "type": "string",
            "format": "uuid",
            "nullable": true
        },
        "children": {
            "type": "array",
            "items": {
                "type": "object",
                "format": "TeamNode",
            },
            "nullable": true
        },
        "numberOfChildren": {
            "type": "integer",
            "format": "int32",
            "nullable": true
        },
        "teamSize": {
            "type": "integer",
            "format": "int32",
            "nullable": true
        },
        "showAdvisorName": {
            "type": "boolean"
        },
        "advisorId": {
            "type": "string",
            "format": "uuid"
        },
        "advisorSponsorId": {
            "type": "string",
            "format": "uuid",
            "nullable": true
        },
        "advisorStatus": {
            "type": "string",
            "nullable": true
        },
        "advisorAccountName": {
            "type": "string",
            "nullable": true
        },
        "advisorFirstname": {
            "type": "string",
            "nullable": true
        },
        "advisorInfix": {
            "type": "string",
            "nullable": true
        },
        "advisorLastname": {
            "type": "string",
            "nullable": true
        },
        "isActive": {
            "type": "boolean"
        },
        "level": {
            "type": "integer",
            "format": "int32"
        },
        "position": {
            "type": "integer",
            "format": "int32"
        },
        "stars": {
            "type": "number",
            "format": "double"
        },
        "teamStars": {
            "type": "number",
            "format": "double"
        },
        "pendingStars": {
            "type": "number",
            "format": "double"
        },
        "pendingTeamStars": {
            "type": "number",
            "format": "double"
        },
        "salesRankName": {
            "type": "string",
            "nullable": true
        },
        "salesRankLevel": {
            "type": "integer",
            "format": "int32"
        },
        "salesQualificationLevels": {
            "type": "integer",
            "format": "int32"
        },
        "networkRankName": {
            "type": "string",
            "nullable": true
        },
        "networkRankLevel": {
            "type": "integer",
            "format": "int32"
        },
        "networkQualificationLevels": {
            "type": "integer",
            "format": "int32"
        }
    }
}


export default definition