import { AppBar, Avatar, Button, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react";
import { useTranslation } from "react-i18next";
import { palette } from "../../../common/theme/base/palette"
import SearchBox from "./SearchBox";
import Logo from "../../../assets/icons/OBG-icon";

function TopBar({ pageNames, redirects, settings, settingsRedirects, roles, name, routeChange }) {
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (index) => {
        setAnchorElUser(null);
        routeChange(settingsRedirects[index]);
    };

    const { t } = useTranslation()

    return (<AppBar position="static"   >
        <Toolbar disableGutters display="flex">
            <Box width="10vw" height="100%" >
                <Box height="100%" width="100%" bgcolor="background.obg" sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: 'column'
                }}>
                    <Box width="75%">
                        <Logo></Logo>
                    </Box>
                </Box>
            </Box>
            <Box flexGrow={5} display="flex" justifyContent="left" flexDirection="row">
                {pageNames.map((page, index) => (
                    <Button
                        className={location.pathname == redirects[index] ? "active" : ""}
                        variant="menu"
                        key={page}
                        onClick={() => routeChange(redirects[index])}
                        sx={{ my: 2, color: 'background.obg' }}
                    >
                        {page}
                    </Button>
                ))}
            </Box>
            <Box flexGrow={3}>
                <SearchBox />
            </Box>
            <Box sx={{ flexGrow: 1 }} display="flex" justifyContent="flex-end" mr={3}>
                <Box display="flex" flexDirection="column" alignItems="flex-end" mr={2}>
                    <Typography variant="body1" fontWeight='bold'>
                        {name}
                    </Typography>
                    <Typography variant="body3" color={palette.action.active}>
                        {roles && Array.isArray(roles) ? roles.map((item, index) => (index > 0 ? ", " : "") + item) : roles}
                    </Typography>
                </Box>
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar sx={{ backgroundColor: palette.background.obg }}>{name.slice(0, 1)}</ Avatar >
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '5vh' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    {settings.map((setting, index) => (
                        <MenuItem key={setting} onClick={() => handleCloseUserMenu(index)}>
                            <Typography textAlign="center">{t(setting)}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        </Toolbar>
    </AppBar >)
}

export default TopBar
