const definition = {
    "format": "OrderLine",
    "type": "object",
    "route": {
        "name": "/:orderLineId"
    },
    "request": {
        "get": { url: "orders/:OrderId", route: "lines/:orderLineId" },
    },
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "column_hide": true
        },
        "orderId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "column_hide": true
        },
        "productId": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "column_hide": true
        },
        "productName": {
            "type": "string",
            "nullable": true
        },
        "productNumber": {
            "type": "string",
            "nullable": true
        },
        "unitPriceExcludingVat": {
            "type": "number",
            "format": "double",
            "block": 3,
            "line": 2,
            "column_hide": true
        },
        "unitPriceIncludingVat": {
            "type": "number",
            "format": "double",
            "block": 3,
            "line": 2
        },
        "unitDescription": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 1
        },
        "quantity": {
            "type": "integer",
            "format": "int32",
            "block": 2
        },
        "subtotalExcludingVat": {
            "type": "number",
            "format": "double",
            "block": 3,
            "line": 2,
            "column_hide": true
        },
        "subtotalIncludingVat": {
            "type": "number",
            "format": "double",
            "block": 3,
            "line": 2
        },
        "subtotalVat": {
            "type": "number",
            "format": "double",
            "block": 3,
            "line": 1,
            "column_hide": true
        },
        "vatPercentage": {
            "type": "number",
            "format": "double",
            "block": 3,
            "line": 1,
            "column_hide": true
        },
        "modifiesContractLineId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true
        },
        "decorationTypeId": {
            "type": "string",
            "nullable": true,
            "field_hide": true,
            "column_hide": true
        },
        "decoration": {
            "nullable": true,
            "field_hide": true
        },
        "createdContractLineId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true,
            "column_hide": true
        }
    }
}


export default definition