import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from 'react-i18next'
import Background from "../Components/layouts/Background";
import SignInForm from "./components/SignInForm";
import Footer from "./components/Footer";
import { selectIsLoggedIn } from "../../redux/auth/selectors";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { authActionTypes } from "../../redux/actionTypes";
import { checkIfLoading } from "../../redux/ui/selectors";
import SvgLogo from "../../assets/icons/OBG-icon-grey-text";
import Logo from "../../assets/icons/OBG-icon";


function SignIn(props) {
    //Constants
    const { isLoggedIn, isLoading } = props;

    //Hooks
    const { t } = useTranslation();

    return (isLoggedIn && !isLoading ? <Navigate to="/dashboard" /> :
        <Background backgroundColor="light">
            <Grid container sx={{ height: "90vh", width: "100vw", flex: 11 }}>
                <Grid md={5} item >
                    <Box
                        height="100%"
                        bgcolor="background.obg"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: 'column'
                        }}>
                        <Box width="60%">
                            <Logo > </Logo>
                        </Box>

                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={7}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                    }}
                >
                    <Box px={6} >
                        <Box flex={1} mb={5} display={{ md: "none" }} >
                            <SvgLogo />
                        </Box>
                        <Box flex={1} >
                            <Typography variant="h3" textAlign="left" >
                                {t('sign_in_title')}
                            </Typography>
                            <Typography variant="subtitle2" textAlign="left">
                                {t('sign_in_subtitle')}
                            </Typography>
                            <SignInForm />
                        </Box>
                    </Box>

                </Grid>
            </Grid>
            <Box sx={{ height: "10vh", width: "100vw" }}>
                <Footer />
            </Box>
        </Background >)
}

const mapStateToProps = (state) => ({
    isLoggedIn: selectIsLoggedIn(state),
    isLoading: checkIfLoading(state, authActionTypes.SIGN_IN),
});


export default connect(mapStateToProps, null)(SignIn);
