
const definition = {
    "format": "Advisor",
    "type": "array",
    "request": {
        "get": { url: "advisor" }
    },
    "route": {
        "name": "advisor",
        "isMain": true,
        "details": "AdvisorDetails"
    },
    "numberPath": "account.number",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true
        },
        "status": {
            "type": "enum",
            "format": "AdvisorStatus",
            "columnFlex": 0.5,
            "column_search": true,
            "column_filterParameter": "Status"
        },
        "sponsor": {
            "type": "string",
            "column_hide": true,
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "column_hide": true
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "column_hide": true,
            "columnDefaultSearch": true,
            "FilterParameter": "Name"
        },
        "account": {
            "type": "object",
            "format": "Account",
            "tab_hide": true
        },
        "alias": {
            "type": "string",
            "column_search": true,
            "column_filterParameter": "Alias",
            "column_hide": true,
        },
        "aliasStrict": {
            "type": "string",
            "column_search": true,
            "column_filterParameter": "AliasStrict",
            "column_hide": true,
        },
    }
}
export default definition