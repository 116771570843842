import { Box } from "@mui/system";
import { getObject } from "../../../utils/objectDefinitionUtils/basicUtils";
import { Button, Divider, IconButton, Modal, Typography } from "@mui/material";
import Close from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { deleteData } from "../../../redux/data/actions";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "../../Components/wrappers/withRouter";
import { JsonToURLParameters } from "../../../utils/formatters/urlFormatters";
import { useEffect, useState } from "react";
import { selectRoles } from "../../../redux/user/selectors";
import { getUpdatingItems } from "../../../redux/ui/selectors";
import { dataActionTypes } from "../../../redux/actionTypes";
import { selectError } from "../../../redux/data/selectors";
import { palette } from "../../../common/theme/base/palette";
import { useNavigate } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    minWidth: "500px",
};
function ConfirmModal({ objectDefinition, editType, open, title, onClose, ...props }) {
    const [isLoading, setIsLoading] = useState(false)

    //Constants
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (isLoading == true && !props.loadingItems.some(item => item.type == JsonToURLParameters(props.params, objectDefinition.request.delete.url))) {
            setIsLoading(false)

            if (!props.error || props.error == "") {
                onClose()
                if (objectDefinition.request.delete.redirect) {
                    navigate(JsonToURLParameters(props.params, objectDefinition.request.delete.redirect), { replace: true })
                }
            }

        }
    }, [props.loadingItems])



    const onConfirm = () => {
        if (editType == "Delete") {
            setIsLoading(true)
            dispatch(deleteData(JsonToURLParameters(props.params, objectDefinition.request.delete.url), JsonToURLParameters(props.params, getObject(objectDefinition.request.delete.response).request.get.url)))
        } else if (editType == "Edit") {
            // dispatch(postData(objectDefinition.request.put.url, values))
        }

    }



    return (
        <Box>
            {

                <Modal open={open}
                    onClose={onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={style}>
                        <Box display="flex" justifyContent="left" alignItems="center" mb={2}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {title}
                            </Typography>
                            <Box position="absolute" right={20} >
                                <IconButton onClick={() => onClose()} ><Close color="primary" /></IconButton>
                            </Box>
                        </Box>
                        <Divider></Divider>
                        <Box
                            mt={3}
                            mb={3}
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="center"
                            flexDirection="column"
                            id="modal-modal-description"
                            width="100%"

                        >
                            <Typography>Are you sure you want to delete the {objectDefinition.format}? </Typography>
                        </Box>
                        <Box mt={5} display="flex" justifyContent="center">
                            <LoadingButton
                                loading={isLoading}
                                onClick={onConfirm}
                                style={{ backgroundColor: palette.error.main, marginRight: 10 }}

                                variant='contained' >
                                {"Delete"}

                            </LoadingButton>
                            <Button
                                style={{ backgroundColor: palette.text.active, marginLeft: 10 }}
                                onClick={() => onClose()}
                                variant='contained' >

                                {"Cancel"}
                            </Button>
                        </Box>
                    </Box>
                </Modal >
            }
        </Box >
    )
}
const mapStateToProps = (state) => {
    // const objectDefinition = props.objectDefinition.request?.get?.url ? props.objectDefinition : props.parentObjectDefinition
    // const urlParams = Object.assign(props.parentObjectDefinition.request ? { ...selectDetailsInner(JsonToURLParameters(props.params, props.parentObjectDefinition.request.get.url), state, props.parentObjectDefinition.request.get.route) } : {}, { ...props.params })

    return ({
        loadingItems: getUpdatingItems(state, dataActionTypes.DELETE_DATA),
        roles: selectRoles(state),
        error: selectError(state)
    })
};
export default withRouter(connect(mapStateToProps, null)(ConfirmModal))
