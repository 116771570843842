import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const DETECTION_OPTIONS = {
    order: ['navigator']
};

i18n.use(initReactI18next).use(LanguageDetector).init({
    fallbackLng: 'en',
    detection: DETECTION_OPTIONS,
    resources: {
        en: {
            translations: require('./locales/en/translations.json')
        },
        nl: {
            translations: require('./locales/nl/translations.json')
        }

        // fr: {
        //     translations: require('./locales/fr/translations.json')
        // }
    },
    ns: ['translations'],
    defaultNS: 'translations'
});

i18n.languages = ['en', 'nl'];

export default i18n;