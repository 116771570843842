import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
    dataActionTypes,
} from '../actionTypes';
import {
    getData, getDetails, postData, deleteData, putData
} from './service';
import {
    startAction,
    stopAction,
    refreshActionStart,
    refreshActionStop
} from '../ui/actions';
import { deleteDataFailed, deleteDataSucces, getDataSucces, getDetailsFailed, getDetailsSuccess, postDataFailed, postDataSucces, putDataFailed, putDataSucces } from './actions';

export function* getDataSaga({ type, payload }) {
    try {

        //Default check for refresh + add to UI loading state
        const { refreshing } = payload;
        yield put(refreshing ? refreshActionStart(type) : startAction(type, { type: payload.type, maxResultCount: payload.maxResultCount, skipCount: payload.skipCount, sorting: payload.sorting, filters: payload.filters }));

        //signing in
        const response = yield call(getData, payload);
        yield put(getDataSucces(payload.type, response.data, payload.maxResultCount, payload.skipCount));

    } catch (error) {

        console.log('error getting data ', error);

    } finally {
        //Stop UI Loading state
        yield put(payload.refreshing ? refreshActionStop(type) : stopAction(type, { type: payload.type, maxResultCount: payload.maxResultCount, skipCount: payload.skipCount, sorting: payload.sorting, filters: payload.filters }));
    }
}

export function* watchGetDataSaga() {
    yield takeEvery(dataActionTypes.GET_DATA, getDataSaga);
}

export function* getDetailsSaga({ type, payload }) {
    try {

        //Default check for refresh + add to UI loading state
        yield put(payload.refreshing ? refreshActionStart(type) : startAction(type, { type: payload.type }));

        //signing in
        const response = yield call(getDetails, payload);
        yield put(getDetailsSuccess(payload.type, response.data));

    } catch (error) {

        yield put(getDetailsFailed(error.message));

    } finally {

        //Stop UI Loading state
        yield put(payload.refreshing ? refreshActionStop(type) : stopAction(type, { type: payload.type }));
    }
}

export function* watchGetDetailsSaga() {
    yield takeEvery(dataActionTypes.GET_DETAILS, getDetailsSaga);
}


export function* postDataSaga({ type, payload }) {
    try {

        //Default check for refresh + add to UI loading state
        yield put(payload.refreshing ? refreshActionStart(type) : startAction(type, { type: payload.url }));
        //signing in
        const response = yield call(postData, payload);

        yield put(postDataSucces(type, response.data, payload.response));

    } catch (error) {

        yield put(postDataFailed(error ? error.message : "error"));

    } finally {

        //Stop UI Loading state
        yield put(payload.refreshing ? refreshActionStop(type) : stopAction(type, { type: payload.url }));
    }
}

export function* watchPostDataSaga() {
    yield takeLeading(dataActionTypes.POST_DATA, postDataSaga);
}

export function* deleteDataSaga({ type, payload }) {
    try {

        //Default check for refresh + add to UI loading state
        yield put(payload.refreshing ? refreshActionStart(type) : startAction(type, { type: payload.url }));
        //signing in
        const response = yield call(deleteData, payload);

        yield put(deleteDataSucces(type, response.data, payload.response));

    } catch (error) {

        yield put(deleteDataFailed(error ? error.message : "error"));

    } finally {

        //Stop UI Loading state
        yield put(payload.refreshing ? refreshActionStop(type) : stopAction(type, { type: payload.url }));
    }
}

export function* watchDeleteDataSaga() {
    yield takeLeading(dataActionTypes.DELETE_DATA, deleteDataSaga);
}

export function* putDataSaga({ type, payload }) {
    try {

        //Default check for refresh + add to UI loading state
        yield put(payload.refreshing ? refreshActionStart(type) : startAction(type, { type: payload.url }));
        //signing in
        const response = yield call(putData, payload);

        yield put(putDataSucces(type, response.data, payload.response));

    } catch (error) {

        yield put(putDataFailed(error ? error.message : "error"));

    } finally {

        //Stop UI Loading state
        yield put(payload.refreshing ? refreshActionStop(type) : stopAction(type, { type: payload.url }));
    }
}

export function* watchPutDataSaga() {
    yield takeLeading(dataActionTypes.PUT_DATA, putDataSaga);
}