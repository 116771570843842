import React from 'react';
import ReactDOM from 'react-dom';
import { PanZoom } from 'react-easy-panzoom';
import * as _ from 'lodash';
import './styles.css';
import MatrixNode from './MatrixNode';
import EmptyMatrixNode from './EmptyMatrixNode';

export default class TreeView extends React.Component {
    constructor(props) {
        super(props)
        this.tree = [...props.tree]
        this.nodeView = props.nodeView
        this.state = {
            ...TreeView.createColumnsData(this.tree),
        }
    }

    componentDidMount() {
        if (this.props.overlay == "Default")
            this.drawCurves()

    }

    componentDidUpdate() {
        document
            .querySelectorAll(`div[class*="svg_container"]`)
            .forEach((e) => e.remove())
        if (this.props.overlay == "Default")
            this.drawCurves()
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            ...TreeView.createColumnsData(props.tree)
        }
    }

    static createColumnsData = (tree) => {
        const columns = []
        const nodeReferencies = []
        const newTree = []
        Object.assign(newTree, tree)

        const treeWalker = (treeNode, column = 0) => {
            if (!columns[column]) {
                columns.push([])
                nodeReferencies.push([])
            }

            newTree.filter(item => parseInt(item.node.split(":")[0]) == column).forEach(node => {
                columns[column].push(node)
                nodeReferencies[column].push({
                    parent_node: node.parent_node,
                    node: node
                })
            })
            if (newTree.some(item => item.node.slice(0, 1) == (column + 1))) {
                treeWalker(treeNode, column + 1)
            }
        }
        treeWalker(newTree)
        return {
            columns,
            nodeReferencies
        }
    }


    drawCurves = () => {
        // Here we remove all existing links in the DOM before the new render of the modified tree
        document
            .querySelectorAll(`div[class*="svg_container"]`)
            .forEach((e) => e.remove())
        // the pipelineContainer contains a root element to display the entire tree
        const pipelineContainer = document.getElementById('tree_wrapper')
        const { columns, nodeReferencies } = this.state
        columns.forEach((column, id) => {
            column.forEach((cell, index) => {
                // Exclude execution for the last layer in the structure
                if (id !== columns.length - 1) {
                    // eslint-disable-next-line react/no-find-dom-node
                    const cardElement = ReactDOM.findDOMNode(
                        nodeReferencies[id][index].element
                    )

                    const childrenElements = []
                    columns[id + 1].map((child, c) => {
                        if (child && child.parent_node === cell.node) {
                            childrenElements.push(nodeReferencies[id + 1][c].element)
                        }
                    })
                    // Here we will store the coordinates of the middle of the left side of the child
                    const coords = []
                    if (childrenElements.length) {
                        childrenElements.forEach((ce) => {
                            if (ce) {
                                // eslint-disable-next-line react/no-find-dom-node
                                const domNode = ReactDOM.findDOMNode(ce)

                                if (domNode) {
                                    coords.push({
                                        x: domNode.offsetLeft + domNode.offsetWidth / 2,
                                    })
                                }
                            }
                        })
                        if (cardElement) {
                            const y = cardElement.offsetHeight + cardElement.offsetTop
                            // Get the midpoint of the parent's right edge
                            const center =
                                cardElement.offsetWidth / 2 + cardElement.offsetLeft
                            if (y !== 0 && !cardElement.classList.contains('add_card')) {
                                // Draw a curve
                                const svgContainer = document.createElement('div')
                                svgContainer.setAttribute('class', "svg_container")
                                const circle = document.createElementNS(
                                    'http://www.w3.org/2000/svg',
                                    'svg'
                                )
                                const checkPoints = coords
                                    .map((coord) => coord.x)
                                    .concat(center)
                                const width =
                                    _.max(checkPoints) - _.min(checkPoints) >= 12
                                        ? _.max(checkPoints) - _.min(checkPoints)
                                        : 12
                                circle.setAttribute('width', width)
                                circle.setAttribute('height', 160)
                                circle.setAttribute('viewBox', `0 0 ${width} 160`)
                                circle.setAttribute('version', '1.1')
                                circle.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
                                let sourcePositionX = null
                                if (Math.abs(_.min(checkPoints) - center) === width) {
                                    sourcePositionX = width - 6
                                } else if (Math.abs(_.min(checkPoints) - center) < 6) {
                                    sourcePositionX = 6
                                } else {
                                    sourcePositionX = Math.abs(_.min(checkPoints) - center)
                                }
                                const lines = coords.map((coord) => {
                                    const besie = `<path d="M  ${sourcePositionX} ,6 C ${sourcePositionX},80, ${Math.abs(
                                        coord.x - _.min(checkPoints)
                                    )},80,${Math.abs(
                                        coord.x - _.min(checkPoints)
                                    )}, 160" stroke="#C4C4C4" fill="none"/>`
                                    return besie
                                })
                                circle.innerHTML = `
                                  ${lines.join('\n')}
                                  ${lines.length
                                        ? `<circle cy="6" cx=${sourcePositionX} r="4" fill="#C4C4C4"/>`
                                        : null
                                    }
                                `
                                svgContainer.style.left = _.min(checkPoints) + 'px'
                                svgContainer.style.top = y + 'px'
                                svgContainer.appendChild(circle)
                                pipelineContainer.appendChild(svgContainer)
                            }
                        }
                    }
                }
            })
        })
    }

    pickHex = (color1, color2, weight) => {
        var p = weight;
        var w = p * 2 - 1;
        var w1 = (w / 1 + 1) / 2;
        var w2 = 1 - w1;
        var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)];
        return rgb;
    }



    render() {
        var gradient = [
            [
                0,
                [216, 99, 75]
            ],
            [
                50,
                [255, 176, 32]
            ],
            [
                100,
                [192, 216, 75]
            ]
        ];

        const calculateBackgroundColor = (id) => {
            const highestStar = Math.max(...this.tree.map(item => item.stars))
            const node = this.tree.find(item => item.node == id)

            var colorRange = []
            //Get the two closest colors
            if (node.stars != 0 && node && highestStar / node.stars > 0.5) {
                colorRange = [1, 2]
            } else {
                colorRange = [0, 1]
            }
            var firstcolor = gradient[colorRange[0]][1];
            var secondcolor = gradient[colorRange[1]][1];

            //Calculate ratio between the two closest colors
            var ratio = 0
            if (node.stars != 0) {
                var firstcolor_x = highestStar * (gradient[colorRange[0]][0] / 100);
                var secondcolor_x = highestStar * (gradient[colorRange[1]][0] / 100) - firstcolor_x;
                var slider_x = highestStar * (node.stars / 100) - firstcolor_x;
                ratio = slider_x / secondcolor_x
            }

            var p = ratio;
            var w = p * 2 - 1;
            var w1 = (w / 1 + 1) / 2;
            var w2 = 1 - w1;
            var rgb = [Math.round(secondcolor[0] * w1 + firstcolor[0] * w2),
            Math.round(secondcolor[1] * w1 + firstcolor[1] * w2),
            Math.round(secondcolor[2] * w1 + firstcolor[2] * w2)];
            return 'rgb(' + rgb.join() + ')'
        }

        const {
            // CSS classes for styling vertices
            nodeViewClasses,
            // Empty Vertex Component Properties
            emptyNodeProps,
            // Trigger for displaying empty vertices
            overlay,
            ...props
        } = this.props
        const { nodeReferencies, columns } = this.state

        return (
            <PanZoom  {...props}>
                <div width="100%" className={"tree"} id='tree_wrapper'>
                    {columns.map((col, i) => {
                        return col.length ? (
                            <div key={`id_${i}`} className={"tree__column"} style={{ margin: overlay == "Default" ? "80px 0px" : "0px 0px" }}>
                                {col.map((item, idx) => {
                                    const backgroundColor = calculateBackgroundColor(`${i}:${idx}`)
                                    return Object.keys(item).length > 3 ?
                                        (
                                            <MatrixNode
                                                ref={(el) => (nodeReferencies[i][idx].element = el)}
                                                key={`node_${i}${idx}`}
                                                id={`card_${i}${idx}`}
                                                {...item}
                                                styles={props.nodeviewclasses}
                                                backgroundColor={backgroundColor}
                                                overlay={overlay}
                                            />
                                        ) : <EmptyMatrixNode
                                            ref={(el) => (nodeReferencies[i][idx].element = el)}
                                            key={`node_${i}${idx}`}
                                            styles={nodeViewClasses}
                                            data={{
                                                ...nodeReferencies[i][idx],
                                                newTree: this.tree
                                            }}
                                            {...emptyNodeProps}
                                        />
                                })}
                            </div>
                        ) : null
                    })}
                </div>
            </PanZoom>
        )


    }
}
