import * as yup from 'yup';
import "../../../utils/generators/yupExtenstions"

const definition = {
    "format": "AddressCreate",
    "fields": {
        "line1": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 1,
            "columnFlex": 1,
            "validationSchema": yup.string('').nullable(''),

        },
        "line2": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 1,
            "column_hide": true,
            "validationSchema": yup.string('').nullable(''),
        },
        "line3": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 1,
            "column_hide": true,
            "validationSchema": yup.string('').nullable(''),
        },
        "line4": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 2,
            "column_hide": true,
            "validationSchema": yup.string('').nullable(''),
        },
        "number": {
            "type": "number",
            "format": "int32",
            "nullable": true,
            "block": 2,
            "line": 3,
            "column_hide": true
            , "validationSchema": yup.number('').integer("Not an integer").nullable(),
            "columnFlex": 0.5,

        },
        "unit": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 3,
            "column_hide": true,
            "columnFlex": 0.5,
            "validationSchema": yup.string('').nullable(''),
        },
        "postalCode": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 4,
            "columnFlex": 0.5,
            "validationSchema": yup.string('').nullable(''),
        },
        "city": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 4,
            "columnFlex": 1,
            "validationSchema": yup.string('').nullable(''),
        },
        "state": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 5,
            "columnFlex": 1,
            "validationSchema": yup.string('').nullable(''),
        },
        "countryCode": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 5,
            "columnFlex": 0.3,
            "validationSchema": yup.string('').nullable(''),
        },
        "isShipping": {
            "type": "boolean",
            "columnFlex": 0.5,
        },
        "isBilling": {
            "type": "boolean",
            "columnFlex": 0.5,
        },
        "isMain": {
            "type": "boolean",
            "columnFlex": 0.5,
        },
        "preferred": {
            "type": "boolean",
            "columnFlex": 0.5,
        }
    }
}
export default definition