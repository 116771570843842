import { userActionTypes } from '../actionTypes';

const initialState = {
    email: "",
    error: "",
    name: "",
    role: "",
    sub: ""
};

export default function user(state = initialState, { type, payload }) {
    switch (type) {
        case userActionTypes.GET_USER_INFO:
            return state;
        case userActionTypes.GET_USER_INFO_SUCCES:
            return {
                ...state,
                email: payload.email,
                error: payload.error ? payload.error : "",
                name: payload.name,
                role: payload.role,
                sub: payload.sub
            };
        case userActionTypes.GET_USER_INFO_FAILED:
            return {
                ...state,
                email: "",
                error: payload.error ? payload.error : "",
                name: "",
                role: "",
                sub: "",
            };
        default:
            return state;
    }
};
