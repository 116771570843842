


const definition = {
    "format": "CustomerDetails",
    "type": "object",
    "request": {
        "get": { url: "customers/:AccountId" },
    },
    "route": { name: "/:AccountId" },
    "numberPath": "account.number",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true
        },
        "emailOptIn": {
            "type": "boolean"
        },
        "donorRelationType": {
            "type": "string",
            "nullable": true,
            "block": 2
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "block": 3
        },
        "advisorId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "field_hide": true
        },
        "account": {
            "type": "object",
            "format": "AccountDetails",
            "request": {
                "get": { url: "accounts/:AccountId" },
            },
            "tab_name": "Account",
            "route": { name: "account" }
        }
    }
}
export default definition