const definition = {
    "format": "CostCenter",
    "type": "array",
    "request": {
        "get": { url: "cost-center" }
    },
    "route": {
        "name": "costCenter",
        "details": "CostCenterDetails"
    },
    "numberPath": "id",
    "fields": {
        "id": {
            "type": "string",
            "format": "uuid",
            "column_hide": true
        },
        "paymentMethod": {
            "type": "enum",
            "format": "PaymentType"
        },
        "paymentDeliveryMethod": {
            "type": "enum",
            "format": "PaymentDeliveryMethod"
        },
        "billCycleId": {
            "type": "string",
            "format": "uuid",
            "nullable": true,
            "column_hide": true
        }
    }
}
export default definition