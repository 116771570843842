import { Box } from '@mui/system';
import PropTypes from "prop-types";

export default function Background({ backgroundColor, children }) {
    return (
        <Box
            width="100vw"
            height="100%"
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            bgColor={backgroundColor}
            sx={{ overflowX: "hidden" }}
        >
            {children}
        </Box>
    );
}

Background.defaultProps = {
    backgroundColor: "default",
};

Background.propTypes = {
    backgroundColor: PropTypes.oneOf(["light", "dark", "default"]),
    children: PropTypes.node.isRequired,
};