import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import DashBoard from "../Views/Dashboard";
import NavigationBar from "../Views/NavigationBar";
import SignInLayout from "../Views/SignIn"
import ProtectedRoute from "./protectedRoute"
// import Data from "../Views/Data/index-old"
import Details from "../Views/Data/Components/details";
import { generateRoutes } from "../utils/generators/routeGenerators";


const navRoutes = () => {

    const routes = generateRoutes()

    return (
        routes ? <Routes >
            <Route
                path="/" element={<Navigate to="/dashboard"></Navigate>}
            />
            {routes.map(route => <Route key={route.path}
                path={route.path} element={<ProtectedRoute><NavigationBar />
                    <Details route={route.route ? route.route : null} key={route.objectDefinition.format} objectDefinition={route.objectDefinition} parentObjectDefinition={route.parentObjectDefinition} />
                </ProtectedRoute>}
            />
            )}
            <Route
                path="/dashboard" element={<ProtectedRoute><NavigationBar /> <DashBoard /></ProtectedRoute>}
            />
            <Route
                path="/signin" element={<SignInLayout />}
            />

        </Routes  > : null
    )
}

export default navRoutes