
const definition = {
    "format": "Address",
    "type": "object",
    "route": {
        "name": "/:addressId"
    },
    "request": {
        "get": { url: "accounts/:AccountId", route: "addresses/:addressId" },
        "put": { url: "accounts/:AccountId/address/:addressId", format: "AddressCreate", response: "AccountDetails" },
        "delete": { url: "accounts/:AccountId/address/:addressId", role: "Admin", response: "AccountDetails", redirect: "/advisor/:AccountId/account/addresses" },
        "post": { url: "accounts/:AccountId/address", format: "AddressCreate", response: "AccountDetails" }

    },
    "numberPath": "label",
    "fields": {
        "line1": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 1,
        },
        "line2": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "hideIfEmpty": true,
            "line": 1,
            "column_hide": true,
        },
        "line3": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "hideIfEmpty": true,
            "line": 1,
            "column_hide": true
        },
        "line4": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "hideIfEmpty": true,
            "line": 1,
            "column_hide": true
        },
        "number": {
            "type": "integer",
            "format": "int32",
            "nullable": true,
            "block": 2,
            "line": 1
        },
        "unit": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 1
        },
        "postalCode": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 2
        },
        "city": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 2
        },
        "state": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 3
        },
        "countryCode": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "line": 3
        },
        "id": {
            "type": "string",
            "format": "uuid",
            "field_hide": true,
            "block": 2,
            "column_hide": true
        },
        "isMain": {
            "type": "boolean",
            "block": 1,
            "columnHidden": true
        },
        "preferred": {
            "type": "boolean",
            "block": 1,
            "columnHidden": true
        },
        "isBilling": {
            "type": "boolean",
            "block": 1,
            "columnHidden": true
        },
        "isShipping": {
            "type": "boolean",
            "block": 1,
            "column_hide": true
        },
        "creationTime": {
            "type": "string",
            "format": "date-time",
            "block": 3,
            "column_hide": true
        },
        "lastModificationTime": {
            "type": "string",
            "format": "date-time",
            "nullable": true,
            "block": 3,
            "column_hide": true
        }
    }
}
export default definition