import * as yup from 'yup';
import "../../../utils/generators/yupExtenstions"

const definition = {
    "format": "PersonCreate",
    "fields": {
        "prefix": {
            "type": "string",
            "nullable": true,
            "validationSchema": yup.string('').nullable().notRequired(''),
        },
        "firstname": {
            "type": "string",
            "nullable": true,
            "validationSchema": yup.string('').nullable().required('Firstname is required'),
        },
        "infix": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "validationSchema": yup.string('').nullable().notRequired(''),
        },
        "lastname": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "validationSchema": yup.string('').nullable().notRequired(''),
        },
        "gender": {
            "type": "enum",
            "format": "Gender",
            "block": 3
        },
        "birthdate": {
            "type": "string",
            "format": "date-time",
            "block": 3,
            "validationSchema": yup.date().required('BirthDate is required'),
        },

    }
}
export default definition