import { useNavigate, useParams, useLocation } from "react-router-dom";

export const withRouter = (WrappedComponent) => {
    const Wrapper = (props) => {
        const params = useParams();
        const navigate = useNavigate();
        const location = useLocation();

        return (
            <WrappedComponent
                params={params}
                navigate={navigate}
                location={location}
                {...props}
            />
        );
    };

    return Wrapper;
};
