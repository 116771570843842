const definition = {
    "format": "AddressValue",
    "fields": {
        "line1": {
            "type": "string",
            "nullable": true,
            "block": 1,
            "line": 1
        },
        "line2": {
            "type": "string",
            "nullable": true,
            "block": 1,
            "line": 1,
            "hideIfEmpty": true
        },
        "line3": {
            "type": "string",
            "nullable": true,
            "block": 1,
            "line": 1,
            "hideIfEmpty": true
        },
        "line4": {
            "type": "string",
            "nullable": true,
            "block": 1,
            "line": 1,
            "hideIfEmpty": true
        },
        "number": {
            "type": "integer",
            "format": "int32",
            "nullable": true,
            "block": 1,
            "line": 1
        },
        "unit": {
            "type": "string",
            "nullable": true,
            "block": 1,
            "line": 1
        },
        "postalCode": {
            "type": "string",
            "nullable": true,
            "block": 1,
            "line": 2
        },
        "city": {
            "type": "string",
            "nullable": true,
            "block": 1,
            "line": 2
        },
        "state": {
            "type": "string",
            "nullable": true,
            "block": 1,
            "line": 3
        },
        "countryCode": {
            "type": "string",
            "nullable": true,
            "block": 1,
            "line": 3
        }
    }
}
export default definition