import { Box, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import { generateSearchDefinition } from "../../../utils/generators/dataGridGenerators"
import { useTranslation } from "react-i18next"
import debounce from 'lodash.debounce';
import { getEnum } from "../../../utils/objectDefinitionUtils/basicUtils";

function gridSearch({ objectDefinition, updateObjects, filter }) {
    const { t } = useTranslation()
    var debouncedChangeHandler = debounce(updateObjects, 250);
    return (
        <Grid container>
            {generateSearchDefinition(objectDefinition).map((def) => (
                <Grid item key={def.field}>
                    <Box
                        mb={1}
                        mx={2}
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Box mb={1}>
                            <Typography variant="caption">
                                {t(def.name)}
                            </Typography>
                        </Box>
                        {def.type == "string" ?
                            < TextField
                                fullWidth
                                size="small"
                                required
                                id={def.field}
                                name={def.title}
                                onChange={e => debouncedChangeHandler({ [def.name]: e.target.value })}
                                placeholder={t("search") + " " + t(def.name)}
                                width="100%"
                                type={def.format == "date-time" ? "date" : null}
                            /> :
                            def.type == "enum" ?
                                <Select
                                    onChange={e => e.target.value == "Search all" ? debouncedChangeHandler({ [def.name]: "" }) : debouncedChangeHandler({ [def.name]: e.target.value })}
                                    value={filter[def.name] ? filter[def.name] : "Search all"} fullWidth
                                >
                                    <MenuItem
                                        name="placeholder"
                                        key={-1}
                                        value={t("Search all")}>{t("Search all")}
                                    </MenuItem>
                                    {getEnum(def.format).array.map((el, index) => { return <MenuItem key={index} value={el}>{el}</MenuItem> })}
                                </Select> : null}
                    </Box>
                </Grid>
            ))
            }
        </Grid >
    )

}

export default gridSearch   