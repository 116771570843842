export const palette = {
    neutral: {
        100: '#e5eaec',
        200: '#ccd5da',
        300: '#b2c0c7',
        400: '#99abb5',
        500: '#7f96a2',
        600: '#668190',
        700: '#4c6c7d',
        800: '#32576a',
        900: '#194258'
    },
    action: {
        active: '#C0D84B',
        focus: 'rgba(55, 65, 81, 0.12)',
        hover: '#e5eaec',
        selected: '#C0D84B',
        disabledBackground: 'rgba(55, 65, 81, 0.12)',
        disabled: 'rgba(55, 65, 81, 0.26)'
    },
    background: {
        default: '#FFFFFF',
        paper: '#FFFFFF',
        obg: '#002E46',
        transparent: 'rgba(55, 65, 81, .26)',
        red: '#930017 ',
        green: '#c0d84b ',
        yellow: '#f3da0b ',
        blue: '#006093 '
    },
    divider: '#E6E8F0',
    primary: {
        main: '#002E46',
        light: '#828DF8',
        dark: '#3832A0',
        contrastText: '#FFFFFF'
    },
    secondary: {
        main: '#C0D84B',
        light: '#3FC79A',
        dark: '#0B815A',
        contrastText: '#FFFFFF'
    },
    success: {
        main: '#C0D84B',
        light: '#43C6B7',
        dark: '#0E8074',
        contrastText: '#FFFFFF'
    },
    info: {
        main: '#2196F3',
        light: '#C0D84Bz',
        dark: '#0B79D0',
        contrastText: '#FFFFFF'
    },
    warning: {
        main: '#FFB020',
        light: '#FFBF4C',
        dark: '#B27B16',
        contrastText: '#FFFFFF'
    },
    error: {
        main: '#D8634B',
        light: '#be7e66',
        dark: '#671c00',
    },
    text: {
        primary: '#002E46',
        secondary: '#00A7E5',
        active: '#C0D84B',
        disabled: 'rgba(55, 65, 81, 0.48)',
        obg: '#002E46'
    }
}