import * as yup from 'yup';
import "../../../utils/generators/yupExtenstions"

const definition = {
    "format": "ContactInformationCreate",
    "fields": {
        "label": {
            "type": "string",
            "nullable": true,
            "block": 2,
            "validationSchema": yup.string('').nullable(''),
        },
        "isMain": {
            "type": "boolean"
        },
        "phone": {
            "type": "string",
            "nullable": true,
            "block": 3,
            "validationSchema": yup.string('').nullable(''),
        },
        "mobile": {
            "type": "string",
            "nullable": true,
            "block": 3,
            "validationSchema": yup.string('').nullable(''),

        },
        "fax": {
            "type": "string",
            "nullable": true,
            "block": 3,
            "validationSchema": yup.string('').nullable(''),

        },
        "email": {
            "type": "string",
            "nullable": true,
            "block": 3
            , validationSchema: yup.string('').nullable().required().email('Not a valid Email')
        },
        "website": {
            "type": "string",
            "nullable": true,
            "block": 3,
            "validationSchema": yup.string('').nullable(''),
        },
        "telegram": {
            "type": "string",
            "nullable": true,
            "block": 3,
            "validationSchema": yup.string('').nullable(''),
        },
        "linkedIn": {
            "type": "string",
            "nullable": true,
            "block": 3,
            "validationSchema": yup.string('').nullable(''),
        },
        "facebook": {
            "type": "string",
            "nullable": true,
            "block": 3,
            "validationSchema": yup.string('').nullable(''),
        },
        "instagram": {
            "type": "string",
            "nullable": true,
            "block": 3,
            "validationSchema": yup.string('').nullable(''),
        },
        "pushToken": {
            "type": "string",
            "nullable": true,
            "block": 4,
            "validationSchema": yup.string('').nullable(''),
        }
    }
}
export default definition